import moment from 'moment';
import { isEmpty, isNil, pathOr } from 'ramda';
import _ from 'lodash';
import { sortByDateField } from './date-helpers';

export type HouseholdTransferLeaseValidations = {
  hasActiveTransferLease: boolean,
  isValid: boolean,
};

export const COMPLIANCE_APPROVAL_STATUSES = {
  APPROVED: 'Approved',
  APPROVED_PENDING_SIGN: 'Approved Pending Signatures',
  CORRECTION_NEEDED: 'Correction Needed',
  CORRECTION_NEEDED_FINAL: 'Correction Needed - Final Packet',
  DENIED: 'Denied',
  PENDING: 'Pending',
  ON_NOTICE_HOLD: 'Household on Notice / Hold',
  NONE: 'None', // not defined in the DB, but used in our code
  NOT_STARTED: 'Not Started', // not defined in the DB, but used in our code
  STARTED: 'Started', // not defined in the DB, but used in our code
  COMPLETE: 'Complete', // not defined in the DB, but used in our code
  PENDING_FINAL_APPROVAL: 'Pending Final Approval',
};

export const NOT_STARTED_COMPLIANCE_APPROVAL = {
  id: 'NotStarted',
  name: 'Not Started',
};

export const AFFORDABLE_QUALIFICATION_STATUSES = {
  ACTIVE: 'ACTIVE',
  REOPEN: 'REOPEN',
  COMPLETE: 'COMPLETE',
};

export const AFFORDABLE_PROGRAMS = {
  BOND: 'BOND',
  CDGB: 'CDGB',
  CITC: 'CITC',
  HOME: 'HOME',
  HUD: 'HUD',
  LIHTC: 'LIHTC',
  'LIHTC/HOME': 'LIHTC/HOME',
  RD: 'RD',
};

export const HUD_SUBSIDY_CODES = {
  SECTION_8: '1',
  RENT_SUPPLEMENT: '2',
  RAP: '3',
  SECTION_236: '4',
  BMIR: '5',
  '811_PRA_DEMO': '6',
  SECTION_202_PRAC: '7',
  SECTION_811_PRAC: '8',
  MARKET_RENT: '0',
  SECTION_202_162: '9',
};

export const getIsReceivingAssistance = (programs: Array<Object>): boolean => {
  return (programs || []).reduce((acc, program) => {
    if (acc === true) return acc;
    return pathOr(
      false,
      ['affordableQualification', 'receivingAssistance'],
      program,
    );
  }, false);
};

export const getAffordableFloorPlanProgramTypes = (floorPlan: Object) => {
  return pathOr([], ['floorPlanAffordablePrograms'], floorPlan).reduce(
    (acc, program) => {
      const affordableProgram = pathOr(
        '',
        ['propertyAffordableProgram', 'masterAffordableProgram', 'name'],
        program,
      );
      acc[`is${affordableProgram}FloorPlan`] = true;
      return acc;
    },
    {
      isHUDFloorPlan: false,
      isLIHTCFloorPlan: false,
      isRDFloorPlan: false,
    },
  );
};

const findMatchingFPUtilityAllowanceByDate = (
  fpUtilityAllowances: Array<Object>,
  voucherEffectiveDate: Object,
) => {
  return fpUtilityAllowances.reduce((current, fpAllowance) => {
    const endDate = pathOr(null, ['endDate'], fpAllowance);
    const startDate = pathOr(null, ['startDate'], fpAllowance);

    const mEnDate = moment(endDate);
    const mStartDate = moment(startDate);
    const mEffectiveDate = moment(voucherEffectiveDate);

    if (endDate === null && mEffectiveDate.isSameOrAfter(mStartDate)) {
      return fpAllowance;
    }

    if (mEffectiveDate.isAfter(mEnDate)) {
      return current;
    }

    if (mEffectiveDate.isBetween(mStartDate, mEnDate)) {
      return fpAllowance;
    }

    if (mEffectiveDate.isBefore(mStartDate)) {
      return current;
    }

    return current;
  }, null);
};

export const determineAffordableUtilityAllowances = (
  floorPlan: Object,
  voucherEffectiveDate: Object,
) => {
  const allowances = pathOr(null, ['allowances'], floorPlan);
  const allowancesHUD =
    allowances &&
    allowances.filter((allowance) =>
      pathOr(false, ['pha', 'isHUD'], allowance),
    );
  const allowance =
    allowancesHUD && allowancesHUD.length > 0 && allowancesHUD[0];

  /* If there is no Allowance, then there won't be a fpua either */
  if (!allowance) {
    return null;
  }

  const fpUtilityAllowances = pathOr(null, ['fpua'], floorPlan);
  const fpUtilityAllowancesHUD =
    fpUtilityAllowances &&
    // data coming from floorplan results
    fpUtilityAllowances.filter((ua) => {
      return pathOr(
        pathOr(false, ['allowances', 'pha', 'isHUD'], ua),
        ['ua', 'pha', 'isHUD'],
        ua,
      );
    });

  const matchingFPAllowance =
    fpUtilityAllowancesHUD &&
    fpUtilityAllowancesHUD.length > 0 &&
    findMatchingFPUtilityAllowanceByDate(
      fpUtilityAllowancesHUD,
      voucherEffectiveDate,
    );

  return matchingFPAllowance
    ? pathOr(null, ['feeAmount'], matchingFPAllowance)
    : pathOr(null, ['utilityAllowanceAmount'], allowance);
};

export const getHUDContractRent = (
  floorPlan: Object,
  currentLeaseStartDate: Object,
) => {
  const HUDUtilityAllowance = currentLeaseStartDate
    ? +determineAffordableUtilityAllowances(
        floorPlan,
        moment(currentLeaseStartDate),
      )
    : 0;
  const HUDGrossRent = pathOr(0, ['HUDGrossRent'], floorPlan);
  return +HUDGrossRent - +HUDUtilityAllowance;
};

export const getNonOptionalChargeFromQualifications = (
  affordableQualifications: Array<Object>,
): number | null => {
  const latestActiveLIHTCCert = (affordableQualifications ?? []).find(
    (aq) =>
      aq?.propertyAffordableProgram?.masterAffordableProgram?.name === 'LIHTC',
  );

  if (isEmpty(latestActiveLIHTCCert ?? {})) {
    const completedCerts =
      affordableQualifications?.filter((aq) => aq.isActive) ?? [];
    const lastCompletedLIHTCCert = sortByDateField(
      completedCerts ?? [],
      'voucherEffectiveDate',
      true,
    ).filter(
      (aq) =>
        aq?.propertyAffordableProgram?.masterAffordableProgram?.name ===
        'LIHTC',
    )?.[0];

    return lastCompletedLIHTCCert?.nonOptionalCharge;
  }

  return latestActiveLIHTCCert?.nonOptionalCharge;
};

export const getMostRecentActiveAffordableQualification = (
  affordableQualifications: Array<Object>,
) => {
  const filterOutCompletedCerts =
    affordableQualifications?.filter((aq) => aq.isActive) ?? [];
  const latestActiveCert =
    sortByDateField(
      filterOutCompletedCerts ?? [],
      'voucherEffectiveDate',
      true,
    )?.[0] ?? {};
  return latestActiveCert;
};

export const getCurrentNonOptionalCharge = (
  fpNonOptionalCharges: Array<Object>,
): number =>
  fpNonOptionalCharges.filter(
    (nonOptionalCharge) =>
      isNil(nonOptionalCharge.deletedAt) &&
      moment.utc(nonOptionalCharge.startDate).isBefore(moment.utc()) &&
      moment
        .utc(nonOptionalCharge.endDate ?? undefined)
        .isSameOrAfter(moment.utc()),
  )?.[0]?.feeAmount ?? 0;

export const chkAllTabsApproved = (
  allAffordableTabs: Array<Object>,
  affordableQualifications: Array<Object>,
): boolean => {
  if (affordableQualifications === undefined) {
    return true;
  }
  const allPass = allAffordableTabs.every((tab) => {
    if (!tab) {
      return true;
    }
    const approvalStatus =
      tab?.affordableQualification?.complianceApproval?.name ?? '';
    return approvalStatus.includes('Approved');
  });
  return allPass;
};

// Returns true if one tab has status of list passed
export const chkAllTabsForComplianceApprovalStatuses = (
  allAffordableTabs: Array<Object>,
  affordableQualifications: Array<Object>,
  approvalStatusesToMatch: Array<string>,
): boolean => {
  if (affordableQualifications === undefined) {
    return false;
  }
  const anyMatches = allAffordableTabs.some((tab) => {
    if (!tab) {
      return false;
    }
    const approvalStatus =
      tab?.affordableQualification?.complianceApproval?.name ?? '';
    return approvalStatusesToMatch.includes(approvalStatus);
  });
  return anyMatches;
};

export const getAffordableAndNonAffordableMembers = (
  householdMembers: Array<Object>,
  excludeFosterOccupants: boolean,
) => {
  const excludeFosterOcccupants = (applicant) => {
    if (!excludeFosterOccupants) {
      return true;
    }
    return applicant?.ar?.name !== 'Foster child/adult';
  };
  const [affordableMembers, nonAffordableMembers] = _.partition(
    householdMembers,
    (applicant) =>
      applicant?.applicantType?.name !== 'Pet' &&
      applicant?.applicantType?.name !== 'Guarantor Lease Signer' &&
      applicant?.ar?.name !== 'Live-in Caretaker' &&
      applicant?.applicantMinor?.jointCustody !== 'yesLessThan50' &&
      excludeFosterOcccupants(applicant),
  );

  return { affordableMembers, nonAffordableMembers };
};

export const formatAffordableMembersForCertModal = (members: Array<Object>) => {
  return members.map((member) => ({
    id: member.id,
    name: member?.name ?? '',
    relationship: member?.ar?.name ?? '',
  }));
};

export const formatNonAffordableMembersForCertModal = (
  members: Array<Object>,
  applyHotmaRules = false,
) => {
  return members.map((member) => {
    // TODO: add translations for tooltips
    let tooltip = '';
    if (member?.applicantType.name === 'Guarantor Lease Signer') {
      tooltip = 'Guarantor Lease Signer';
    } else if (member?.ar?.name === 'Live-in Caretaker') {
      tooltip = 'Live in Caretaker';
    } else if (member?.applicantMinor?.jointCustody === 'yesLessThan50') {
      tooltip = 'Child with Joint Custody under 50%';
    } else if (applyHotmaRules && member?.ar?.name === 'Foster child/adult') {
      tooltip = 'Foster Child/Adult';
    }
    return {
      id: member.id,
      name: member?.name ?? '',
      relationship: member?.ar?.name ?? '',
      tooltip,
    };
  });
};

export const getIsSection236 = (
  property: Object,
  floorPlan: Object,
): boolean => {
  const hasHUD = (property?.pap ?? [])
    .map((pap) => pap?.masterAffordableProgram?.name)
    .includes(AFFORDABLE_PROGRAMS.HUD);
  if (hasHUD) {
    const fpHudCode = (floorPlan?.floorPlanAffordablePrograms ?? []).find(
      (fp) =>
        fp?.propertyAffordableProgram?.masterAffordableProgram?.name ===
        AFFORDABLE_PROGRAMS.HUD,
    )?.hudCode;
    const propertyHUDSubsidy = (property?.propertyHUDSubsidy ?? []).map(
      (hud) => hud?.masterHUDSubsidy?.code,
    );

    return (
      propertyHUDSubsidy.includes(HUD_SUBSIDY_CODES.SECTION_236) ||
      fpHudCode === HUD_SUBSIDY_CODES.SECTION_236
    );
  }

  return false;
};

const ASSET_THRESHOLD_DEFAULT = 50000;
export const getAssetThreshold = (
  affordableConfigs: Object,
  programName: string,
  isHotmaActive: boolean,
) => {
  if (isHotmaActive) {
    return affordableConfigs?.ASSET_THRESHOLD_HOTMA ?? ASSET_THRESHOLD_DEFAULT;
  }

  return (
    affordableConfigs?.[`ASSET_THRESHOLD_${programName}`] ??
    affordableConfigs?.ASSET_THRESHOLD ??
    ASSET_THRESHOLD_DEFAULT
  );
};

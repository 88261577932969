import { useMemo } from 'react';
import keyBy from 'lodash.keyby';
import { useFetchConversations } from '../../hooks/data-fetching/useFetchConversations';
import useHasPermission from '../../hooks/useHasPermission';
import useSelectedProperty from '../../hooks/useSelectedProperty';

/**
 * Will mainly calculate the texting column, similar to the ManageApplications table
 * ---
 * @notes
 *  - Review src/containers/ManageApplications/ApplicationsTable/ApplicationsTable_V2.hooks.js for reference
 * @param {*} param0
 * @returns
 */
const useParseResults = ({ results }) => {
  const { isTwoWayCommunicationActive } = useSelectedProperty();

  const twoWayRecipientIds = useMemo(
    () => results.map((r) => r.twoWayRecipientIds).flat(),
    [results],
  );

  const hasCommunicationCreatePermission = useHasPermission(
    'communication-create',
  );
  const showTextingColumn =
    isTwoWayCommunicationActive && hasCommunicationCreatePermission;

  const [conversations] = useFetchConversations(
    twoWayRecipientIds,
    showTextingColumn,
  );

  const conversationsDictionary = useMemo(() => {
    return keyBy(conversations, (c) => c.recipient.customerId);
  }, [conversations]);

  const parsedResults = useMemo(() => {
    return results?.length
      ? results.map((result) => {
          const parsedResult = { ...result };

          const householdConversationsStatus = result.twoWayRecipientIds.reduce(
            (acc, customerId) => {
              const conversation = conversationsDictionary[customerId];

              if (conversation) {
                acc[conversation.conversationStatus] += 1;
              }

              return acc;
            },
            { NEW: 0, UNRESOLVED: 0, RESOLVED: 0 },
          );

          householdConversationsStatus.TOTAL =
            householdConversationsStatus.NEW +
            householdConversationsStatus.UNRESOLVED;

          result.texting = householdConversationsStatus;

          return parsedResult;
        })
      : [];
  }, [results, conversationsDictionary]);

  return { parsedResults, showTextingColumn };
};

export default useParseResults;

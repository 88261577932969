import React, { memo } from 'react';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  CircleCheckIcon,
  AlertIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import useIntl from '../../../hooks/useIntl';
import messages from '../messages';

const Review = () => {
  const intl = useIntl();

  const MOCK_RESPONSE = [
    {
      value: 'Part I',
      items: [
        {
          value: '30. Net Cash (Deficit): Proposed: $4,900.17',
          state: 'pass',
        },
      ],
    },
    {
      value: 'Part II',
      items: [
        {
          value: 'All Variances Greater than 10% include comments',
          state: 'fail',
        },
        {
          value:
            '33. Sub-Total Administrative:  $42,876.53 divided by Part 1 ' +
            'Sub-Total of Operational Cash Sources: $209,201',
          state: 'pass',
        },
        {
          value:
            'Tax Credit Debt Service: Calculation:  (P1 L10 - ' +
            '(P1 L16 + P1 L22 + P1 L23))  /  (P1 L17 + P1 L25 + P1 L27)',
          state: 'pass',
        },
      ],
    },
    {
      value: 'Part III',
      items: [
        {
          value: '8. Ending Balance: $120,729.00',
          state: 'fail',
        },
      ],
    },
  ];

  const StateIcons = {
    pass: <CircleCheckIcon color="success" />,
    fail: <AlertIcon color="warning" />,
  };

  return (
    <Box mx={8}>
      <Typography variant="h2" my={2}>
        {intl.formatMessage(messages.review)}
      </Typography>
      {MOCK_RESPONSE.map((section) => (
        <Box mb={2}>
          <Typography variant="titleSmall" my={2} fontWeight={500}>
            {section.value}
          </Typography>
          {section.items.map((item) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {StateIcons[item.state]}
              <Typography variant="bodyMedium" my={1} ml={1}>
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default memo(Review);

import { useMemo } from 'react';
import { useMutation, QueryOptions } from 'react-query';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';

import MincTransactionsService from '../../services/mincTransactionsService';

const useGenerateMincTransactionsReport = ({
  options = {},
}: {
  organizationId: string,
  propertyId: string,
  ids: Array<string>,
  options?: QueryOptions,
}) => {
  const service = useMemo(() => new MincTransactionsService(), []);

  const mutation = useMutation(
    async (values) => {
      const fileName = `MINC_Transactions_Report_${moment().unix()}.xml`;
      return service.generateMincReport(
        values.organizationId,
        values.propertyId,
        values.ids,
        fileName,
      );
    },
    {
      ...options,
      onSuccess: () => {
        toastr.success(
          'Success',
          'Successfully Created MINC Transactions Report',
        );
      },
      onError: (error) => {
        toastr.error('Error', error);
      },
    },
  );
  return mutation;
};

export default useGenerateMincTransactionsReport;

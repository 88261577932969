import { TextingIndicator } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  useTableFilterSortData,
  useTableFilterSortSearchManager,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import {
  formatDateDB,
  formatDateDisplayLocal,
} from '@fortress-technology-solutions/fortress-component-library/utils';
import keyBy from 'lodash.keyby';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useFetchConversations } from '../../hooks/data-fetching/useFetchConversations';
import useHasPermission from '../../hooks/useHasPermission';
import { appendFilterTextToCSV } from '../../utils/csv-helpers';
import { download } from '../../utils/downloadFile';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import { promptToaster } from '../App/actions';
import {
  AMENITY_DETAILS_PREFIX,
  HEADERS,
  PROPERTY_PATH_MAP,
  RENT_ROLL_TOTAL_COLUMNS,
} from './constantsV2';
import {
  buildFloorPlanGrandTotalForCSVExport,
  buildFloorPlanRowsFormCSVExport,
  buildFloorPlanSummaryForCSVExport,
} from './utilsV2';

import UnitService from '../../services/unitService';
import { DASHES } from '@fortress-technology-solutions/fortress-component-library/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useUniqueTableName from '../../hooks/useUniqueTableName';

export const useManageRentRoll = ({
  selectedProperty,
  NAME,
  intl,
  redirectedFromHomeKPI = false,
}) => {
  const propertyClass = selectedProperty?.propertyClass?.propertyType;
  const isRDRAEnabled = selectedProperty?.setup?.isRDRAEnabled ?? false;
  const isAffordable = propertyClass === 'Affordable';
  const isCommercialMixed = propertyClass === 'Commercial-Mixed';
  const isMixed = propertyClass === 'Mixed' || isCommercialMixed;
  const isConventional = propertyClass === 'Conventional';
  const initialName = `${NAME}${redirectedFromHomeKPI ? '-from-KPI' : ''}`;
  const name = useUniqueTableName(initialName);

  const rdRents = selectedProperty?.setup?.rdProjectType?.rdrents;

  const { units, isLoading } = useFetchManageRentRoll({ selectedProperty });

  const twoWayRecipientIds = useMemo(
    () => units.map((r) => r.twoWayRecipientIds).flat(),
    [units],
  );

  const hasCommunicationCreatePermission = useHasPermission(
    'communication-create',
  );
  const showTextingColumn =
    selectedProperty.isTwoWayCommunicationActive &&
    hasCommunicationCreatePermission;

  const [conversations] = useFetchConversations(
    twoWayRecipientIds,
    showTextingColumn,
  );
  const conversationsDictionary = useMemo(() => {
    return keyBy(conversations, (c) => c.recipient.customerId);
  }, [conversations]);
  const hasFortressAsPaymentProvider = useMemo(
    () =>
      selectedProperty.paymentProviders?.find(
        (pp) => pp.paymentProvider === 'FORTRESS',
      ) !== undefined,
    [selectedProperty],
  );
  const isPropertyFromCanada = selectedProperty.physicalCountry === 'CA';
  const { results, unitAmenityHeaders } = useParseResults({
    units,
    conversationsDictionary,
  });
  const showAutopayColumn = hasFortressAsPaymentProvider;
  const showPADColumn = hasFortressAsPaymentProvider && isPropertyFromCanada;

  const filterOptions = useGetFilterOptions({ results });

  const headers = useHeaders({
    isAffordable,
    isConventional,
    isMixed,
    isRDRAEnabled,
    rdRents,
    filterOptions,
    unitAmenityHeaders,
    showTextingColumn,
    showAutopayColumn,
    showPADColumn,
    intl,
  });

  const tableFilterSortSearchManagerPayload = useMemo(() => {
    const payload = { name, initialOrderBy: 'unit', headers };
    if (redirectedFromHomeKPI) {
      payload.initialFilterState = {
        underEviction: ['yes'],
      };
    }
    return payload;
  }, [redirectedFromHomeKPI, name, headers]);

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager(tableFilterSortSearchManagerPayload);

  const propertyPathMap = useMemo(
    () => PROPERTY_PATH_MAP({ unitAmenityHeaders }),
    [unitAmenityHeaders],
  );
  const sortedAndFilteredResults = useTableFilterSortData({
    results,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP: propertyPathMap,
  });

  const rows = useRows({
    sortedAndFilteredResults,
    unitAmenityHeaders,
    isAffordable,
    isMixed,
    isRDRAEnabled,
    rdRents,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const footerRow = useGetFooterRow({
    filteredHeaders,
    sortedAndFilteredResults,
  });

  const { onCSVButtonClick } = useCSVPDFExport({
    propertyName: selectedProperty?.name,
    hasAnyFilters:
      Object.keys(filterState)?.length ||
      Object.keys(dateState)?.length ||
      Object.keys(searchState)?.length,
    filteredHeaders,
    rows,
    results: sortedAndFilteredResults,
  });

  return {
    allColumnsHidden,
    columnOptions,
    count: rows?.length ?? 0,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers,
    isLoading,
    name,
    onCSVButtonClick,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    totalCount: results?.length ?? undefined,
  };
};

const useFetchManageRentRoll = ({ selectedProperty = {} }) => {
  const { id, organizationId } = selectedProperty;
  const abortController = new AbortController();
  const queryKey = [id, organizationId];
  const options = { signal: abortController.signal };
  const { data, isLoading } = useQuery(
    ['manageRentRoll', queryKey],
    () => new UnitService().getRentRoll(id, organizationId, options),
    {
      enabled: Boolean(id.length && organizationId.length),
      refetchOnWindowFocus: false,
      onError: (e) => {
        promptToaster({
          type: 'error',
          title: 'Error Loading Rent Roll',
          message: e.toString(),
        });
      },
    },
  );
  const units = useMemo(() => data?.units ?? [], [data?.units]);
  return {
    units,
    isLoading,
  };
};

const useGetFilterOptions = ({ results }) => {
  return useMemo(() => {
    const options = {
      bedsBathsOptions: [],
      floorPlanOptions: [],
      statusOptions: [],
      applicantStatusOptions: [],
      buildingNumberOptions: [],
    };

    if (results) {
      results.forEach(
        ({ floorPlan, bedsBaths, status, applicantStatus, buildingNumber }) => {
          if (floorPlan?.length) options.floorPlanOptions.push(floorPlan);
          if (bedsBaths?.length) options.bedsBathsOptions.push(bedsBaths);
          if (status?.length) options.statusOptions.push(status);
          if (applicantStatus?.length)
            options.applicantStatusOptions.push(applicantStatus);
          if (buildingNumber?.length)
            options.buildingNumberOptions.push(buildingNumber);
        },
      );

      options.floorPlanOptions = Array.from(new Set(options.floorPlanOptions))
        .sort()
        .map((floorPlan) => ({
          text: floorPlan,
          value: floorPlan?.toLowerCase(),
        }));

      options.bedsBathsOptions = Array.from(new Set(options.bedsBathsOptions))
        .sort()
        .map((bedsBaths) => ({
          text: bedsBaths,
          value: bedsBaths,
        }));

      options.statusOptions = Array.from(new Set(options.statusOptions))
        .sort()
        .map((status) => ({
          text: status,
          value: status?.toLowerCase(),
        }));

      options.applicantStatusOptions = Array.from(
        new Set(options.applicantStatusOptions),
      )
        .sort()
        .map((applicantStatus) => ({
          text: applicantStatus,
          value: applicantStatus?.toLowerCase(),
        }));

      options.buildingNumberOptions = Array.from(
        new Set(options.buildingNumberOptions),
      )
        .sort((a, b) => a - b)
        .map((buildingNumber) => ({
          text: buildingNumber,
          value: buildingNumber.toLowerCase(),
        }));
    }

    return options;
  }, [results]);
};

const useGetFooterRow = ({ filteredHeaders, sortedAndFilteredResults }) => {
  return useMemo(() => {
    const totalsColumns = JSON.parse(JSON.stringify(RENT_ROLL_TOTAL_COLUMNS));

    sortedAndFilteredResults.forEach((result) => {
      Object.keys(totalsColumns).forEach((id) => {
        if (result[id]) {
          const addition =
            typeof result[id] === 'string'
              ? parseFloat(result[id])
              : result[id];
          totalsColumns[id].value += addition;
        }
      });
    });

    return filteredHeaders.reduce((object, { id }) => {
      object[id] = totalsColumns[id] ?? '';
      return object;
    }, {});
  }, [filteredHeaders, sortedAndFilteredResults]);
};

const useParseResults = ({ units, conversationsDictionary }) => {
  return useMemo(() => {
    let results = [];
    let unitAmenityHeaders = new Set();

    units.forEach((result) => {
      const parsedResult = { ...result };

      // transform setAside to number
      if (
        typeof result.setAside === 'string' &&
        result.setAside.includes('%')
      ) {
        parsedResult.setAside = parseFloat(result.setAside.replace('%', ''));
      }

      if (Array.isArray(result.otherHouseholdMembers)) {
        parsedResult.otherHouseholdMembers_search =
          parsedResult.otherHouseholdMembers?.join(',');
      }

      Object.keys(result).forEach((key) => {
        if (key.startsWith(AMENITY_DETAILS_PREFIX)) {
          unitAmenityHeaders.add(key);
        }
      });

      const householdConversationsStatus = result.twoWayRecipientIds.reduce(
        (acc, customerId) => {
          const conversation = conversationsDictionary[customerId];

          if (conversation) {
            acc[conversation.conversationStatus] += 1;
          }

          return acc;
        },
        { NEW: 0, UNRESOLVED: 0, RESOLVED: 0 },
      );

      householdConversationsStatus.TOTAL =
        householdConversationsStatus.NEW +
        householdConversationsStatus.UNRESOLVED;

      parsedResult.texting = householdConversationsStatus;

      if (typeof parsedResult.isAutopayActive === 'boolean')
        parsedResult.isAutopayActive = result.isAutopayActive ? 'on' : 'off';
      if (typeof parsedResult.isPADActive === 'boolean')
        parsedResult.isPADActive = result.isPADActive ? 'on' : 'off';
      if (typeof parsedResult.rdRentalAssistanceRA === 'boolean') {
        parsedResult.rdRentalAssistanceRA = result.rdRentalAssistanceRA
          ? 'yes'
          : 'no';
      }

      results.push(parsedResult);
    });

    unitAmenityHeaders.delete(undefined);
    unitAmenityHeaders = Array.from(unitAmenityHeaders);

    return { results, unitAmenityHeaders };
  }, [conversationsDictionary, units]);
};
const useHeaders = ({
  isAffordable,
  isConventional,
  isMixed,
  isRDRAEnabled,
  rdRents,
  filterOptions,
  unitAmenityHeaders,
  showTextingColumn,
  showAutopayColumn,
  showPADColumn,
  intl,
}) => {
  const { manageRentRollBuildingsColumn } = useFlags();

  return useMemo(() => {
    const headers = [];
    HEADERS({
      isAffordable,
      isConventional,
      isMixed,
      isRDRAEnabled,
      rdRents,
      showTextingColumn,
      showAutopayColumn,
      showPADColumn,
      intl,
      manageRentRollBuildingsColumn,
    }).forEach((header) => {
      if (filterOptions[`${header.id}Options`])
        header.filterOptions = filterOptions[`${header.id}Options`];

      headers.push(header);

      if (
        header.id === 'baseFloorPlanMarketRent' && // previous header id
        unitAmenityHeaders?.length
      ) {
        unitAmenityHeaders.forEach((unitAmenityHeader) => {
          headers.push({
            id: unitAmenityHeader,
            label: `${unitAmenityHeader.replace(
              AMENITY_DETAILS_PREFIX,
              '',
            )} Unit Amenity`,
            dataType: 'number',
            sortable: true,
            searchable: true,
          });
        });
      }
    });

    return headers;
  }, [
    isAffordable,
    isConventional,
    isMixed,
    isRDRAEnabled,
    rdRents,
    showTextingColumn,
    showAutopayColumn,
    showPADColumn,
    intl,
    filterOptions,
    unitAmenityHeaders,
    manageRentRollBuildingsColumn,
  ]);
};

const useRows = ({
  sortedAndFilteredResults = [],
  unitAmenityHeaders = [],
  isAffordable,
  isMixed,
  isRDRAEnabled,
  rdRents,
}) => {
  return useMemo(() => {
    return sortedAndFilteredResults?.map(
      ({
        id,
        actualUa,
        amenityFees,
        applicant,
        applicantStatus,
        applicationId,
        balance,
        baseFloorPlanMarketRent,
        bedsBaths,
        chargedUnitFees,
        collectionNote,
        collectionNoteStartTime,
        daysVacant,
        floorPlan,
        floorPlanId,
        grossRentLimit,
        leaseEndDate,
        leaseStartDate,
        leasedRent,
        marketRent,
        moveInDate,
        moveInReady,
        moveOutDate,
        netMaxRent,
        nonOptionalCharge,
        otherHouseholdMembers,
        priorLeasedRent,
        quotingRent,
        resident,
        residentId,
        residentRent,
        scheduledMoveInDate,
        setAside,
        sqFt,
        status,
        subsidyRent,
        totalGrossRent,
        underEviction,
        unit,
        unitId,
        utilityAllowances,
        texting,
        requestedHardshipExemption,
        isAutopayActive,
        isPADActive,
        rdRentalAssistanceRA,
        ...rest
      }) => {
        return {
          id,
          unit: {
            variant: 'link',
            to: getUrlWithSelectedPropertyId(`/unit/${unitId}`),
            value: unit,
          },
          floorPlan: {
            variant: 'link',
            to: getUrlWithSelectedPropertyId(`/floorPlan/${floorPlanId}`),
            value: floorPlan,
          },
          buildingNumber: {
            variant:
              rest?.buildingNumber?.length > 10 ? 'description' : 'string',
            value: rest.buildingNumber,
          },
          bedsBaths: {
            variant: 'number',
            value: bedsBaths,
          },
          sqFt: {
            variant: 'number',
            value: sqFt,
          },
          status: {
            value: status,
          },
          resident: {
            variant: 'link',
            to: getUrlWithSelectedPropertyId(`/resident/${residentId}`),
            value: resident !== '---' && resident?.length ? resident : null,
          },
          otherHouseholdMembers: {
            value: otherHouseholdMembers?.length
              ? otherHouseholdMembers
              : '---',
          },
          texting: {
            variant: texting.TOTAL > 0 ? 'link' : undefined,
            value: <TextingIndicator {...texting} />,
            to:
              texting.TOTAL > 0
                ? getUrlWithSelectedPropertyId(
                    `/resident/${residentId}?tab=texting`,
                  )
                : undefined,
          },
          ...((isAffordable || isMixed) && {
            requestedHardshipExemption: {
              variant: 'boolean',
              value: requestedHardshipExemption,
              iconName: 'CheckIcon',
              fontSize: 'large',
            },
            setAside: {
              variant: 'number',
              value: setAside ? `${setAside}%` : DASHES,
            },
            ...(isRDRAEnabled && {
              rdRentalAssistanceRA: {
                variant: 'string',
                value: rdRentalAssistanceRA === 'yes' ? 'RA' : DASHES,
              },
            }),
          }),
          moveInDate: {
            variant: 'date',
            value: moveInDate,
          },
          leaseStartDate: {
            variant: 'date',
            value: leaseStartDate,
          },
          leaseEndDate: {
            variant: 'date',
            value: leaseEndDate,
          },
          collectionNote: {
            variant: 'description',
            value: collectionNote
              ? [
                  formatDateDisplayLocal(collectionNoteStartTime),
                  collectionNote,
                ]
              : null,
          },
          underEviction: {
            variant: 'boolean',
            value: underEviction,
            iconName: 'AlertInfoIcon',
            color: 'error',
          },
          baseFloorPlanMarketRent: {
            variant: 'currency',
            value: baseFloorPlanMarketRent,
          },
          ...(unitAmenityHeaders?.length &&
            unitAmenityHeaders.reduce((object, unitAmenityHeaderId) => {
              object[unitAmenityHeaderId] = {
                variant: 'currency',
                value: rest[unitAmenityHeaderId],
              };

              return object;
            }, {})),
          amenityFees: {
            variant: 'currency',
            value: amenityFees,
          },
          marketRent: {
            variant: 'currency',
            value: marketRent,
          },
          ...((isAffordable || isMixed) && {
            totalGrossRent: {
              variant: 'currency',
              value: totalGrossRent,
            },
            grossRentLimit: {
              variant: 'currency',
              value: grossRentLimit,
            },
            netMaxRent: {
              variant: 'currency',
              value: netMaxRent,
            },
          }),
          priorLeasedRent: {
            variant: 'currency',
            value: priorLeasedRent,
          },
          isAutopayActive: {
            variant: 'onOff',
            value: isAutopayActive,
          },
          isPADActive: {
            variant: 'onOff',
            value: isPADActive,
          },
          leasedRent: {
            variant: 'currency',
            value: leasedRent,
          },
          ...((isAffordable || isMixed) && {
            nonOptionalCharge: {
              variant: 'currency',
              value: nonOptionalCharge,
            },
            actualUa: {
              variant: 'currency',
              value: actualUa,
            },
            utilityAllowances: {
              variant: 'currency',
              value: utilityAllowances,
            },
          }),
          residentRent: { variant: 'currency', value: residentRent },
          subsidyRent: { variant: 'currency', value: subsidyRent },
          ...(rdRents?.noteRent && {
            noteRent: {
              variant: 'currency',
              value: rest?.noteRent,
            },
          }),
          ...(rdRents?.basicRent && {
            basicRent: {
              variant: 'currency',
              value: rest?.basicRent,
            },
          }),
          chargedUnitFees: {
            variant: 'currency',
            value: chargedUnitFees,
          },
          balance: {
            variant: 'currency',
            value: balance,
          },
          moveOutDate: {
            variant: 'date',
            value: moveOutDate,
          },
          moveInReady: {
            value: moveInReady
              ? moveInReady === 'Ready'
                ? moveInReady
                : formatDateDisplayLocal(moveInReady)
              : null,
          },
          daysVacant: {
            variant: 'number',
            value: daysVacant,
          },
          quotingRent: {
            variant: 'currency',
            value: quotingRent,
          },
          applicant: {
            variant: 'link',
            value: applicant !== '---' ? applicant : undefined,
            to: getUrlWithSelectedPropertyId(`/application/${applicationId}`),
          },
          applicantStatus: {
            value: applicantStatus,
          },
          scheduledMoveInDate: {
            variant: 'date',
            value: scheduledMoveInDate,
          },
        };
      },
    );
  }, [
    isAffordable,
    isMixed,
    isRDRAEnabled,
    rdRents,
    sortedAndFilteredResults,
    unitAmenityHeaders,
  ]);
};

const useCSVPDFExport = ({
  filteredHeaders,
  rows,
  propertyName,
  hasAnyFilters,
  results,
}) => {
  const onCSVButtonClick = useCallback(() => {
    const csvHeaders = filteredHeaders
      .map(({ label }) =>
        typeof label === 'string' ? label : label.props?.defaultMessage,
      )
      .join(',');

    const totals = { ...RENT_ROLL_TOTAL_COLUMNS };
    const csvRows = buildFloorPlanRowsFormCSVExport({
      rows,
      filteredHeaders,
      totals,
    });
    csvRows.push(['-']);

    const totalsRow = buildFloorPlanGrandTotalForCSVExport({
      totals,
      filteredHeaders,
    });
    csvRows.push(totalsRow);
    csvRows.push(['-']);

    const floorPlanRows = buildFloorPlanSummaryForCSVExport({ results });
    csvRows.push(...floorPlanRows);

    const csv = appendFilterTextToCSV({
      headers: csvHeaders,
      rows: csvRows,
      hasAnyFilters,
    });

    download(
      csv,
      `RentRoll_${propertyName?.split(' ').join('-')}_${formatDateDB(
        new Date(),
      )}.csv`,
      'text/csv;charset=utf-8',
    );
  }, [filteredHeaders, rows, results, hasAnyFilters, propertyName]);

  return { onCSVButtonClick };
};

import React, { Component } from 'react';
import { ApplicationChecklistContainer } from '../../../ApplicationProfile/LeaseApplicationTab';

class ApproveOrDisapproveApplicantForm extends Component<any> {
  render() {
    const {
      intl,
      currentRecord,
      applicationDecisionStatus,
      handleChecklistOptionChange,
      handleEditApplicationForm,
      approveApplicant,
      cancelApplicant,
      isResident,
      deniedReasons,
      isCanceled,
    } = this.props;
    return (
      <div className="form-container container-fluid">
        <ApplicationChecklistContainer
          {...{
            intl,
            currentRecord,
            applicationDecisionStatus,
            handleChecklistOptionChange,
            approveApplicant,
            cancelApplicant,
            handleEditApplicationForm,
            isResident,
            deniedReasons,
            isCanceled,
          }}
        />
      </div>
    );
  }
}

export default ApproveOrDisapproveApplicantForm;

import messages from '../messages';
import {
  Input,
  Box,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { DatePicker } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import React from 'react';
import useIntl from '../../../hooks/useIntl';
import moment from 'moment';

const RentForecastModal = (props) => {
  const {
    closeModal,
    createRentForecast,
    isLoading,
    open,
    rentForecastValues: { name, date },
    setRentForecastValues,
  } = props;
  const intl = useIntl();

  const handleChange = (name, value) => {
    if (name === 'date') {
      const date = moment(value).startOf('year');
      setRentForecastValues((prev) => ({
        ...prev,
        [name]: date,
      }));
    } else {
      setRentForecastValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={intl.formatMessage(messages.newRentForecast)}
      actionsProps={[
        {
          children: 'Cancel',
          onClick: closeModal,
        },
        {
          children: 'Save',
          submitButton: true,
          isSubmitting: isLoading,
          onClick: createRentForecast,
          disabled: !name || !date || date < moment().startOf('year'),
        },
      ]}
      ModalProps={{
        PaperProps: {
          sx: {
            width: {
              xs: 'calc(100% - 32px)',
              sm: 480,
            },
            maxHeight: 'calc(100vh - 100px)',
          },
        },
      }}
    >
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Input
          label={intl.formatMessage(messages.name)}
          placeholder={intl.formatMessage(messages.placeholder)}
          onChange={(e) => handleChange('name', e.target.value)}
          required
          value={name}
        />
        <DatePicker
          label={intl.formatMessage(messages.date)}
          placeholder={intl.formatMessage(messages.datePlaceholder)}
          onChange={(date) => handleChange('date', date)}
          required
          disablePast
          value={date}
          views={['year']}
        />
      </Box>
    </Modal>
  );
};

export default RentForecastModal;

import { useMemo } from 'react';

import { HEADERS } from './constants';

import { processTableFilterOptions } from '../../utils/table-helpers';

const useBuildFilterOptions = ({ results }) => {
  return useMemo(() => {
    const options = {
      affordablePrograms: [],
      preferredUnitSize: [],
      incomeLevel: [],
      requestingAccommodation: [],
      status: [],
    };
    if (results) {
      results.forEach((result) => {
        if (result.affordablePrograms) {
          options.affordablePrograms.push(result.affordablePrograms);
        }
        if (result.preferredUnitSize) {
          options.preferredUnitSize.push(result.preferredUnitSize);
        }
        if (result.incomeLevel) {
          options.incomeLevel.push(result.incomeLevel);
        }
        if (result.applicationStatus?.name) {
          options.status.push(result.applicationStatus?.name);
        }
        options.requestingAccommodation.push(
          result.requestingAccommodation ? 'Yes' : 'No',
        );
      });
    }

    // Remove duplicates, sort, and map to label/value objects
    processTableFilterOptions(options);

    return options;
  }, [results]);
};

const useHeaders = ({ results }) => {
  // Add filters if needed later
  const filterOptions = useBuildFilterOptions({ results });
  return useMemo(() => {
    const headers = [];

    // Add headers
    HEADERS.forEach((header) => {
      headers.push({
        ...header,
        filterOptions: header.filterOptions ?? filterOptions[header.id],
      });
    });

    return headers;
  }, [filterOptions]);
};

export default useHeaders;

import React, { memo } from 'react';
import {
  TextArea,
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import useIntl from '../../../hooks/useIntl';
import messages from '../messages';

const Comments = ({ tableState, setTableState }) => {
  const key = 'comments-section';
  const intl = useIntl();

  const handleCommentsChange = (event) => {
    setTableState({
      ...tableState,
      [key]: event.target.value,
    });
  };

  return (
    <Box mx={8}>
      <Typography variant="h2" my={2}>
        {intl.formatMessage(messages.comments)}
      </Typography>
      <TextArea
        value={tableState[key] || ''}
        onChange={handleCommentsChange}
        placeholder="Start typing..."
        rows={20}
      />
    </Box>
  );
};

export default memo(Comments);

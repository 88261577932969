import { post, get, put } from '../utils/api';

type AffordableWaitlist = {
  affordableProgram: string,
  anticipatedAnnualIncome: number,
  incomeLevel: string,
  requestingAccommodations: boolean,
  preferences: Array<string>,
};

export default class AffordableWaitlistService {
  /**
   * Will retrieve all affordable waitlists for specific property
   * -
   * @param {*} organizationId
   * @param {*} propertyId
   * @returns
   */
  getAllAffordableWaitlistsByPropertyId(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ): any {
    return get(`/${organizationId}/${propertyId}/affordable-waitlist`, options);
  }

  /**
   * Will add a household to the affordable waitlist
   * ---
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} payload
   * @param {*} options
   * @returns
   */
  addToWaitlist(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    payload: AffordableWaitlist,
    options?: Object,
  ): any {
    return post(
      `/${organizationId}/${propertyId}/${applicationId}/affordable-waitlist`,
      JSON.stringify(payload),
      options,
    );
  }

  getAffordableWaitlistPropertyPreferences(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/affordable-waitlist/configure-preferences`,
      options,
    );
  }

  createAffordableWaitlistPropertyPreference(
    organizationId: string,
    propertyId: string,
    payload,
    options?: Object,
  ): any {
    const body = JSON.stringify(payload);
    return post(
      `/${organizationId}/${propertyId}/affordable-waitlist/configure-preferences`,
      body,
      options,
    );
  }

  updateAffordableWaitlistPropertyPreference(
    organizationId: string,
    propertyId: string,
    payload,
    options?: Object,
  ): any {
    const body = JSON.stringify(payload);
    return put(
      `/${organizationId}/${propertyId}/affordable-waitlist/configure-preferences`,
      body,
      options,
    );
  }
}

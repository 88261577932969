import { post, get, postDownload } from '../utils/api';

/**
 * - Service that will manage different actions related to the minc transactions.
 * - The transactions are related to the "minc transactions report".
 */
export default class MincTransactionsService {
  /**
   * Will retrieve all minc transactions for a specific property
   * -
   * @note Check reportSertice -> downloadMINCTenantTransactionsReport() for reference
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} id
   * @param {*} startDate
   * @param {*} endDate
   * @returns
   */
  getAllMincTransactions(
    organizationId: string,
    propertyId: string,
    startDate?: string,
    endDate?: string,
    options?: Object,
  ): any {
    let finalQuery = '';
    if (startDate && !endDate) {
      finalQuery += `?startDate=${startDate}`;
    } else if (startDate && endDate) {
      finalQuery += `?startDate=${startDate || null}&endDate=${
        endDate || null
      }`;
    }
    return get(
      `/${organizationId}/${propertyId}/minc-transactions?${finalQuery}`,
      options,
    );
  }

  /**
   * Will generate a MINC report for a specific property.
   * -
   * @note Check reportService -> downloadMincTenantTransactionsReportByIds() for reference
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} ids
   * @returns
   */
  generateMincReport(
    organizationId: string,
    propertyId: string,
    ids: Array<string>,
    fileName: string,
    options?: Object,
  ): any {
    return postDownload(
      `/${organizationId}/${propertyId}/minc-tenant-transactions-by-ids`,
      fileName,
      JSON.stringify({ tenantEventIds: ids }),
      options,
    );
  }

  test(organizationId: string, propertyId: string, options?: Object): any {
    return post(`/${organizationId}/${propertyId}/minc-transactions`, options);
  }
}

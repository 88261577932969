import React, { useState, useCallback, useMemo } from 'react';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  Box,
  Button,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { RefreshIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { promptToaster } from '../../App/actions';
import IntacctModal from './IntacctModal';
import messages from '../messages';
import useIntl from '../../../hooks/useIntl';
import Part1 from './part1';
import Part2 from './part2';
import Part3 from './part3';
import Part5 from './part5';
import Comments from './comments';
import Review from './review';
import StatusCard from './statusCard';

const RDBudgetModal = (props) => {
  const {
    open,
    closeModal,
    forecastName,
    intacctOpen,
    openIntacctModal,
    closeIntacctModal,
  } = props;
  const intl = useIntl();
  const [tableData, setTableData] = useState({
    page: 0,
  });
  const [cardStatus] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: false,
    5: false,
  });

  const handleStatusCardClick = useCallback((index) => {
    setTableData((prev) => ({ ...prev, page: index }));
  }, []);

  const statusCards = useMemo(
    () => [
      {
        label: 'Cash Flow Stmt.',
        tooltip: 'Part I - Cash Flow Statement',
        part: 'I',
        component: (
          <Part1 tableState={tableData} setTableState={setTableData} />
        ),
      },
      {
        label: 'Op. & Maint.',
        tooltip: 'Part II - Operating & Maintenance',
        part: 'II',
        component: (
          <Part2 tableState={tableData} setTableState={setTableData} />
        ),
      },
      {
        label: 'Acct. Bdgt./Status',
        tooltip: 'Part III - Accounting Budget/Status',
        part: 'III',
        component: (
          <Part3 tableState={tableData} setTableState={setTableData} />
        ),
      },
      {
        label: 'Annu. Cap. Bdgt.',
        tooltip: 'Part V - Annual Capital Budget',
        part: 'V',
        component: (
          <Part5 tableState={tableData} setTableState={setTableData} />
        ),
      },
      {
        label: 'Review',
        tooltip: 'Review',
        component: (
          <Review tableState={tableData} setTableState={setTableData} />
        ),
      },
      {
        label: 'Comments',
        tooltip: 'Comments',
        component: (
          <Comments tableState={tableData} setTableState={setTableData} />
        ),
      },
    ],
    [tableData, setTableData],
  );

  const ActivePage = useMemo(
    () => statusCards[tableData.page].component,
    [tableData.page, statusCards],
  );

  const handleIntacctSubmit = () => {
    promptToaster({
      type: 'success',
      title: intl.formatMessage(messages.success),
      message: intl.formatMessage(messages.intacctSuccess),
    });
    closeIntacctModal();
  };

  const Title = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography mr={2}>{forecastName}</Typography>
      <Button
        variant="primarySubtle"
        onClick={openIntacctModal}
        startIcon={<RefreshIcon />}
      >
        {intl.formatMessage(messages.intacctHeader)}
      </Button>
      <IntacctModal
        open={intacctOpen}
        closeModal={closeIntacctModal}
        onSubmit={handleIntacctSubmit}
      />
    </Box>
  );
  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={Title}
      actionsProps={[
        {
          children: 'Cancel',
          onClick: closeModal,
        },
        {
          children: 'Create 3560-7',
          submitButton: true,
          disabled: true,
        },
        {
          children: 'Save Budget',
          submitButton: true,
        },
      ]}
      ModalProps={{
        PaperProps: {
          sx: { width: '100%', maxWidth: 'xl', height: '100%' },
        },
      }}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            paddingBottom: '16px',
            overflowX: 'auto',
          }}
        >
          {statusCards.map((card, index) => (
            <StatusCard
              key={index}
              selected={tableData.page === index}
              onClick={() => handleStatusCardClick(index)}
              success={cardStatus[index]}
              {...card}
            />
          ))}
        </Box>
        {ActivePage}
      </>
    </Modal>
  );
};

export default RDBudgetModal;

import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import messages from '../messages';

export const AltAssetFooter = ({
  totalMarket,
  totalCash,
  totalAssetIncome,
  imputedIncome,
  isHotmaActive,
}) => {
  return (
    <React.Fragment>
      <div className="row highlighted-footer">
        <div className="col-xs-5">
          <div className="row">
            <div className="col-xs-9">
              <FormattedMessage {...messages.totalAssets} />
            </div>
            <div className="col-xs-3" style={{ paddingLeft: '30px' }}>
              <FormattedNumber
                value={totalMarket}
                style={{ value: 'currency' }.value}
                currency="USD"
              />
            </div>
          </div>
        </div>
        <div className="col-xs-7">
          {isHotmaActive ? (
            <div className="row">
              <div className="col-xs-11">
                <div className="row">
                  <div className="col-xs-4" />
                  <div className="col-xs-2" style={{ paddingLeft: '30px' }}>
                    <FormattedNumber
                      value={totalCash}
                      style={{ value: 'currency' }.value}
                      currency="USD"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="row highlighted-footer padtop10">
        <div className="col-xs-5">
          <div className="row">
            <div className="col-xs-8">
              {isHotmaActive ? (
                <FormattedMessage {...messages.totalActualIncome} />
              ) : (
                <FormattedMessage {...messages.totalAnnualIncome} />
              )}
            </div>
          </div>
        </div>
        <div className="col-xs-7">
          <div className="row">
            <div className="col-xs-11">
              <div className="row">
                <div
                  className={`col-xs-offset-${
                    isHotmaActive ? '6' : '9'
                  } col-xs-3`}
                  style={{ paddingLeft: '45px' }}
                >
                  <FormattedNumber
                    value={totalAssetIncome}
                    style={{ value: 'currency' }.value}
                    currency="USD"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Only show this row if hotma is not active */}
      {!isHotmaActive && (
        <div className="row highlighted-footer padtop10">
          <div className="col-xs-5">
            <div className="row">
              <div className="col-xs-8">
                <FormattedMessage {...messages.imputedIncome} />
              </div>
            </div>
          </div>
          <div className="col-xs-7">
            <div className="row">
              <div
                className="col-xs-offset-6 col-xs-3"
                style={{ paddingLeft: '10px' }}
              >
                <FormattedNumber
                  value={imputedIncome}
                  style={{ value: 'currency' }.value}
                  currency="USD"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

import React from 'react';
import DocumentTitle from 'react-document-title';
import { useModal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { SingleSelect } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Box } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import RentForecastModal from './RentForecastModal';
import useIntl from '../../hooks/useIntl';
import TableLayout from '../../components/TableLayout';
import RentForecastHeader from './RentForecastHeader';
import RentForecastTable from './RentForecastTable';
import RDBudgetModal from './RDBudgetModal';
import messages from './messages';
import { useManageRentForecast } from './hooks';

const RentForecast = () => {
  const {
    open: rentForecastOpen,
    openModal: openRentForecastModal,
    closeModal: closeRentForecastModal,
  } = useModal();
  const {
    open: rdBudgetOpen,
    openModal: openRDBudgetModal,
    closeModal: closeRDBudgetModal,
  } = useModal();

  const {
    open: intacctOpen,
    openModal: openIntacctModal,
    closeModal: closeIntacctModal,
  } = useModal();

  const intl = useIntl();

  const refreshFn = (data) => {
    closeRentForecastModal();
    setRentForecastValues({ name: '', date: null });
    refetchRentForecasts().then(() => {
      const newId = data?.rentForecast?.id;
      setCurrentRentForecast(newId);
    });
  };

  const {
    allColumnsHidden,
    columnOptions,
    count,
    createRentForecast,
    currentRentForecast,
    dateState,
    filteredHeaders,
    filterState,
    filterTypeState,
    footerRow,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers,
    isLoading,
    order,
    orderBy,
    refetchRentForecasts,
    rentForecasts,
    rentForecastValues,
    rows,
    searchState,
    selectedColumns,
    setCurrentRentForecast,
    setRentForecastValues,
    tableName,
    totalCount,
  } = useManageRentForecast({ refreshFn });

  const getForecastName = (rentForecast) => {
    return `${rentForecast?.name}${
      rentForecast?.year ? ` - ${rentForecast?.year}` : ''
    }`;
  };

  const selectValues = rentForecasts?.rentForecasts?.map((rentForecast) => ({
    value: rentForecast?.id,
    text: getForecastName(rentForecast),
  }));

  const selectedRentForecastName = getForecastName(
    rentForecasts?.rentForecasts?.find(
      (rentForecast) => rentForecast?.id === currentRentForecast,
    ),
  );

  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <>
        <TableLayout
          name={tableName}
          sx={{
            'button[disabled]': { backgroundColor: 'unset' },
          }}
          childrenElementsHeight={56 + 8}
        >
          {(tableHeight) => (
            <>
              <RentForecastHeader
                openRentForecastModal={openRentForecastModal}
                openRDBudgetModal={openRDBudgetModal}
                count={count}
                totalCount={totalCount}
              />

              <Box
                sx={{
                  maxWidth: '200px',
                  visibility: selectValues?.length ? 'visible' : 'hidden',
                }}
              >
                <SingleSelect
                  name=""
                  options={selectValues}
                  onChange={setCurrentRentForecast}
                  value={currentRentForecast}
                />
              </Box>
              <RentForecastTable
                allColumnsHidden={allColumnsHidden}
                columnOptions={columnOptions}
                count={count}
                dateState={dateState}
                filterState={filterState}
                filterTypeState={filterTypeState}
                filteredHeaders={filteredHeaders}
                footerRow={footerRow}
                handleColumnChange={handleColumnChange}
                handleDateSubmit={handleDateSubmit}
                handleFilterChange={handleFilterChange}
                handleFilterTypeChange={handleFilterTypeChange}
                handleSearchSubmit={handleSearchSubmit}
                handleSortChange={handleSortChange}
                headers={headers}
                height={tableHeight}
                intl={intl}
                name={tableName}
                order={order}
                orderBy={orderBy}
                rows={rows}
                searchState={searchState}
                selectedColumns={selectedColumns}
                totalCount={totalCount}
              />
            </>
          )}
        </TableLayout>
        <RentForecastModal
          open={rentForecastOpen}
          closeModal={closeRentForecastModal}
          rentForecastValues={rentForecastValues}
          setRentForecastValues={setRentForecastValues}
          createRentForecast={createRentForecast}
          isLoading={isLoading}
        />
        <RDBudgetModal
          open={rdBudgetOpen}
          closeModal={closeRDBudgetModal}
          forecastName={selectedRentForecastName}
          intacctOpen={intacctOpen}
          openIntacctModal={openIntacctModal}
          closeIntacctModal={closeIntacctModal}
        />
      </>
    </DocumentTitle>
  );
};

export default RentForecast;

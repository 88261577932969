import { useState, useEffect } from 'react';

/**
 * Hook to manage the checkboxes column state
 * ---
 * @TODO need to re-implement custom hook, since it doesn't behave as expected with filtering/sorting
 * @param {*} param0.results all the rows of the table
 * @param {*} param0.show a feature flag to show the checkboxes column
 * @returns
 */
const useCheckboxSelection = ({ results }) => {
  const [selectedIds, setSelectedIds] = useState(new Set());
  useEffect(() => {
    setSelectedIds(new Set());
  }, [results]);

  const handleSelectDeSelectAllRows = (event) => {
    event.persist();
    const checked = event.target.checked;
    if (checked) {
      setSelectedIds(new Set(results.map((row) => row.id)));
    }
    if (!checked) {
      setSelectedIds(new Set());
    }
  };

  const handleSelectRow = (row) => {
    const id = row.id;
    setSelectedIds((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const isAtLeastOneRowSelected = selectedIds.size > 0;

  return {
    selectedIds,
    handleSelectDeSelectAllRows,
    handleSelectRow,
    isAtLeastOneRowSelected,
  };
};

export default useCheckboxSelection;

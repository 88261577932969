import { useEffect } from 'react';

// Components
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { toastr } from 'react-redux-toastr';

// Types
import type { AddToWaitlistInfo } from '../types';

// Others
import AffordableService from '../../../services/affordableService';

type AddToWaitlistPayload = AddToWaitlistInfo & {
  anticipatedAnnualIncome: number,
};

const labels = [
  'Applicant',
  'Phone',
  'Income Level',
  'Household Size',
  'Preferred Bedroom Size',
  'Accessible Unit',
];

const affordableService = new AffordableService();

const AddToWaitlistModal = ({
  organizationId,
  propertyId,
  // Modal
  open,
  handleClose,
  modalProps,
  // Form
  ReactHookFormProps,
  formProps,
  // Info
  addToWaitlistInfo,
  anticipatedAnnualIncome,
  setAddToWaitlistIncomeLevel,
  currentIncomeLevel,
}) => {
  useEffect(() => {
    const payload: AddToWaitlistPayload = {
      ...addToWaitlistInfo,
      anticipatedAnnualIncome: anticipatedAnnualIncome || '',
    };
    const getIncomeLevelCalculation = async () => {
      const res = await affordableService.calculateIncomeLevel(
        organizationId,
        propertyId,
        payload,
      );
      const incomeLevelIndicator = res.data?.incomeLevel;
      if (incomeLevelIndicator)
        setAddToWaitlistIncomeLevel(incomeLevelIndicator);
      else {
        toastr.error('Error', 'Failed to calculate income level');
        setAddToWaitlistIncomeLevel('Not Determined');
      }
    };
    getIncomeLevelCalculation();
  }, [anticipatedAnnualIncome]);
  return (
    <Modal open={open} onClose={handleClose} {...modalProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant={'bodyMedium'} color={'text.secondary'}>
            Household Details
          </Typography>
          {addToWaitlistInfo &&
            Object.values(addToWaitlistInfo).map((item, index) => {
              let value = item;
              if (labels[index] === 'Income Level') {
                value = currentIncomeLevel;
              }
              return (
                <Box
                  key={index}
                  sx={{
                    padding: '14px 0',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant={'label'}>{labels[index]}</Typography>
                  <Typography
                    variant={'bodyMedium'}
                    sx={{
                      padding: '4px 0',
                    }}
                  >
                    {value}
                  </Typography>
                </Box>
              );
            })}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              padding: '16px',
              borderRadius: '4px',
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Form ReactHookFormProps={ReactHookFormProps} {...formProps} />
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddToWaitlistModal;

import CashFlowTable from './table';
import React, { memo } from 'react';

const headers = [
  { label: '', key: 'label', flex: 1.5, textAlign: 'left' },
  {
    label: 'Price',
    subLabel: 'Current',
    key: 'priceCurrent',
    flex: 1,
    editable: true,
    placeholder: '0.00',
    type: 'currency',
  },
  {
    label: 'Units/Items',
    subLabel: 'Proposed',
    key: 'unitsProposed',
    flex: 1,
    editable: true,
    placeholder: '0',
  },
  {
    label: 'Reserve',
    subLabel: 'Proposed',
    key: 'reserveProposed',
    flex: 1,
    editable: true,
    placeholder: '0.00',
    type: 'currency',
  },
  {
    label: 'Reserve',
    subLabel: 'Actual',
    key: 'reserveActual',
    flex: 1,
    placeholder: '0.00',
    type: 'currency',
  },
  {
    label: 'Operating',
    subLabel: 'Proposed',
    key: 'operatingProposed',
    flex: 1,
    placeholder: '0.00',
    type: 'currency',
  },
  {
    label: 'Operating',
    subLabel: 'Actual',
    key: 'operatingActual',
    flex: 1,
    placeholder: '0.00',
    type: 'currency',
  },
  {
    label: 'Total Cost',
    subLabel: 'Actual',
    key: 'totalActual',
    flex: 1,
    placeholder: '0.00',
    type: 'currency',
  },
  {
    label: 'Units/Items',
    subLabel: 'Total Actual',
    key: 'unitsActual',
    flex: 1,
    placeholder: '0',
  },
  {
    label: 'Comments or (YTD)',
    key: 'comments',
    flex: 2,
    editable: true,
    placeholder: 'Comment or (YTD)',
  },
];

const data = [
  {
    header: 'Appliances',
    rows: [
      {
        label: 'Range',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Refrigerator',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Range Hood',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Washers & Dryers',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Carpet & Vinyl',
    rows: [
      {
        label: '1BR',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: '2BR',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: '3BR',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: '4BR',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Cabinets',
    rows: [
      {
        label: 'Kitchens',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Bathrooms',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Doors',
    rows: [
      {
        label: 'Exterior',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Interior',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Window Coverings',
    rows: [
      {
        label: 'List',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Heating & AC',
    rows: [
      {
        label: 'Heating',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Air Conditioning',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Plumbing',
    rows: [
      {
        label: 'Water Heater',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Bath Sinks',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Kitchen Sinks',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Faucets',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Toilets',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Major Electrical',
    rows: [
      {
        label: 'List',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Structures',
    rows: [
      {
        label: 'Windows',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Screens',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Walls',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Roofing',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Siding',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Exterior Painting',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Paving',
    rows: [
      {
        label: 'Asphalt',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Concrete',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Seal & Stripe',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Landscape & Grounds',
    rows: [
      {
        label: 'Landscaping',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Lawn Equipment',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Fencing',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Recreation Area',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Signs',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Accessiblity features',
    rows: [
      {
        label: 'List',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Automation Equipment',
    rows: [
      {
        label: 'Site Management',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Common Area',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
  {
    header: 'Other',
    rows: [
      {
        label: 'Other List 1',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other List 2',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
      {
        label: 'Other List 3',
        reserveActual: '$0.00',
        operatingProposed: '$0.00',
        operatingActual: '$0.00',
        totalActual: '$0.00',
        unitsActual: '0',
      },
    ],
  },
];

const Part5 = ({ tableState, setTableState }) => (
  <CashFlowTable
    data={data}
    headers={headers}
    tableState={tableState}
    setTableState={setTableState}
  />
);

export default memo(Part5);

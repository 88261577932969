import { useQuery, QueryOptions } from 'react-query';

import AffordableWaitlistService from '../../services/affordableWaitlistService';

const useManageAffordableWaitlistPropertyPreferences = ({
  organizationId,
  propertyId,
  options = {},
}: {
  organizationId: string,
  propertyId: string,
  options?: QueryOptions,
}) => {
  const affordableWaitlistService = new AffordableWaitlistService();
  const queryKey = [
    'getAffordableWaitlistConfigurePreference',
    organizationId,
    propertyId,
  ];

  const { data, isLoading, refetch } = useQuery(
    queryKey,
    async () => {
      const response =
        await affordableWaitlistService.getAffordableWaitlistPropertyPreferences(
          organizationId,
          propertyId,
        );
      return response;
    },
    { refetchOnWindowFocus: false, ...options },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export default useManageAffordableWaitlistPropertyPreferences;

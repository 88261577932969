import isEmpty from 'lodash/isEmpty';

import { get } from '../utils/api';

class AffordableConfigsService {
  getAffordableConfigsByDate({
    organizationId,
    propertyId,
    effectiveDate,
    options = {},
  }) {
    if (isEmpty(effectiveDate ?? '')) {
      throw new Error(
        'AffordableConfigsService.getAffordableConfigsByDate: effectiveDate is required',
      );
    }
    const queryParams = new URLSearchParams({
      date: effectiveDate,
    });
    return get(
      `/${organizationId}/${propertyId}/affordable-configs?${queryParams}`,
      options,
    );
  }
}

export default AffordableConfigsService;

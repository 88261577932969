import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import moment, { Moment } from 'moment';

import { get, post, postDownload, put } from '../utils/api';

export default class AffordableService {
  // TODO: AFFORDABLE_59: remove in favor of the new method below it.
  getAllAffordablePrograms(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/set-aside-programs/${affordableQualificationId}`,
      options,
    );
  }

  getAllSetAsideProgramsForProperty(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(`/${organizationId}/${propertyId}/set-aside-programs`, options);
  }

  batchEditSetAsidePrograms(
    organizationId: string,
    propertyId: string,
    setAsidePrograms: Array<Object>,
    options?: Object,
  ) {
    if (!setAsidePrograms || setAsidePrograms.length === 0) {
      throw new Error('Could not update Set Aside Programs');
    }

    return put(
      `/${organizationId}/${propertyId}/set-aside-programs/batch/edit`,
      JSON.stringify(setAsidePrograms),
      options,
    );
  }

  modifySetAsideProgram(
    organizationId: string,
    propertyId: string,
    setAsideProgram: Object,
  ) {
    if (!setAsideProgram || !setAsideProgram.id) {
      throw new Error('Could not update this Set Aside Program');
    }

    const payload = omit(setAsideProgram, ['id']);

    return put(
      `/${organizationId}/${propertyId}/set-aside-programs/${setAsideProgram.id}`,
      JSON.stringify(payload),
    );
  }

  addSetAsideIncomeLimits(
    organizationId: string,
    propertyId: string,
    payload: Object,
    duplicateFromId: string,
  ) {
    if (!duplicateFromId) {
      throw new Error('Could not add these Income Limits');
    }

    return post(
      `/${organizationId}/${propertyId}/set-aside-programs/${duplicateFromId}/income-limits`,
      JSON.stringify(payload),
    );
  }

  getAllSetAsidePrograms(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/affordable-qualifications/${affordableQualificationId}/set-aside-programs`,
      options,
    );
  }

  getAllRentalAssistanceSources(options?: Object) {
    return get('/rental-assistance-sources', options);
  }

  getRDEligibilitySources(options?: Object) {
    return get('/rd-eligibility-sources', options);
  }

  getAllComplianceStatuses(options?: Object) {
    return get('/compliance-statuses', options);
  }

  getAllCorrectionReasons(options?: Object) {
    return get('/correction-reasons', options);
  }

  getAllHUDIncomeLimits() {
    return get('/hud-income-limits');
  }

  getUtilityAllowances(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/household-utility-allowances`,
      options,
    );
  }

  stringifyQueryParam(param, value) {
    return `${param}=${value}`;
  }

  async getActiveFloorPlanUtilityAllowancesByFloorPlanIds(
    organizationId: string,
    propertyId: string,
    floorPlanIds?: Array<string>,
    options?: Object<string, string>,
    activeForDate?: Moment,
  ): Promise<Array> {
    const activeForDateQuery = moment(activeForDate ?? '').isValid()
      ? { activeForDate: moment(activeForDate).utc().startOf('day').format() }
      : {};
    const cleanedUpFloorPlanIds = [...(floorPlanIds ?? [])].filter((id) => id);
    const floorPlanIdsQuery = {
      floorPlanId: cleanedUpFloorPlanIds, // not plural here because single kept for API consistency
    };
    const queryParamsObject = {
      ...floorPlanIdsQuery,
      ...activeForDateQuery,
    };

    const queryString = Object.entries(queryParamsObject)
      .map(([param, value]) => {
        if (Array.isArray(value)) {
          return value.map((individual) => `${param}=${individual}`).join('&');
        }
        return `${param}=${value}`;
      })
      .join('&');

    return get(
      `/${organizationId}/${propertyId}/floor-plan-utility-allowances?${queryString}`,
      options,
    );
  }

  updateHouseholdQualificationHistory(
    organizationId: string,
    propertyId: string,
    qualificationHistoryId: string,
    values: Object,
  ) {
    return put(
      `/${organizationId}/${propertyId}/affordable-history/${qualificationHistoryId}`,
      JSON.stringify(values),
    );
  }

  updateQualificationChecklist(
    organizationId: string,
    propertyId: string,
    affordableChecklistHistoryId: string,
    checklist: Object,
  ) {
    return put(
      `/${organizationId}/${propertyId}/affordable-checklist-history/${affordableChecklistHistoryId}`,
      JSON.stringify(checklist),
    );
  }
  getQualificationChecklist(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    applicantId: string,
  ) {
    /* eslint-disable max-len */
    return get(
      `/${organizationId}/${propertyId}/application/${applicationId}/applicant/${applicantId}/affordableQualificationChecklist`,
    );
  }

  runQualificationTest(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    affordableQualificationId?: string,
    options?: Object,
  ) {
    return post(
      `/${organizationId}/${propertyId}/test-household`,
      JSON.stringify({ applicationId, affordableQualificationId }),
      options,
    );
  }

  calculateIncomeLevel(
    organizationId: string,
    propertyId: string,
    payload: Object,
    options?: Object,
  ): any {
    return post(
      `/${organizationId}/${propertyId}/calculate-income-level`,
      JSON.stringify(payload),
      options,
    );
  }

  generateTIC(
    organizationId: string,
    propertyId: string,
    payload: any,
    fileName: string,
    options?: Object,
  ) {
    const {
      applicationId,
      documentTypeName,
      isRecertification,
      residentId,
      setAsideProgramId,
      affordableQualificationId,
    } = payload;

    return postDownload(
      `/${organizationId}/${propertyId}/tic`,
      fileName,
      JSON.stringify({
        applicationId,
        documentTypeName,
        isRecertification,
        setAsideProgramId,
        residentId,
        affordableQualificationId,
      }),
      options,
    );
  }

  createOrUpdateQualification(
    payload: Object,
    organizationId: string,
    propertyId: string,
    householdId: string,
    programName: string,
    options?: Object,
  ) {
    return post(
      `/${organizationId}/${propertyId}/households/${householdId}/affordable/${programName}/qualification`,
      JSON.stringify(payload),
      options,
    );
  }

  getAffordableDisplacementStatusCodes(
    organizationId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/affordable-displacement-status-codes`,
      options,
    );
  }

  getAffordablePreviousHousings(organizationId: string, options?: Object) {
    return get(`/${organizationId}/affordable-previous-housings`, options);
  }

  getAffordableWaiverCodes(organizationId: string, options?: Object) {
    return get(`/${organizationId}/affordable-waiver-codes`, options);
  }

  getAffordableExtenuatingCircumstances(
    organizationId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/affordable-extenuating-circumstances`,
      options,
    );
  }

  getAffordableHardshipExceptions(organizationId: string, options?: Object) {
    return get(`/${organizationId}/affordable-hardship-exceptions`, options);
  }

  getAffordableIncomeExceptionCodes(organizationId: string, options?: Object) {
    return get(`/${organizationId}/affordable-income-exception-codes`, options);
  }

  createOrUpdateAffordableException(
    organizationId: string,
    propertyId: string,
    values: Object,
    options?: Object,
  ) {
    const valuesObj = { ...values };
    const id = valuesObj.id;
    delete valuesObj.id;
    return id
      ? put(
          `/${organizationId}/${propertyId}/affordable-HUD-exceptions/${id}`, // should be exemptions
          JSON.stringify(values),
          options,
        )
      : post(
          `/${organizationId}/${propertyId}/affordable-HUD-exceptions`, // should be exemptions
          JSON.stringify(values),
          options,
        );
  }

  getHouseholdCitizenshipEligibility(organizationId: string, options?: Object) {
    return get(`/${organizationId}/household-citizenship-eligibility`, options);
  }

  getAffordableTerminationCodes(
    organizationId: string,
    propertyId: string,
    floorplanHUDSubsidyCodes?: Array<string>,
    options?: Object,
  ) {
    const params = !isEmpty(floorplanHUDSubsidyCodes ?? [])
      ? `?subsidyProgramCodes=${floorplanHUDSubsidyCodes.join(',')}`
      : '';
    return get(
      `/${organizationId}/${propertyId}/master-termination-codes${params}`,
      options,
    );
  }

  getAffordableMoveOutCodes(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/master-move-out-codes`,
      options,
    );
  }

  getAffordableCorrectionCodes(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/master-correction-codes`,
      options,
    );
  }

  getHUDTransferProps(
    organizationId: string,
    propertyId: string,
    householdId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/${householdId}/hud-transfer-props`,
      options,
    );
  }
}

import { useMemo } from 'react';
import { useMutation } from 'react-query';
import AffordableWaitlistService from '../../services/affordableWaitlistService';

export const useCreateAffordableWaitlistPropertyPreferences = ({
  organizationId,
  propertyId,
  options,
}) => {
  const affordableWaitlistService = useMemo(
    () => new AffordableWaitlistService(),
    [],
  );
  const mutation = useMutation(
    ({ payload }) =>
      affordableWaitlistService.createAffordableWaitlistPropertyPreference(
        organizationId,
        propertyId,
        payload,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

export const useUpdateAffordableWaitlistPropertyPreferences = ({
  organizationId,
  propertyId,
  options,
}) => {
  const affordableWaitlistService = useMemo(
    () => new AffordableWaitlistService(),
    [],
  );
  const mutation = useMutation(
    ({ payload }) =>
      affordableWaitlistService.updateAffordableWaitlistPropertyPreference(
        organizationId,
        propertyId,
        payload,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

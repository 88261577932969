import React, { Component } from 'react';
import isNil from 'lodash/isNil';

import SubmitButton from '../../../../../components/SubmitButton';

type Props = {
  handleSubmit: Function,
  deniedReasons: ?any,
  checklistApproved: ?boolean,
};

type State = {
  decision: ?string,
  cancelReasonId: ?string,
  isSubmitting: boolean,
};
class ApplicantApprovalContainer extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      decision: null,
      cancelReasonId: null,
      isSubmitting: false,
    };
  }
  handleCancelReasonSelectChange = (event: any) => {
    this.setState({
      cancelReasonId: event.target.value,
    });
  };
  handleApplicantsApprovalDecision = (event: any) => {
    this.setState({
      decision: event.target.value,
    });
  };
  handleClick = () => {
    this.props.handleSubmit(this.state, () => {
      this.setState({ isSubmitting: false });
    });
    this.setState({ isSubmitting: true });
  };
  render() {
    const { deniedReasons, checklistApproved } = this.props;
    if (!deniedReasons) {
      return <div />;
    }

    const isApprovedDecision = this.state.decision === 'approve';
    const isCancelDecision = this.state.decision === 'cancel';
    const cancelReasonSelected = !isNil(this.state.cancelReasonId);

    const isSubmitDecisionEnabled =
      (isApprovedDecision && checklistApproved) ||
      (isCancelDecision && cancelReasonSelected);

    const isApproveButtonEnabled = checklistApproved;

    return (
      <div className="row padtop20 padleft10">
        <div className="co-xs-12">
          <h3>Approve Move-In For this new household Member?</h3>
          <div className="row">
            <div className="col-xs-2">
              <label className="padleft5">
                <input
                  type="radio"
                  value="approve"
                  checked={this.state.decision === 'approve'}
                  onChange={this.handleApplicantsApprovalDecision}
                  disabled={isApproveButtonEnabled !== true}
                />
                <span
                  style={{
                    marginLeft: '5px',
                    color: isApproveButtonEnabled !== true ? '#bfc2c4' : '',
                  }}
                >
                  Approve
                </span>
              </label>
            </div>
            <div className="col-xs-3">
              <label style={{ paddingRight: '10px' }}>
                <input
                  type="radio"
                  value="cancel"
                  checked={this.state.decision === 'cancel'}
                  onChange={this.handleApplicantsApprovalDecision}
                />
                <span style={{ marginLeft: '5px' }}>Cancel Application</span>
              </label>
            </div>
            {isCancelDecision && (
              <div>
                <div className="col-xs-2">
                  <label className="textRight">Reason for Cancellation</label>
                </div>
                <div className="col-xs-4">
                  <select
                    className="input-lg form-control"
                    defaultValue=""
                    onChange={this.handleCancelReasonSelectChange}
                    value={this.state.cancelReasonId}
                  >
                    <option value="" disabled>
                      Choose
                    </option>
                    {deniedReasons.map((denied) => (
                      <option key={denied.id} value={denied.id}>
                        {denied.reason}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-xs-12 padbottom20">
              <SubmitButton
                bsStyle="primary"
                disabled={isSubmitDecisionEnabled !== true}
                clickHandler={this.handleClick}
                isSubmitting={this.state.isSubmitting}
              >
                Submit Decision
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicantApprovalContainer;

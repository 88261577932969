import { useMemo } from 'react';
import { useQuery } from 'react-query';

import AffordableConfigsService from '../../../services/affordableConfigsService';

export const useFetchAffordableConfigs = ({
  organizationId,
  propertyId,
  effectiveDate,
}) => {
  const service = useMemo(() => new AffordableConfigsService(), []);
  return useQuery(
    ['affordableConfigs', organizationId, propertyId],
    () =>
      service.getAffordableConfigsByDate({
        organizationId,
        propertyId,
        effectiveDate,
      }),
    {
      enabled: !!organizationId && !!propertyId && !!effectiveDate,
      refetchInterval: 60000,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      staleTime: 59900,
    },
  );
};

import { useMutation, UseMutationOptions } from 'react-query';
import MasterAffordableProgramDocumentTypeService from '../../services/masterAffordableProgramDocumentTypeService';

const useUploadSignedMutation = ({
  propertyId,
  organizationId,
  options,
}: {
  propertyId: string,
  organizationId: string,
  options?: UseMutationOptions,
}) => {
  return useMutation(
    [
      'MasterAffordableProgramDocumentTypeService.uploadCompliancePacket',
      propertyId,
      organizationId,
    ],
    async ({ affordableQualificationId, document }) => {
      return await new MasterAffordableProgramDocumentTypeService().uploadCompliancePacket(
        organizationId,
        propertyId,
        affordableQualificationId,
        document,
      );
    },
    options,
  );
};

export default useUploadSignedMutation;

// Table Hooks
import useUniqueTableName from '../../hooks/useUniqueTableName';
import useManageMincTransactionsQuery from './hooks.useManageMincTransactionsQuery';
import useRows from './hooks.useRows';
import {
  useTableFilterSortSearchManager,
  useTableFilterSortData,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useHeaders from './hooks.useHeaders';

// Other Hooks
import useModalProps from '../../hooks/useModalProps';
import useCheckboxSelection from '../../hooks/useCheckboxSelection';
import useMincTransactionsCSVExport from './hooks.useMincTransactionsCSVExport';
import useParseResults from './hooks.useParseResults';
import useGenerateMincTransactionsReport from './hooks.useGenerateMincTransactionsReport';

// Constants
import { PROPERTY_PATH_MAP } from './constants';

const useManageMincTransactions = ({ organizationId, propertyId }) => {
  const name = useUniqueTableName('manage-minc-transactions-table');

  const {
    data,
    isLoading,
    refetch: refetchTransactions,
  } = useManageMincTransactionsQuery({
    organizationId,
    propertyId,
    startDate: null,
    endDate: null,
  });

  // Parse results
  const parsedResults = useParseResults({ results: data });

  // Handle checkboxes column
  // TODO: need to re-implement custom hook, since it doesn't behave as expected with filtering/sorting
  const {
    selectedIds,
    handleSelectRow,
    handleSelectDeSelectAllRows,
    isAtLeastOneRowSelected,
  } = useCheckboxSelection({
    results: parsedResults,
  });

  const headers = useHeaders({
    results: data,
    checkBoxListProps: {
      handleSelectDeSelectAllRows,
    },
  });

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    initialOrderBy: 'certificationCompletionDate',
    initialOrder: 'DESC',
    headers,
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results: parsedResults,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  const modalProps = useModalProps();

  const rows = useRows({
    mincTransactions: sortedAndFilteredResults,
    checkBoxListProps: {
      selectedIds,
      handleSelectRow,
    },
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const { onCSVButtonClick } = useMincTransactionsCSVExport({
    filteredHeaders,
    rows,
    hasAnyFilters: Boolean(Object.keys(filterState)?.length),
  });

  const {
    mutate: generateReport,
    isLoading: isReportLoading,
    isSuccess: isReportSuccess,
    error: reportError,
  } = useGenerateMincTransactionsReport({
    organizationId,
    propertyId,
    selectedIds: Array.from(selectedIds),
  });

  return {
    modalProps,
    // Handle Sort/Filter/Search
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    // Columns
    handleColumnChange,
    allColumnsHidden,
    columnOptions,
    selectedColumns,
    // Indicators
    isLoading,
    count: rows.length ?? 0,
    totalCount: sortedAndFilteredResults.length ?? 0,
    name,
    order,
    orderBy,
    // Data
    filteredHeaders,
    refetchTransactions,
    rows,
    onCSVButtonClick,
    // For Report Generation
    selectedIds,
    generateReport,
    isReportLoading,
    isReportSuccess,
    reportError,
    // Pagination
    // pageCount: data?.meta?.pageCount,
    // perPage: LIMIT_PER_PAGE,
    // currentPage,
    // onPageChange,
    // Checkboxes
    isAtLeastOneRowSelected,
  };
};

export default useManageMincTransactions;

import type { AssetFormList } from '../types';

export const FORM_INFO: AssetFormList = {
  initialAssets: [
    {
      name: 'cashHeld',
      forms: 'aob',
    },
    {
      name: 'checkingAccount',
      forms: 'vob',
      expandable: true,
      fieldLimit: 4,
    },
    {
      name: 'savingsAccount',
      forms: 'vob',
      expandable: true,
      fieldLimit: 4,
    },
    {
      name: 'payCard',
      forms: 'vob',
      expandable: true,
      fieldLimit: 4,
    },
    {
      name: 'certificateOfDeposit',
      forms: 'vob',
      expandable: true,
      fieldLimit: 4,
    },
    {
      name: 'trustAccount',
      forms: 'vob',
      expandable: true,
      fieldLimit: 4,
    },
    {
      name: 'treasuryMoneyMarketFund',
      forms: 'vob',
      expandable: true,
      fieldLimit: 4,
    },
    {
      name: 'stocksOrBonds',
      forms: 'vosab',
      expandable: true,
      fieldLimit: 4,
    },
  ],
  retirementAccounts: [
    {
      number: '',
      name: 'ira',
      forms: null,
      expandable: true,
      fieldLimit: 4,
    },
    {
      number: '',
      name: 'pension',
      forms: null,
      expandable: true,
      fieldLimit: 4,
    },
    {
      number: '',
      name: 'keogh',
      forms: 'vora',
      expandable: true,
      fieldLimit: 4,
    },
    {
      number: '',
      name: 'k401',
      forms: null,
      expandable: true,
      fieldLimit: 4,
    },
    {
      number: '',
      name: 'otherRetirementAccount',
      forms: null,
    },
  ],
  lifeInsurance: [
    {
      name: 'lifeInsurance',
      forms: 'voli',
      expandable: true,
      fieldLimit: 4,
    },
  ],
  finalAssets: [
    {
      name: 'realEstate',
      forms: 'coa',
      expandable: true,
      fieldLimit: 4,
    },
    {
      name: 'rentalProperty',
      forms: 'coa',
      expandable: true,
      fieldLimit: 4,
    },
    {
      name: 'disposed',
      forms: 'coda',
    },
    {
      name: 'investmentProperties',
      forms: 'coda',
      expandable: true,
      fieldLimit: 4,
    },
    {
      name: 'other',
      forms: 'applicableDocumentation',
      expandable: true,
      fieldLimit: 10,
    },
    {
      name: 'federalTaxRefundOrCredit',
      forms: 'ftroc',
      expandable: true,
      fieldLimit: 4,
      tooltip: {
        type: 'INFO',
        message: 'federalTaxRefundOrCreditTooltip',
      },
    },
  ],
};

export const ASSET_VALUE_FIELDS = [
  'cashHeldAssetValue',
  'certificateOfDepositAssetValue',
  'checkingAccountAssetValue',
  'disposedAssetValue',
  'investmentPropertiesAssetValue',
  'iraAssetValue',
  'k401AssetValue',
  'keoghAssetValue',
  'otherRetirementAccountAssetValue',
  'lifeInsuranceAssetValue',
  'otherAssetValue',
  'payCardAssetValue',
  'pensionAssetValue',
  'realEstateAssetValue',
  'rentalPropertyAssetValue',
  'savingsAccountAssetValue',
  'stocksOrBondsAssetValue',
  'treasuryMoneyMarketFundAssetValue',
  'trustAccountAssetValue',
];

export const ASSET_INCOME_FIELDS = [
  'cashHeldAssetIncome',
  'certificateOfDepositAssetIncome',
  'checkingAccountAssetIncome',
  'disposedAssetIncome',
  'investmentPropertiesAssetIncome',
  'iraAssetIncome',
  'k401AssetIncome',
  'keoghAssetIncome',
  'otherRetirementAccountAssetIncome',
  'lifeInsuranceAssetIncome',
  'otherAssetIncome',
  'payCardAssetIncome',
  'pensionAssetIncome',
  'realEstateAssetIncome',
  'rentalPropertyAssetIncome',
  'savingsAccountAssetIncome',
  'stocksOrBondsAssetIncome',
  'treasuryMoneyMarketFundAssetIncome',
  'trustAccountAssetIncome',
];

export const ASSET_ROW_NAMES = [
  'cashHeld',
  'certificateOfDeposit',
  'checkingAccount',
  'disposed',
  'investmentProperties',
  'ira',
  'k401',
  'keogh',
  'otherRetirementAccount',
  'lifeInsurance',
  'other',
  'payCard',
  'pension',
  'realEstate',
  'rentalProperty',
  'savingsAccount',
  'stocksOrBonds',
  'treasuryMoneyMarketFund',
  'trustAccount',
  'federalTaxRefundOrCredit',
];

export const NEW_ALTERNATE_ROW = {
  marketValue: '0.00',
  interestRate: '0.00',
  feesToConvertCash: '0.00',
  cashValue: '0.00',
  annualIncome: '0.00',
  actualImpute: '-',
};
export const SUITABLE_FOR_OCC_ROW = {
  ...NEW_ALTERNATE_ROW,
  suitableForOccupancy: null,
};

export const INITIAL_FIELD_ARRAY_VALUES = [NEW_ALTERNATE_ROW];

export const INITIAL_ASSET_VALUES = {
  cashHeldAssetIncome: '0.00',
  cashHeldAssetValue: '0.00',
  cashHeldAssetInterestRate: '0.00',
  certificateOfDepositAssetIncome: '0.00',
  certificateOfDepositAssetValue: '0.00',
  certificateOfDepositInterestRate: '0.00',
  checkingAccountAssetIncome: '0.00',
  checkingAccountAssetValue: '0.00',
  checkingAccountInterestRate: '0.00',
  disposedAssetIncome: '0.00',
  disposedAssetValue: '0.00',
  disposedAssetInterestRate: '0.00',
  investmentPropertiesAssetIncome: '0.00',
  investmentPropertiesAssetValue: '0.00',
  investmentPropertiesInterestRate: '0.00',
  iraAssetIncome: '0.00',
  iraAssetValue: '0.00',
  iraInterestRate: '0.00',
  k401AssetIncome: '0.00',
  k401AssetValue: '0.00',
  k401InterestRate: '0.00',
  keoghAssetIncome: '0.00',
  keoghAssetValue: '0.00',
  keoghInterestRate: '0.00',
  lifeInsuranceAssetIncome: '0.00',
  lifeInsuranceAssetValue: '0.00',
  lifeInsuranceInterestRate: '0.00',
  otherRetirementAccountAssetIncome: '0.00',
  otherRetirementAccountAssetValue: '0.00',
  otherRetirementAccountInterestRate: '0.00',
  payCardAssetIncome: '0.00',
  payCardAssetValue: '0.00',
  payCardInterestRate: '0.00',
  pensionAssetIncome: '0.00',
  pensionAssetValue: '0.00',
  pensionInterestRate: '0.00',
  realEstateAssetIncome: '0.00',
  realEstateAssetValue: '0.00',
  realEstateInterestRate: '0.00',
  rentalPropertyAssetIncome: '0.00',
  rentalPropertyAssetValue: '0.00',
  rentalPropertyInterestRate: '0.00',
  savingsAccountAssetIncome: '0.00',
  savingsAccountAssetValue: '0.00',
  savingsAccountInterestRate: '0.00',
  stocksOrBondsAssetIncome: '0.00',
  stocksOrBondsAssetValue: '0.00',
  stocksOrBondsInterestRate: '0.00',
  treasuryMoneyMarketFundAssetIncome: '0.00',
  treasuryMoneyMarketFundAssetValue: '0.00',
  treasuryMoneyMarketFundInterestRate: '0.00',
  trustAccountAssetIncome: '0.00',
  trustAccountAssetValue: '0.00',
  trustAccountInterestRate: '0.00',
  otherAssetIncome: '0.00',
  otherAssetValue: '0.00',
  otherInterestRate: '0.00',
};

export const ALL_ASSETS_DEFINITIONS = [
  ...FORM_INFO.initialAssets,
  ...FORM_INFO.retirementAccounts,
  ...FORM_INFO.lifeInsurance,
  ...FORM_INFO.finalAssets,
];
const INITIAL_ROWS_MAP = {
  other: [{ ...NEW_ALTERNATE_ROW, otherName: '' }],
  realEstate: [{ ...NEW_ALTERNATE_ROW, suitableForOccupancy: null }],
  rentalProperty: [{ ...NEW_ALTERNATE_ROW, suitableForOccupancy: null }],
};

export const ALTERNATE_FORM_INITIAL_VALUES = ALL_ASSETS_DEFINITIONS.reduce(
  (initValues, asset) => {
    const name = asset.name;
    if (asset.expandable) {
      const initialArrayValues =
        INITIAL_ROWS_MAP[name] ?? INITIAL_FIELD_ARRAY_VALUES;
      return {
        ...initValues,
        [`${name}List`]: initialArrayValues,
      };
    } else {
      return {
        ...initValues,
        [`${name}AssetMarketValue`]: '0.00',
        [`${name}AssetInterestRate`]: '0.00',
        [`${name}AssetFeesToConvertCash`]: '0.00',
        [`${name}AssetCashValue`]: '0.00',
        [`${name}AssetAnnualIncome`]: '0.00',
        [`${name}AssetActualImpute`]: '-',
      };
    }
  },
  { isAltForm: true },
);

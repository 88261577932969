import { useMemo } from 'react';
import { useMutation } from 'react-query';
import AffordableWaitlistService from '../../services/affordableWaitlistService';

/**
 * @param {*} param0
 * @returns
 */
export const useAddToWaitlist = ({
  organizationId,
  propertyId,
  applicationId,
  options,
}) => {
  const affordableWaitlistService = useMemo(
    () => new AffordableWaitlistService(),
    [],
  );
  const mutation = useMutation(
    ({ payload }) =>
      affordableWaitlistService.addToWaitlist(
        organizationId,
        propertyId,
        applicationId,
        payload,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

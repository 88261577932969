import { useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import {
  Modal,
  ModalConfirm,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { formatDateDisplayLocal } from '@fortress-technology-solutions/fortress-component-library/utils';
import { DASHES } from '@fortress-technology-solutions/fortress-component-library/constants';
import {
  FileDescriptionIcon,
  PencilIcon,
  AlertInfoIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import messages from './ManageCertificationsContent/GrossRentCertsTable/messages';
import useHasPermission from '../../hooks/useHasPermission';
import useReactHookFormProps from '../../hooks/useReactHookFormProps';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import useCancelCertificationMutation from '../../hooks/data-modifying/useCancelCertificationMutation';
import useCompleteCertificationMutation from '../../hooks/data-modifying/useCompleteCertificationMutation';
import useAsyncCorrectionCodeOptions from '../../hooks/data-fetching/useAffordableCorrectionCodes';

import { useFlags } from 'launchdarkly-react-client-sdk';

const ManageCertificationModals = ({
  closeConfirmModal,
  handleClose,
  modalState,
  modalType,
  open,
  openConfirm,
  openConfirmModal,
  refetchCertifications,
  signed59ADocumentType,
  signed35608DocumentType,
  editGrossRentCertificationMutation: {
    mutate: editGrossRentCertificationMutate,
    isLoading: editGrossRentCertificationMutationIsLoading,
  },
  uploadSignedMutation: {
    mutate: uploadSignedMutate,
    isLoading: uploadSignedMutationIsLoading,
  },
  propertyAffordablePrograms,
}) => {
  const { organizationId, id: propertyId } = useSelectedProperty();
  const {
    mutate: cancelCertificationMutationMutate,
    isLoading: cancelCertificationMutationIsLoading,
  } = useCancelCertificationMutation({
    affordableQualificationId: modalState?.affordableQualificationId,
    organizationId,
    propertyId,
    options: {
      enabled: Boolean(
        organizationId && propertyId && modalState?.affordableQualificationId,
      ),
      onSuccess: () => {
        toastr.success('Certification Cancelled');
        closeConfirmModal();
        refetchCertifications();
      },
      onError: () => {
        toastr.error('Error Cancelling Certification');
      },
    },
  });
  const correctionCodes = useAsyncCorrectionCodeOptions({
    organizationId,
    id: propertyId,
  });

  const {
    mutate: completeCertificationMutationMutate,
    isLoading: completeCertificationMutationIsLoading,
  } = useCompleteCertificationMutation({
    affordableQualificationId: modalState?.affordableQualificationId,
    organizationId,
    propertyId,
    options: {
      enabled: Boolean(
        organizationId && propertyId && modalState?.affordableQualificationId,
      ),
      onSuccess: () => {
        toastr.success('Certification Completed');
        handleClose();
        refetchCertifications();
      },
      onError: () => {
        toastr.error('Error Completing Certification');
      },
    },
  });
  const hasEditAnticipatedVoucherPermission = useHasPermission(
    'edit-anticipated-voucher-date',
  );
  const { hudSignatureDate: hudSignatureDateFlag } = useFlags();
  const ReactHookFormProps = useReactHookFormProps({});
  const { reset, getValues, isValid, isDirty } = ReactHookFormProps;

  const documentTypeId = signed59ADocumentType
    ? signed59ADocumentType?.id
    : signed35608DocumentType?.id;

  useEffect(() => {
    const {
      anticipatedVoucherDate,
      anticipatedVoucherOverride,
      correction,
      prevMissingCert,
    } = modalState?.grossRentCert || {};
    const defaultValues = {
      uploadDocument: {
        documentTypeId,
      },
      editCert: {
        anticipatedVoucherDate: anticipatedVoucherDate
          ? moment(anticipatedVoucherDate)
          : null,
        anticipatedVoucherOverride: anticipatedVoucherOverride,
        masterCorrectionCodeId: correction?.masterCorrectionCodeId,
        eivIndicator: correction?.eivIndicator ? 'yes' : 'no',
        priorCertificationDate: prevMissingCert?.priorCertificationDate
          ? moment(prevMissingCert?.priorCertificationDate)
          : null,
        prevHUDGrossRentLimit: prevMissingCert?.prevHUDGrossRentLimit,
        adjustedAnnualIncome: prevMissingCert?.adjustedAnnualIncome,
        ttp: prevMissingCert?.ttp,
        qualifyForRentOverride: prevMissingCert?.qualifyForRentOverride
          ? 'yes'
          : 'no',
        TTPbeforeOverride: prevMissingCert?.TTPbeforeOverride,
      },
      completeCert: {
        ownerSignatureDate: null,
      },
    }[modalType];

    reset(defaultValues);
  }, [reset, modalType, modalState?.grossRentCert, documentTypeId]);

  const { confirmModalProps, modalProps, formProps } = useMemo(() => {
    const props = {
      modalProps: {},
      formProps: {},
      confirmModalProps: {},
    };

    const {
      isCorrection,
      hasPriorHUDCerts,
      unit,
      headOfHousehold,
      grEffectiveDate,
    } = modalState?.grossRentCert || {};

    const isSigned59a = signed59ADocumentType?.id;

    const optionsDocumentType = isSigned59a
      ? [
          {
            value: signed59ADocumentType?.id,
            text: signed59ADocumentType?.name,
          },
        ]
      : [
          {
            value: signed35608DocumentType?.id,
            text: signed35608DocumentType?.name,
          },
        ];

    const title = isSigned59a ? 'Upload Signed 59a' : 'Upload Signed 3560-8';
    switch (modalType) {
      case 'uploadDocument':
        props.modalProps = {
          title,
          icon: <FileDescriptionIcon />,
          actionsProps: [
            {
              children: 'Cancel',
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: 'Save',
              submitButton: true,
              isSubmitting: uploadSignedMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const { file, notes, documentTypeId } = getValues();
                // save values as FormData
                const document = new FormData();
                document.append('file', file);
                document.append('notes', notes);
                document.append('documentTypeId', documentTypeId);

                uploadSignedMutate({
                  affordableQualificationId:
                    modalState.affordableQualificationId,
                  document,
                });
              },
            },
          ],
          ContentProps: {
            sx: {
              maxWidth: 550,
            },
          },
        };
        props.confirmModalProps = {
          onClose: closeConfirmModal,
          open: openConfirm,
          title: 'Discard Changes?',
          actionsProps: [
            {
              children: 'Cancel',
              onClick: closeConfirmModal,
            },
            {
              children: 'Discard Changes',
              onClick: () => {
                handleClose();
                closeConfirmModal();
              },
            },
          ],
        };

        props.formProps = {
          fields: [
            {
              variant: 'fileUploader',
              name: 'file',
              requiredFileType: 'application/pdf',
              sx: { margin: 'auto' },
              rules: { required: true },
              GridProps: {
                xs: 12,
              },
            },
            {
              name: 'note',
              variant: 'custom',
              component: (
                <Typography variant={'caption'}>
                  Note: The document type must be a PDF
                </Typography>
              ),
              GridProps: { xs: 12 },
            },
            {
              variant: 'singleSelect',
              name: 'documentTypeId',
              label: 'Document Type',
              options: optionsDocumentType,
              rules: { required: true },
              GridProps: { xs: 12 },
            },
            {
              variant: 'text',
              name: 'notes',
              label: 'Document Notes (Optional)',
              multiline: true,
              rows: 4,
              GridProps: { xs: 12 },
            },
          ],
        };
        break;
      case 'editCert':
        const effectiveDate = grEffectiveDate
          ? formatDateDisplayLocal(grEffectiveDate)
          : DASHES;

        props.modalProps = {
          title: <FormattedMessage {...messages.editGRModalTitle} />,
          icon: <PencilIcon />,
          actionsProps: [
            {
              children: 'Cancel',
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: 'Save',
              submitButton: true,
              isSubmitting: editGrossRentCertificationMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const payload = {
                  affordableQualificationId:
                    modalState.affordableQualificationId,
                  grossRentEdits: {
                    anticipatedVoucherDate: values.anticipatedVoucherDate,
                    anticipatedVoucherOverride:
                      values.anticipatedVoucherOverride,
                  },
                  ...(isCorrection === 'yes' && {
                    correction: {
                      masterCorrectionCodeId: values.masterCorrectionCodeId,
                      eivIndicator: values.eivIndicator,
                    },
                  }),
                  ...(!hasPriorHUDCerts && {
                    prevMissingCert: {
                      priorCertificationDate: values.priorCertificationDate,
                      prevHUDGrossRentLimit: values.prevHUDGrossRentLimit,
                      adjustedAnnualIncome: values.adjustedAnnualIncome,
                      ttp: values.ttp,
                      qualifyForRentOverride:
                        values.qualifyForRentOverride === 'yes',
                      TTPbeforeOverride: values.TTPbeforeOverride,
                    },
                  }),
                };

                editGrossRentCertificationMutate(payload);
              },
            },
          ],
        };
        props.formProps = {
          fields: [
            {
              name: 'customElement',
              variant: 'custom',
              component: (
                <>
                  <Typography variant={'h2'}>
                    Unit {unit} - {headOfHousehold}
                  </Typography>
                  <Typography variant={'body1'}>
                    Effective Date: {effectiveDate}
                  </Typography>
                </>
              ),
              GridProps: { xs: 12 },
            },
            {
              variant: 'date',
              name: 'anticipatedVoucherDate',
              label: 'Anticipated Voucher Date',
              rules: {
                required: true,
              },
              disabled: ({ getValues }) =>
                getValues('anticipatedVoucherOverride') !== true,
              GridProps: {
                xs: 6,
              },
            },
            {
              variant: 'switch',
              name: 'anticipatedVoucherOverride',
              label: 'Override',
              disabled: !hasEditAnticipatedVoucherPermission,
              GridProps: {
                alignItems: 'flex-end',
                display: 'flex',
                xs: 6,
              },
            },

            ...(isCorrection === 'yes'
              ? [
                  {
                    variant: 'singleSelect',
                    name: 'masterCorrectionCodeId',
                    label: 'Correction Type',
                    GridProps: {
                      xs: 6,
                    },
                    options: correctionCodes,
                  },
                  {
                    variant: 'radioGroup',
                    name: 'eivIndicator',
                    label: 'EIV Indicator',
                    options: [
                      { value: 'yes', label: 'Yes' },
                      { value: 'no', label: 'No' },
                    ],
                    GridProps: {
                      xs: 6,
                    },
                  },
                ]
              : []),
            ...(!hasPriorHUDCerts
              ? [
                  {
                    variant: 'formLabel',
                    name: 'priorCertificationInfo',
                    children: 'Prior Certification Information',
                    GridProps: {
                      xs: 12,
                    },
                  },
                  {
                    variant: 'date',
                    name: 'priorCertificationDate',
                    label: 'Certification Date',
                    rules: { required: true },
                    GridProps: {
                      xs: 6,
                    },
                  },
                  {
                    variant: 'number',
                    name: 'prevHUDGrossRentLimit',
                    label: 'Prior HUD Gross Rent',
                    adornment: '$',
                    rules: {
                      required: true,
                      min: 0,
                    },
                    GridProps: {
                      xs: 6,
                    },
                  },
                  {
                    variant: 'number',
                    name: 'adjustedAnnualIncome',
                    label: 'Adjusted Annual Income',
                    adornment: '$',
                    rules: {
                      required: true,
                      min: -1,
                    },
                    GridProps: {
                      xs: 6,
                    },
                  },
                  {
                    variant: 'number',
                    name: 'ttp',
                    label: 'Total Tenant Payment',
                    adornment: '$',
                    rules: {
                      required: true,
                      min: 0,
                    },
                    GridProps: {
                      xs: 6,
                    },
                  },
                  {
                    variant: 'radioGroup',
                    name: 'qualifyForRentOverride',
                    label: 'Unit qualifies for rent override',
                    options: [
                      { value: 'yes', label: 'Yes' },
                      { value: 'no', label: 'No' },
                    ],
                    sx: {
                      flexDirection: 'row',
                    },
                    rules: {
                      required: true,
                    },
                    GridProps: {
                      xs: 6,
                    },
                  },
                  {
                    variant: 'number',
                    name: 'TTPbeforeOverride',
                    label: 'TTP before Override',
                    adornment: '$',
                    options: [
                      { value: 'yes', text: 'Yes' },
                      { value: 'no', text: 'No' },
                    ],
                    disabled: ({ getValues }) =>
                      getValues('qualifyForRentOverride') !== 'yes',
                    rules: {
                      required: ({ getValues }) =>
                        getValues('qualifyForRentOverride') === 'yes',
                    },
                    GridProps: {
                      xs: 6,
                    },
                  },
                ]
              : []),
          ],
        };
        props.confirmModalProps = {
          title: 'Discard Changes?',
          onClose: closeConfirmModal,
          open: openConfirm,
          actionsProps: [
            {
              children: 'Cancel',
              onClick: closeConfirmModal,
            },
            {
              children: 'Discard Changes',
              onClick: () => {
                handleClose();
                closeConfirmModal();
              },
            },
          ],
        };

        break;
      case 'cancelCert':
        props.confirmModalProps = {
          title: 'Cancel Certification?',
          children: (
            <>
              <Typography>
                This will cancel the Gross Rent Certification for:
              </Typography>
              <Typography variant={'body2'}>
                Unit {unit} - {headOfHousehold}
              </Typography>
            </>
          ),
          actionsProps: [
            {
              children: 'Cancel',
              onClick: closeConfirmModal,
            },
            {
              children: 'Yes',
              submitButton: true,
              isSubmitting: cancelCertificationMutationIsLoading,
              onClick: () => {
                cancelCertificationMutationMutate();
              },
            },
          ],
        };
        break;
      case 'completeCert':
        const isHudSignatureDateFlagActiveAndPropertyHasHud =
          hudSignatureDateFlag && propertyAffordablePrograms.includes('HUD');
        props.modalProps = {
          title: 'Complete Certification?',
          icon: <AlertInfoIcon />,
          ContentProps: {
            sx: {
              maxWidth: '400px',
            },
          },
          actionsProps: [
            {
              children: 'Cancel',
              onClick: handleClose,
            },
            {
              children: 'Confirm',
              submitButton: true,
              isSubmitting: completeCertificationMutationIsLoading,
              disabled: isHudSignatureDateFlagActiveAndPropertyHasHud
                ? !isValid || !isDirty
                : false,
              onClick: () => {
                const values = getValues();
                const payload = {
                  ownerSignatureDate: values.ownerSignatureDate,
                };

                completeCertificationMutationMutate(payload);
              },
            },
          ],
        };

        props.formProps = {
          fields: [
            {
              name: 'customElement',
              variant: 'custom',
              component: (
                <>
                  <Typography variant="bodyMedium">
                    This will complete the Gross Rent Certification for: &nbsp;
                  </Typography>
                  <Typography variant={'labelLarge'}>
                    Unit {unit} - {headOfHousehold}
                  </Typography>
                </>
              ),
              GridProps: { xs: 12 },
            },
            ...(isHudSignatureDateFlagActiveAndPropertyHasHud
              ? [
                  {
                    variant: 'date',
                    name: 'ownerSignatureDate',
                    label: 'Owner Signature Date',
                    rules: {
                      required: true,
                    },
                    GridProps: { xs: 12 },
                  },
                ]
              : []),
          ],
        };
        break;

      default:
        break;
    }

    return props;
  }, [
    cancelCertificationMutationIsLoading,
    cancelCertificationMutationMutate,
    closeConfirmModal,
    completeCertificationMutationIsLoading,
    completeCertificationMutationMutate,
    correctionCodes,
    editGrossRentCertificationMutate,
    editGrossRentCertificationMutationIsLoading,
    getValues,
    handleClose,
    hasEditAnticipatedVoucherPermission,
    isDirty,
    isValid,
    modalState.affordableQualificationId,
    modalState?.grossRentCert,
    modalType,
    openConfirm,
    openConfirmModal,
    signed59ADocumentType?.id,
    signed59ADocumentType?.name,
    signed35608DocumentType,
    uploadSignedMutate,
    uploadSignedMutationIsLoading,
    hudSignatureDateFlag,
    propertyAffordablePrograms,
  ]);

  return (
    <>
      <Modal open={open} onClose={handleClose} {...modalProps}>
        <Form ReactHookFormProps={ReactHookFormProps} {...formProps} />
      </Modal>
      <ModalConfirm open={openConfirm} {...confirmModalProps} />
    </>
  );
};

export default ManageCertificationModals;

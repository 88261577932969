import { useCallback } from 'react';
import { exportAndDownloadCSV } from '../../utils/csv-helpers';
import moment from 'moment';

const useAffordableWaitlistCSVExport = ({
  filteredHeaders,
  rows,
  hasAnyFilters,
}: {
  filteredHeaders: {
    id: string,
    label: string | { props: { defaultMessage: string } },
  }[],
  rows: { [headerId: string]: { variant: string, value: string | number } },
  hasAnyFilters: boolean,
}) => {
  const onCSVButtonClick = useCallback(() => {
    const fileName = `${moment().year()}-Affordable-Waitlist.csv`;

    exportAndDownloadCSV({
      excludedHeaders: ['actions'],
      filteredHeaders,
      fileName,
      rows,
      hasAnyFilters,
    });
  }, [filteredHeaders, rows, hasAnyFilters]);

  return { onCSVButtonClick };
};

export default useAffordableWaitlistCSVExport;

import { datadogRum } from '@datadog/browser-rum';

/**
 * Initialize the Datadog RUM agent
 * @returns {void}
 */
export function initializeDatadog() {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DEPLOYMENT_ENV,
    version: process.env.REACT_APP_VERSION || '1.0.0',
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingOrigins: [process.env.REACT_APP_API_URL],
    enableExperimentalFeatures: ['feature_flags'],
  });
}

/**
 * Start a Datadog RUM session
 */
export function startDatadogSession() {
  datadogRum.startSessionReplayRecording();
}

/**
 * Set a user's metadata in Datadog
 * @param {Object} user - The user object
 * @param {string} user.id - The user's ID
 * @param {string} user.firstName - The user's first name
 * @param {string} user.lastName - The user's last name
 * @param {string} user.emailAddress - The user's email address
 * @param {string} user.username - The user's username
 * @param {string} user.organizationId - The user's organization ID
 * @param {string} user.userRole.description - The user's role
 * @param {string} user.userStatus.name - The user's status
 * @param {string} user.userTitle.name - The user's title
 * @param {string} user.lastSystemAccess - The user's last system access
 * @param {string} user.numberFailedLoginAttempts - The user's number of failed login attempts
 * @returns {void}
 * @see https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#user-session
 */
export function setDatadogUser(user) {
  datadogRum.setUser({
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
    email: user.emailAddress,
    username: user.username,
    organizationId: user.organizationId,
    role: user.userRole.description,
    status: user.userStatus.name,
    title: user.userTitle.name,
    lastSystemAccess: user.lastSystemAccess,
    numberFailedLoginAttempts: user.numberFailedLoginAttempts,
  });
}

/**
 * Stop a Datadog RUM session
 */
export function endDatadogSession() {
  datadogRum.clearUser();
}

/**
 * Add a feature flag evaluation to Datadog
 * @param key
 * @param value
 */
export function addFeatureFlagEvaluation(key: string, value: string) {
  datadogRum.addFeatureFlagEvaluation(key, value);
}

import { get, put, post } from '../utils/api';
import { path, isEmpty } from 'ramda';

export default class CertificationsService {
  getRecerts(
    organizationId: string,
    propertyId: string,
    options: Object,
    search: ?Object,
    filters: ?Object,
    displayAll = false,
  ) {
    const searchText =
      search && path(['searchText'], search)
        ? `searchText=${search.searchText}`
        : '';
    const filter = !isEmpty(filters) ? `filter=${JSON.stringify(filters)}` : '';
    const displayAllRecerts = `displayAll=${displayAll}`;
    const filterList = displayAll
      ? [searchText, displayAllRecerts]
      : [searchText, filter, displayAllRecerts];
    const queryString = filterList.filter((param) => param).join('&');

    return get(
      `/${organizationId}/${propertyId}/all-recerts?${queryString}`,
      options,
    );
  }

  getGrossRentCerts(
    organizationId: string,
    propertyId: string,
    options: Object,
    search: ?Object,
    filters: ?Object,
  ) {
    const searchText =
      search && path(['searchText'], search)
        ? `searchText=${search.searchText}`
        : '';
    const filter = !isEmpty(filters) ? `filter=${JSON.stringify(filters)}` : '';
    const queryString = [searchText, filter].filter((param) => param).join('&');

    return get(
      `/${organizationId}/${propertyId}/all-gross-rent-certs?${queryString}`,
      options,
    );
  }

  editGrossRentCert(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    payload: Object,
    options: Object,
  ) {
    return put(
      `/${organizationId}/${propertyId}/affordable-qualifications/${affordableQualificationId}/edit-gross-rent`,
      JSON.stringify(payload),
      options,
    );
  }

  createGrossRentCertification(
    organizationId: string,
    propertyId: string,
    householdId: string,
    programName: string,
    options: Object,
  ) {
    return post(
      `/${organizationId}/${propertyId}/affordable-qualifications/create-gross-rent-certification`,
      JSON.stringify({
        householdId,
        programName,
      }),
      options,
    );
  }

  getActiveCertifications(
    organizationId: string,
    propertyId: string,
    certificationType: string,
    options: Object,
    search: ?Object,
    filters: ?Object,
  ) {
    const searchText =
      search && path(['searchText'], search)
        ? `searchText=${search.searchText}`
        : '';
    const filter = !isEmpty(filters) ? `filter=${JSON.stringify(filters)}` : '';
    const queryString = [searchText, filter].filter((param) => param).join('&');

    return get(
      `/${organizationId}/${propertyId}/active-certifications?certificationType=${certificationType}${
        isEmpty(queryString) ? '' : `&${queryString}`
      }`,
      options,
    );
  }
}

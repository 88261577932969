import React from 'react';
import { FieldArray } from 'redux-form';
import { YesNoColumns } from '../../SharedFormFields';
import { NumberAndAssetColumn } from '..';
import { SuitableForOccupancyAssetFieldArrayRows } from './SuitableForOccupancyAssetFieldArrayRows';

export const ExpandableSuitableOccupancyAssetRows = ({
  asset,
  fields,
  isPrior,
  handleDelete,
  handleAdd,
  noClicked,
  calculateCashValue,
  calculateAnnualIncome,
  fieldsAnnualIncome,
  intl,
}: Object) => {
  // assetName is top level asset name used to check if yes or no
  const disabled = fields[asset.name] !== 'yes';
  const showAddAccountBtn = !disabled && asset?.expandable;
  const { name } = asset;
  return (
    <React.Fragment key={asset.name}>
      <div className="row container-fluid">
        <div className="row">
          <div className="col-xs-5">
            <div className="row">
              <NumberAndAssetColumn
                row={asset}
                isPrior={isPrior}
                colSize="6"
                disabled={disabled}
              />
              <YesNoColumns
                name={name}
                noClicked={() => noClicked(asset.name, true)}
                isPrior={isPrior}
                colSize="3"
              />
              <div className="col-xs-3" />
            </div>
          </div>
          <div className="col-xs-7" />
        </div>
        {!disabled && (
          <div className="row faux-table__row">
            <FieldArray
              name={`${asset.name}List`}
              component={SuitableForOccupancyAssetFieldArrayRows}
              asset={asset}
              isPrior={isPrior}
              assetName={asset.name}
              handleDelete={handleDelete}
              handleAdd={handleAdd}
              noClicked={noClicked}
              formFields={fields}
              showAddAccountBtn={showAddAccountBtn}
              calculateCashValue={calculateCashValue}
              calculateAnnualIncome={calculateAnnualIncome}
              fieldsAnnualIncome={fieldsAnnualIncome}
              intl={intl}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

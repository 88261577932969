import { defineMessages } from 'react-intl';

/**
 * Will have the messages displayed in the ManageAffordableWaitlist component
 */
const modalMessages = defineMessages({
  // Add to Waitlist Modal
  addToWaitlistTitle: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.Title',
    defaultMessage: 'Add Household to Waitlist',
  },
  completeTheFollowing: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.CompleteTheFollowing',
    defaultMessage: 'Complete the following:',
  },
  affordableProgram: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.AffordableProgram',
    defaultMessage: 'Affordable Program',
  },
  anticipatedAnnualIncome: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.AnticipatedAnnualIncome',
    defaultMessage: 'Anticipated Annual Income',
  },
  requestingAccomodation: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.RequestingAccommodation',
    defaultMessage: 'Requesting Accommodation',
  },
  preferences: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.Preferences',
    defaultMessage: 'Preferences',
  },
  // Configure Preferences Modal
  veteran: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Veteran',
    defaultMessage:
      'Being a veteran or the unmarried spouse of a service person killed while in active service',
  },
  adultTransition: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.AdultTransition',
    defaultMessage: 'Being a young adult transitioning out of foster care',
  },
  involuntarily: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Involuntarily',
    defaultMessage: 'Being involuntarily displaced',
  },
  disability: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Disability',
    defaultMessage: 'Disability',
  },
  elderly: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Elderly',
    defaultMessage: 'Elderly',
  },
  homeless: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Homeless',
    defaultMessage: 'Homeless or living in a substandard housing',
  },
  paymore50: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Paymore50',
    defaultMessage: 'Paying more than 50% of your income for rent',
  },
  residency: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Residency',
    defaultMessage: 'Residency',
  },
  victims: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Victims',
    defaultMessage: 'Victims of domestic violence',
  },
  workingFamilies: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.WorkingFamilies',
    defaultMessage: 'Working families',
  },
  // Deny Modal
});

export default modalMessages;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import DocumentTitle from 'react-document-title';

// Components
import ManageAffordableWaitlistModals from './ManageAffordableWaitlistModals';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import {
  Banner,
  BadgeStatus,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import ManageAffordableWaitlistButtons from './ManageAffordableWaitlistButtons';
import TableLayout from '../../components/TableLayout';

// Hooks
import useSelectedProperty from '../../hooks/useSelectedProperty';
import useManageAffordableWaitlist from './hooks';
import useBaseTableProps from '../../hooks/useBaseTableProps';

// Others
import { AffordableWaitlist } from './types';
import messages from './messages';
import { useAsyncPropertyDetails } from '../ViewProperty/hooks';
import useIntl from '../../hooks/useIntl';
/**
 * The central component for the Manage Affordable Waitlist page.
 */
const ManageAffordableWaitlist = () => {
  const { id: propertyId, organizationId } = useSelectedProperty();
  const baseTableProps = useBaseTableProps();
  const intl = useIntl();

  const [propertyDetails] = useAsyncPropertyDetails(
    organizationId,
    propertyId,
    intl,
  );

  const affordableWaitlist =
    propertyDetails?.setup?.affordableWaitlist ?? false;

  const affordableWaitlistStatus =
    propertyDetails?.setup?.affordableWaitlistStatus;

  const {
    modalProps,
    // Handle Sort/Filter/Search
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    // Columns
    handleColumnChange,
    allColumnsHidden,
    columnOptions,
    selectedColumns,
    // Indicators
    isLoading,
    count,
    totalCount,
    name,
    order,
    orderBy,
    // Data
    filteredHeaders,
    refetchAffordableWaitlists,
    rows,
    onCSVButtonClick,
    refetchAffordableWaitlistPropertyPreferences,
    defaultValuesConfigurePreferences,
  } = useManageAffordableWaitlist({
    organizationId,
    propertyId,
  });

  const affordableWaitlistItems: Array<AffordableWaitlist> = rows;

  const disableButtons = isLoading || !affordableWaitlist;

  const isAffordableWaitlistStatusOpen = affordableWaitlistStatus === 'OPEN';

  return (
    <DocumentTitle
      data-testid={'manage-affordable-waitlist'}
      title={intl.formatMessage(messages.title)}
    >
      <>
        <TableLayout name={name} childrenElementsHeight={113 + 158}>
          {(tableHeight) => (
            <>
              {!isAffordableWaitlistStatusOpen && !isLoading && (
                <Banner
                  withClose
                  icon={<AlertInfoIcon />}
                  text={intl.formatMessage(messages.closedWarning)}
                  color="lightGrey"
                  hasBorder={false}
                  BoxProps={{
                    sx: {
                      marginBottom: '16px',
                    },
                  }}
                />
              )}
              <TableHeader
                title={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <FormattedMessage {...messages.title} />
                    {affordableWaitlist && !isLoading && (
                      <>
                        {affordableWaitlistStatus === 'OPEN' && (
                          <BadgeStatus
                            variant="filled"
                            size="large"
                            status="open"
                            marginLeft="8px"
                          >
                            {intl.formatMessage(messages.open)}
                          </BadgeStatus>
                        )}
                        {affordableWaitlistStatus === 'CLOSED' && (
                          <BadgeStatus
                            variant="filled"
                            size="large"
                            status="closed"
                            marginLeft="8px"
                          >
                            {intl.formatMessage(messages.closed)}
                          </BadgeStatus>
                        )}
                      </>
                    )}
                  </span>
                }
                count={count}
                totalCount={totalCount}
                actions={
                  <ManageAffordableWaitlistButtons
                    organizationId={organizationId}
                    propertyId={propertyId}
                    disabled={disableButtons}
                    openModalConfigurePreferences={(e) => {
                      modalProps.handleOpen({
                        configurePreferences: {
                          ...defaultValuesConfigurePreferences,
                        },
                        modalType: 'configurePreferences',
                      });
                    }}
                  />
                }
              />
              <Table
                {...baseTableProps}
                // Handle Sort/Filter/Search
                handleSortChange={handleSortChange}
                handleFilterChange={handleFilterChange}
                handleFilterTypeChange={handleFilterTypeChange}
                handleSearchSubmit={handleSearchSubmit}
                handleDateSubmit={handleDateSubmit}
                filterState={filterState}
                filterTypeState={filterTypeState}
                searchState={searchState}
                dateState={dateState}
                // Columns
                handleColumnChange={handleColumnChange}
                allColumnsHidden={allColumnsHidden}
                columnOptions={columnOptions}
                selectedColumns={selectedColumns}
                // Indicators
                isLoading={isLoading}
                count={count}
                totalCount={totalCount}
                name={name}
                order={order}
                orderBy={orderBy}
                // Data
                headers={filteredHeaders}
                rows={affordableWaitlistItems}
                onCSVButtonClick={count > 0 ? onCSVButtonClick : undefined}
                // Others
                height={tableHeight}
              />
              <ManageAffordableWaitlistModals
                organizationId={organizationId}
                propertyId={propertyId}
                refetchAffordableWaitlists={refetchAffordableWaitlists}
                refetchAffordableWaitlistPropertyPreferences={
                  refetchAffordableWaitlistPropertyPreferences
                }
                {...modalProps}
              />
            </>
          )}
        </TableLayout>
      </>
    </DocumentTitle>
  );
};

export default ManageAffordableWaitlist;

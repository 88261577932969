import moment from 'moment';
import isNil from 'lodash/isNil';

import { FORM_INFO } from './constants';
import type { PassbookRate } from '../../ViewProperty/AffordableSetup/Programs/shared';
// This is added currently for Washington State requirements for their asset forms
export const hasInterestRateField = (property) => {
  const physicalState = property?.physicalState ?? '';
  const isWashingtonState = physicalState === 'US-WA';
  return isWashingtonState;
};

export const getAssetDefinitions = (isHotmaActive) => {
  let allAssetDefinitions = [...FORM_INFO.initialAssets];
  if (!isHotmaActive) {
    allAssetDefinitions = [
      ...allAssetDefinitions,
      ...FORM_INFO.retirementAccounts,
    ];
  }
  allAssetDefinitions = [
    ...allAssetDefinitions,
    ...FORM_INFO.lifeInsurance,
    ...FORM_INFO.finalAssets,
  ];
  return allAssetDefinitions;
};

export const getPassbookRateByProgramAndDate = (
  data: Array<PassbookRate>,
  programName: string,
  effectiveDate: string,
): number => {
  return (
    data?.find((rate: PassbookRate) => {
      const mRateStartDate = moment(rate.startDate);
      const mRateEndDate = isNil(rate.endDate)
        ? moment().add('100', 'years')
        : moment(rate.endDate);
      const mEffectiveDate = moment(effectiveDate);
      return (
        rate.programName === programName &&
        mEffectiveDate.isBetween(mRateStartDate, mRateEndDate, undefined, '[]')
      );
    })?.amount ?? 0
  );
};

import { useMemo } from 'react';
import { useQuery, QueryOptions } from 'react-query';
import useAbortController from '../useAbortController';
import {
  INITIAL_CERT_TYPE,
  MOVE_IN_CERT_TYPE,
  RECERT_CERT_TYPE,
  OTHER,
  OTHER_HUD,
  OTHER_LIHTC,
  GROSS_RENT_CERT_TYPE,
  INTERIM_CERT_TYPE,
  INITIAL_UT_CERT_TYPE,
  RD_GROSS_RENT_CERT_TYPE,
} from '../../constants/affordableCertificationTypes';
import AffordableCertificationsService from '../../services/affordableCertificationsService';
import { flatten } from 'ramda';

const useCertificationsQuery = ({
  organizationId,
  propertyId,
  certificationType,
  options = {},
}: {
  organizationId: string,
  propertyId: string,
  options?: QueryOptions,
}) => {
  const affordableRecertificationsService =
    new AffordableCertificationsService();
  const abortControllerOptions = useAbortController();
  const queryKey = [
    'useCertificationsQuery',
    organizationId,
    propertyId,
    certificationType,
  ];

  const { data, isLoading, refetch } = useQuery(
    queryKey,
    async () => {
      let response = {
        results: [],
        summary: { recerts: [], certifications: [] },
        signed59DocumentType: {},
      };
      switch (true) {
        case RECERT_CERT_TYPE === certificationType:
          const { results: recertResults, statistics: recertStatistics } =
            await affordableRecertificationsService.getRecerts(
              organizationId,
              propertyId,
              abortControllerOptions,
              '',
              {},
              true,
            );
          const mappedRecerts = recertResults.map((recert) => {
            return {
              ...recert,
              id: recert?.currentCertificationId ?? '',
            };
          });

          response = {
            ...response,
            results: flatten(mappedRecerts),
            summary: { recerts: recertStatistics },
          };

          break;

        case GROSS_RENT_CERT_TYPE === certificationType:
          const {
            data: { grossRentCerts, signed59ADocumentType },
          } = await affordableRecertificationsService.getGrossRentCerts(
            organizationId,
            propertyId,
            options,
            '',
            {},
          );

          response = {
            ...response,
            results: grossRentCerts,
            signed59ADocumentType,
          };
          break;

        case RD_GROSS_RENT_CERT_TYPE === certificationType:
          const { data } =
            await affordableRecertificationsService.getGrossRentCerts(
              organizationId,
              propertyId,
              options,
              '',
              {
                programName: 'RD',
              },
            );
          response = {
            ...response,
            results: data?.grossRentCerts,
            signed35608DocumentType: data?.signed35608DocumentType,
          };
          break;

        case [
          MOVE_IN_CERT_TYPE,
          INITIAL_CERT_TYPE,
          INITIAL_UT_CERT_TYPE,
          INTERIM_CERT_TYPE,
          OTHER,
          OTHER_HUD,
          OTHER_LIHTC,
        ].includes(certificationType):
          const filters = {
            [OTHER_HUD]: {
              ApplicantSection: {
                priorResident: true,
                resident: true,
              },
            },
            [OTHER]: {
              ApplicantSection: {
                priorResident: true,
                resident: true,
              },
            },
          }[certificationType];

          const certTypes =
            certificationType === INITIAL_CERT_TYPE
              ? [INITIAL_CERT_TYPE, INITIAL_UT_CERT_TYPE]
              : certificationType;
          const {
            result: activeCertResults,
            summary: certificationsSummary = {},
          } = await affordableRecertificationsService.getActiveCertifications(
            organizationId,
            propertyId,
            certTypes,
            options,
            '',
            filters ?? {},
          );
          response = {
            results: activeCertResults,
            summary: { certifications: certificationsSummary },
          };

          break;

        default:
          break;
      }

      return response;
    },
    { refetchOnWindowFocus: false, ...options },
  );

  const memoizedData = useMemo(
    () => data ?? { results: [], statistics: [] },
    [data],
  );

  return {
    data: memoizedData,
    isLoading,
    refetch,
  };
};

export default useCertificationsQuery;

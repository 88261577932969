import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';

// Components
import { FormGroup, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import TheWorkNumber from '../../../components/TheWorkNumber';
import WaitlistInfoSection from '../../../components/WaitlistInfoSection';
import ApplicantChecklistToggle from './ApplicantChecklistToggle';
import { ApplicationChecklistCompletionStatus } from './ApplicationChecklistCompletionStatus';
import ApplicationNotes from './ApplicationNotes';
import ApplicationReceived from './ApplicationReceived';
import CreditCriminalStatus from './CreditCriminalStatus';
import ManageAffordableWaitlistModals from '../../ManageAffordableWaitlist/ManageAffordableWaitlistModals';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { reduxForm } from 'redux-form';

// Hooks
import { useOneProperty } from '../../../hooks/data-fetching/useOneProperty';
import useWaitlistService from '../../../components/WaitlistInfoSection/hooks';
import { useApplicationStatusOptions } from '../hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useModalProps from '../../../hooks/useModalProps';
import useSelectedProperty from '../../../hooks/useSelectedProperty';
import useApplicationStatusSingleSelect from './useApplicationStatusSingleSelect';

// Types
import type { Applicant, Application } from '../../CreateApplication/types';
import type { FeatureFlags } from '../../../types';
import type { DropdownOption, User } from '../../App/types';
import type { DecisionStatus } from '../types';
import type { AddToWaitlistParams } from '../../ManageAffordableWaitlist/types';

// Utilss
import { filter, pathOr } from 'ramda';
import {
  showWaitlistUpdateFail,
  showWaitlistUpdateSuccess,
} from '../../../components/WaitlistInfoSection/utils';
import { getSentAndExecutedStatus } from '../../../utils/lease-helpers';
import { formatNBedsArr } from '../../../utils/prospectPreferences-helpers';
import { formatPhoneNumber } from '@fortress-technology-solutions/fortress-component-library/utils';

// Constants
import messages from './messages';

const RowWarning = styled(Row)`
  .highlight-details {
    padding: 10px;
    margin: 0 20px 20px;
  }
`;

type Props = {
  intl: Object,
  propertyId: string,
  organizationId: string,
  currentRecord: any,
  applicationDecisionStatus: Array<DecisionStatus>,
  applicationStatuses: Array<DropdownOption>,
  applicationId: string,
  screening: Object,
  screeningLetterTypes: Array<Object>,
  saveScreeningResults: Function,
  generateAdverseActionLetter: Function,
  checkUserPermissions: Function,
  currentUser: User,
  handleNotesChange: Function,
  handleChecklistOptionChange: Function,
  handleEditApplicationForm: Function,
  handleStatusChange: Function,
  submitScreeningApplication: Function,
  promptToaster: Function,
  selectedProperty: Object,
  householdId: string,
  initialValues: { applicationStatusId: string },
};

type LeaseProps = {
  intl: Object,
  currentRecord: Application,
  handleStatusChange?: Function,
  handleNotesChange?: Function,
  applicationStatuses: Array<DropdownOption>,
  checkUserPermissions: Function,
  isResident?: boolean,
  leaseExecuted?: boolean,
  leaseSentToPortal?: boolean,
  applicationStatusId: string,
  modalProps: Object, // AddToWaitlistModalProps
};

type ChecklistProps = {
  intl: Object,
  currentRecord: Object,
  applicationDecisionStatus: Array<DecisionStatus>,
  handleChecklistOptionChange?: Function,
  handleEditApplicationForm: Function,
  approveApplicant?: Function,
  cancelApplicant?: Function,
  isResident?: boolean,
  isCanceled?: boolean,
  deniedReasons?: any,
  enabled?: boolean,
  leaseExecuted?: boolean,
  leaseSentToPortal?: boolean,
};

export const getWaitlistSectionProps = (currentRecord) => {
  const {
    applicationStatus: currentApplicationStatus,
    property: { organizationId },
    propertyId,
    id: applicationId,
  } = currentRecord;
  return {
    organizationId,
    propertyId,
    applicationId,
    currentApplicationStatus,
  };
};

export const statusOptionIsWaitlist = (status) =>
  /Waitlist/gi.test(status?.text ?? '');

export const getWaitlistStatusId = (applicationStatuses) =>
  applicationStatuses.find(statusOptionIsWaitlist)?.value ?? '';

export const applicationStatusChangeHandler = async (
  // applicationStatus Props
  currentStatusId,
  newStatusId,
  waitlistStatusId,
  localWaitlistInfo,
  waitlistActions,
  handleStatusChange,
  // Modal Props
  modalProps,
  addToWaitlistParams,
  // Other Props
  intl,
): Promise<Void> => {
  const updateSingleSelectValue = (applicationStatusId) => {
    addToWaitlistParams.setValue('applicationStatusId', applicationStatusId);
  };

  if (newStatusId === currentStatusId) {
    updateSingleSelectValue(currentStatusId);
    return;
  }

  const { updateWaitlist } = waitlistActions;
  const { id: applicationId } = localWaitlistInfo;

  const executeUpdates = async (currentApplicationId, newWaitlistInfo) => {
    handleStatusChange(null, newStatusId);
    const applicationIdToBeSent =
      currentApplicationId || addToWaitlistParams?.applicationIdExt;
    await updateWaitlist(applicationIdToBeSent, newWaitlistInfo);
    showWaitlistUpdateSuccess(intl);
  };

  const cancelWaitlistUpdate = () => updateSingleSelectValue(currentStatusId);

  if (currentStatusId === waitlistStatusId) {
    const newWaitlistInfo = {
      waitlistStartTime: localWaitlistInfo.waitlistStartTime,
      waitlistEndTime: new Date(),
    };
    await executeUpdates(applicationId, newWaitlistInfo);
    return;
  }
  if (newStatusId === waitlistStatusId) {
    const newWaitlistInfo = {
      waitlistStartTime: localWaitlistInfo?.waitlistStartTime ?? new Date(),
      waitlistEndTime: null,
    };
    if (addToWaitlistParams.showAddToWaitlistModal) {
      modalProps.handleOpen({
        modalType: 'addToWaitlist',
        addToWaitlistParams,
        updateCallback: executeUpdates,
        updateCallbackParams: {
          applicationId,
          newWaitlistInfo,
        },
        cancelCallback: cancelWaitlistUpdate,
      });
    } else {
      await executeUpdates(applicationId, newWaitlistInfo);
    }

    return;
  }
  updateSingleSelectValue(newStatusId);
  handleStatusChange(null, newStatusId);
};

export const LeaseApplication = ({
  intl,
  currentRecord,
  handleStatusChange,
  handleNotesChange,
  applicationStatuses,
  checkUserPermissions,
  isResident,
  leaseExecuted,
  leaseSentToPortal,
  applicationStatusId,
  headOfHouseholdId,
  applicationId: applicationIdExt,
  modalProps,
}: LeaseProps) => {
  const statusUpdateAllowed = checkUserPermissions([
    'application-status-update',
    'application-status-update-excluding-approved',
  ]);
  const hasAffordableWaitlistPermission = checkUserPermissions([
    'affordable-waitlist-add-remove-select',
  ]);
  const { pap } = useSelectedProperty();

  const propertyAffordablePrograms = pap?.map(
    (item) => item.masterAffordableProgram?.name,
  );
  const prospectUpdateAllowed = checkUserPermissions(['prospect-update']);
  const disableStatusChange =
    !statusUpdateAllowed || isResident || leaseExecuted || leaseSentToPortal;

  const { affordableWaitlist: affordableWaitlistFlag } = useFlags();

  const {
    property: { id: propertyId, organizationId },
    id: applicationId,
    applicationStatus: { id: currentStatusId },
    applicants,
    prospectInfo: {
      prospectPreferences: { nBedsArr, nBeds },
    },
  } = currentRecord;

  const affordableWaitlistPropertySetting =
    currentRecord.affordableSetup?.[0]?.affordableWaitlist;

  const disableWaitlistOption =
    (!hasAffordableWaitlistPermission || !affordableWaitlistPropertySetting) &&
    affordableWaitlistFlag;

  const waitlistStatusId = getWaitlistStatusId(applicationStatuses);

  const [localWaitlistInfo, waitlistActions, isLoading] = useWaitlistService(
    organizationId,
    propertyId,
    applicationId,
  );

  const waitlistSectionProps = {
    ...getWaitlistSectionProps(currentRecord),
    localWaitlistInfo,
    waitlistActions,
    isLoading,
  };

  const handleApplicationStatusChange = async (
    event,
    newStatusId,
    addToWaitlistParams,
  ) => {
    try {
      await applicationStatusChangeHandler(
        currentStatusId,
        newStatusId,
        waitlistStatusId,
        localWaitlistInfo,
        waitlistActions,
        handleStatusChange,
        modalProps,
        addToWaitlistParams,
        intl,
      );
    } catch (err) {
      showWaitlistUpdateFail(intl, err);
    }
  };

  const [applicationStatusOptions] = useApplicationStatusOptions(
    applicationStatuses,
    applicationStatusId,
    disableWaitlistOption,
  );

  const hohApplicant = useMemo(() => {
    return applicants.length === 1
      ? applicants[0]
      : applicants.find(
          (applicant) =>
            applicant.affordableRelationshipId === headOfHouseholdId,
        );
  }, [applicants, headOfHouseholdId]);

  const showAddToWaitlistModal =
    affordableWaitlistPropertySetting && affordableWaitlistFlag;
  const addToWaitlistParams: AddToWaitlistParams = {
    info: {
      applicant: hohApplicant?.name,
      phone: formatPhoneNumber(hohApplicant?.applicantCustomer?.phoneNumber),
      incomeLevel: 'Not Determined',
      householdSize: applicants.length,
      preferredBedroomSize: formatNBedsArr(nBedsArr, nBeds),
      accessibleUnit: 'Not Determined',
    },
    affordablePrograms: propertyAffordablePrograms,
    showAddToWaitlistModal,
    applicationIdExt,
  };

  const { ReactHookFormProps, formProps, setValue } =
    useApplicationStatusSingleSelect({
      showAddToWaitlistModal,
      handleApplicationStatusChange,
      statusUpdateAllowed,
      disableStatusChange,
      hasAffordableWaitlistPermission,
      addToWaitlistParams,
      applicationStatusOptions,
      applicationStatusId,
      currentStatusId,
    });
  addToWaitlistParams.setValue = setValue;

  return (
    <div className="container-fluid ledger-head">
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <Typography variant="h1">
            {intl.formatMessage(messages.leaseApplication)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Form ReactHookFormProps={ReactHookFormProps} {...formProps} />
        </Grid>
      </Grid>

      <FormGroup>
        <WaitlistInfoSection {...waitlistSectionProps} />
      </FormGroup>

      <ApplicationReceived currentRecord={currentRecord} />

      <ApplicationNotes
        intl={intl}
        currentRecord={currentRecord}
        handleNotesChange={handleNotesChange}
        maxLength={620}
        isResident={isResident}
        prospectUpdateAllowed={prospectUpdateAllowed}
      />
    </div>
  );
};

export const filterApplicants = (applicants: Array<Applicant>) => {
  const hasChecklist = (x) => x.checklist !== null;
  return filter(hasChecklist, applicants);
};

export const countCompleteApplications = (applicants: Array<Applicant>) => {
  const checklistIsApproved = (x) => x.checklist.approved;
  return filter(checklistIsApproved, applicants).length;
};

export const ApplicationChecklistContainer = ({
  intl,
  currentRecord,
  applicationDecisionStatus,
  handleChecklistOptionChange,
  handleEditApplicationForm,
  isResident,
  deniedReasons,
  approveApplicant,
  cancelApplicant,
  isCanceled,
  leaseExecuted,
  leaseSentToPortal,
}: ChecklistProps) => {
  const applicantsChecklist = filterApplicants(currentRecord.applicants);
  return (
    <div className="container-fluid">
      <div className="accordion accordion-applicationmanager panel-group">
        {applicantsChecklist.map((applicant) => (
          <ApplicantChecklistToggle
            key={applicant.id}
            applicant={applicant}
            intl={intl}
            applicationDecisionStatus={applicationDecisionStatus}
            handleChecklistOptionChange={handleChecklistOptionChange}
            handleEditApplicationForm={handleEditApplicationForm}
            isResident={isResident}
            deniedReasons={deniedReasons}
            approveApplicant={approveApplicant}
            cancelApplicant={cancelApplicant}
            isCanceled={isCanceled}
            leaseExecuted={leaseExecuted}
            leaseSentToPortal={leaseSentToPortal}
          />
        ))}
      </div>
    </div>
  );
};

export const LeaseApplicationTab = ({
  intl,
  currentRecord,
  applicationDecisionStatus,
  applicationStatuses,
  handleChecklistOptionChange,
  handleEditApplicationForm,
  handleNotesChange,
  handleStatusChange,
  applicationId,
  screening,
  screeningLetterTypes,
  submitScreeningApplication,
  refreshActivityTable,
  saveScreeningResults,
  generateAdverseActionLetter,
  checkUserPermissions,
  currentUser,
  promptToaster,
  selectedProperty,
  householdId,
  initialValues: { applicationStatusId },
  affordableRelationships,
}: Props) => {
  const [headOfHouseholdId, setHeadOfHouseholdId] = useState('');
  useEffect(() => {
    setHeadOfHouseholdId(
      affordableRelationships.filter(
        (relationship) => relationship?.name === 'Head of Household',
      )[0]?.id,
    );
  }, [affordableRelationships]);
  const propertyId = selectedProperty?.id ?? '';
  const organizationId = currentUser?.user?.organization?.id ?? '';
  const propertyDetails = useOneProperty(organizationId, propertyId);
  const isTransUnionActive = propertyDetails[0]?.isTransUnionActive;
  const lease = pathOr({}, ['au', 'lease'], currentRecord);
  const { leaseExecuted, leaseSentToPortal } = getSentAndExecutedStatus(lease);
  const {
    theWorkNumber,
    transunionOffLeaseAppOff,
    applicationChecklistUpdate,
  }: FeatureFlags = useFlags();

  const modalProps = useModalProps();

  const theWorkNumberProps = {
    organizationId: currentUser?.user?.organization?.id ?? '',
    propertyId: selectedProperty?.id ?? '',
    householdId: householdId ?? '',
    currentUser,
  };

  const adverseActionDeadlineDays =
    selectedProperty?.config?.adverseActionDeadlineDays;

  return (
    <div className="tab-pane active" id="1a">
      {leaseExecuted && (
        <RowWarning>
          <div className="highlight-details">
            <FormattedMessage {...messages.leaseLockedExecuted} />
          </div>
        </RowWarning>
      )}
      {leaseSentToPortal && (
        <RowWarning>
          <div className="highlight-details">
            <FormattedMessage {...messages.leaseLockedSentToPortal} />
          </div>
        </RowWarning>
      )}
      <LeaseApplication
        intl={intl}
        currentRecord={currentRecord}
        applicationDecisionStatus={applicationDecisionStatus}
        applicationStatuses={applicationStatuses}
        handleNotesChange={handleNotesChange}
        handleStatusChange={handleStatusChange}
        checkUserPermissions={checkUserPermissions}
        leaseExecuted={leaseExecuted}
        leaseSentToPortal={leaseSentToPortal}
        applicationStatusId={applicationStatusId}
        headOfHouseholdId={headOfHouseholdId}
        applicationId={applicationId}
        modalProps={modalProps}
      />
      <CreditCriminalStatus
        intl={intl}
        applicationId={applicationId}
        screening={screening}
        onResubmitCallback={submitScreeningApplication}
        refreshActivityTable={refreshActivityTable}
        saveScreeningResults={saveScreeningResults}
        generateAdverseActionLetter={generateAdverseActionLetter}
        screeningLetterTypes={screeningLetterTypes}
        currentUser={currentUser}
        isResident={false}
        promptToaster={promptToaster}
        isTransUnionActive={isTransUnionActive}
        transunionOffLeaseAppOff={transunionOffLeaseAppOff}
        adverseActionDeadlineDays={adverseActionDeadlineDays}
      />
      {theWorkNumber && <TheWorkNumber {...theWorkNumberProps} />}
      <ApplicationChecklistCompletionStatus
        isTransUnionActive={isTransUnionActive}
        applicationChecklistUpdate={applicationChecklistUpdate}
      />
      <ApplicationChecklistContainer
        {...{
          intl,
          currentRecord,
          applicationDecisionStatus,
          handleChecklistOptionChange,
          handleEditApplicationForm,
          leaseExecuted,
          leaseSentToPortal,
        }}
      />
      <ManageAffordableWaitlistModals
        organizationId={organizationId}
        propertyId={propertyId}
        applicationId={applicationId}
        {...modalProps}
      />
    </div>
  );
};

/**
 * In order to remove this reduxForm wrapper all redux-form <Fields/> must be removed from
 * src/containers/ApplicationProfile/LeaseApplicationTab.
 * @notes If the wrapper is removed in the future, consider the following for this component:
 * - double check data sent to api call `waitlistActions.updateWaitlist(applicationId,localWaitlistInfo)`
 * - double check unit tests that might fail due to reduxForm removal
 */
export default reduxForm({
  form: 'LeaseApplicationTab',
  enableReinitialize: true,
})(LeaseApplicationTab);

import { useState, useMemo, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';

// Components
import {
  Modal,
  ModalConfirm,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import {
  PlusIcon,
  SettingsIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import {
  householdPreferencesNames,
  propertyPreferencesNames,
} from './constants';
import AddToWaitlistModal from './AddToWaitlistModal';

// Hooks
import useReactHookFormProps from '../../../hooks/useReactHookFormProps';
import { useAddToWaitlist } from '../hooks.affordableWaitlistMutations';
import { useQueryClient } from 'react-query';
import {
  useCreateAffordableWaitlistPropertyPreferences,
  useUpdateAffordableWaitlistPropertyPreferences,
} from '../hooks.affordableWaitlistPropertyPreferencesMutations';

// Constants
import messages from '../messages';
import modalMessages from './modalMessages';

// Utils
import _ from 'lodash';

const confirmModalPropsDiscardChanges = ({
  closeConfirmModal,
  handleClose,
  openConfirm,
  cancelCallback,
}) => ({
  title: 'Discard Changes?',
  onClose: closeConfirmModal,
  open: openConfirm,
  actionsProps: [
    {
      children: 'Cancel',
      onClick: closeConfirmModal,
    },
    {
      children: 'Discard Changes',
      onClick: () => {
        cancelCallback?.();
        handleClose();
        closeConfirmModal();
      },
    },
  ],
});

/**
 * Central component in charge to manage the modals related to the AffordableWaitlist screen.
 * -
 * @param {*} param0.modalState - The state containing parameters passed to the modal when using handleOpen
 * @returns
 */
const ManageAffordableWaitlistModals = ({
  // Component Props
  organizationId,
  propertyId,
  applicationId,
  refetchAffordableWaitlistPropertyPreferences,
  // refetchAffordableWaitlists,
  // Modal Props
  modalState,
  modalType,
  open,
  openConfirm,
  openConfirmModal,
  handleClose,
  closeConfirmModal,
  // Other Props
  intl,
}) => {
  const queryClient = useQueryClient();
  const { updateCallback, updateCallbackParams } = modalState;

  const [addToWaitlistIncomeLevel, setAddToWaitlistIncomeLevel] =
    useState('---');

  const {
    mutate: addToWaitlistMutate,
    isLoading: addToWaitlistMutationIsLoading,
  } = useAddToWaitlist({
    organizationId,
    propertyId,
    applicationId: applicationId ?? modalState?.applicationId,
    options: {
      onSuccess: async (res) => {
        if (updateCallback) {
          const { applicationId, newWaitlistInfo } = updateCallbackParams;
          await updateCallback(applicationId, newWaitlistInfo);
        }
        queryClient.invalidateQueries(['getAllAffordableWaitlistsByProperty']);
        handleClose();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const {
    mutate: createAffordableWaitlistPropertyPrefMutation,
    isLoading: createAffordableWaitlistPropertyPrefMutationIsLoading,
  } = useCreateAffordableWaitlistPropertyPreferences({
    organizationId,
    propertyId,
    options: {
      onSuccess: (res) => {
        toastr.success('Success', res.message);
        handleClose();
        refetchAffordableWaitlistPropertyPreferences();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const {
    mutate: updateAffordableWaitlistPropertyPrefMutation,
    isLoading: updateAffordableWaitlistPropertyPrefMutationIsLoading,
  } = useUpdateAffordableWaitlistPropertyPreferences({
    organizationId,
    propertyId,
    options: {
      onSuccess: (res) => {
        toastr.success('Success', res.message);
        handleClose();
        refetchAffordableWaitlistPropertyPreferences();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const ReactHookFormProps = useReactHookFormProps({
    validationSchema: {
      checkboxes: {
        validate: (value) => {
          const selectedCheckboxes = Object.values(value).filter(
            (val) => val === true,
          );
          return (
            selectedCheckboxes.length > 0 ||
            'At least one checkbox must be selected'
          );
        },
      },
    },
  });
  const { reset, getValues, isValid, isDirty, watch } = ReactHookFormProps;
  const anticipatedAnnualIncome = watch('anticipatedAnnualIncome');

  useEffect(() => {
    const { addToWaitlistParams } = modalState;
    const initialPropertyPreferences = modalState?.configurePreferences || {};

    const preferences = Object.fromEntries(
      householdPreferencesNames.map((name) => [_.camelCase(name), false]),
    );

    const defaultValues = {
      addToWaitlist: {
        right: {
          affordableProgram:
            addToWaitlistParams?.affordablePrograms &&
            addToWaitlistParams.affordablePrograms?.length === 1
              ? [addToWaitlistParams.affordablePrograms?.[0]]
              : [],
          anticipatedAnnualIncome: '',
          requestingAccomodation: '',
          ...preferences,
        },
      },
      configurePreferences: {
        ...initialPropertyPreferences?.options,
      },
      deny: {
        someField: '',
      },
    }[modalType];

    if (modalType === 'addToWaitlist') {
      reset(defaultValues.right);
    } else {
      reset(defaultValues);
    }
  }, [reset, modalType, modalState]);

  /**
   * The callback is used
   */
  const formFields = useCallback(({ intl }) => {
    let disabled = false;
    return [
      {
        variant: 'text',
        name: 'reason',
        label: 'Reason',
        disabled,
        GridProps: {
          xs: 12,
        },
      },
      {
        variant: 'text',
        name: 'notes',
        label: 'Notes',
        GridProps: {
          xs: 12,
        },
      },
    ];
  }, []);

  const { confirmModalProps, modalProps, formProps } = useMemo(() => {
    const props = {
      modalProps: {},
      formProps: {},
      confirmModalProps: {},
    };

    switch (modalType) {
      case 'addToWaitlist':
        props.modalProps = {
          title: <FormattedMessage {...modalMessages.addToWaitlistTitle} />,
          icon: <PlusIcon />,
          ContentProps: {
            sx: {
              maxWidth: '680px',
            },
          },
          onClose: () => {
            openConfirmModal();
          },
          actionsProps: [
            {
              children: 'Cancel',
              onClick: openConfirmModal,
            },
            {
              children: 'Add to Waitlist',
              submitButton: true,
              isSubmitting: addToWaitlistMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const payload = {
                  affordableProgram: values.affordableProgram.sort().join(', '),
                  anticipatedAnnualIncome: values.anticipatedAnnualIncome,
                  incomeLevel: addToWaitlistIncomeLevel,
                  requestingAccommodation:
                    values.requestingAccomodation === 'yes' ? true : false,
                  preferences: {
                    ...Object.fromEntries(
                      householdPreferencesNames.map((name) => [
                        _.camelCase(name),
                        values[_.camelCase(name)],
                      ]),
                    ),
                  },
                };
                addToWaitlistMutate({ payload });
              },
            },
          ],
        };
        const programs =
          modalState.addToWaitlistParams?.affordablePrograms || [];
        props.formProps = {
          fields: [
            {
              name: 'custom',
              variant: 'custom',
              component: (
                <>
                  <Typography color={'text.secondary'}>
                    <FormattedMessage {...modalMessages.completeTheFollowing} />
                  </Typography>
                </>
              ),
              GridProps: {
                xs: 12,
              },
            },
            {
              variant: 'multiSelect',
              name: 'affordableProgram',
              label: <FormattedMessage {...modalMessages.affordableProgram} />,
              placeholder: 'Select a Program',
              options: programs.map((name) => ({
                value: name, // will be name for practical purposes
                text: name,
              })),
              readOnly: programs.length === 1,
              rules: {
                required: true,
              },
              GridProps: {
                xs: 12,
              },
            },
            {
              variant: 'number',
              name: 'anticipatedAnnualIncome',
              label: (
                <FormattedMessage {...modalMessages.anticipatedAnnualIncome} />
              ),
              adornment: '$',
              rules: {
                required: true,
                min: 0.01,
                step: 0.01,
              },
              GridProps: {
                xs: 12,
              },
            },
            {
              variant: 'radioGroup',
              name: 'requestingAccomodation',
              label: (
                <FormattedMessage {...modalMessages.requestingAccomodation} />
              ),
              options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
              ],
              rules: {
                required: true,
              },
              GridProps: {
                xs: 12,
              },
            },
            {
              name: 'custom',
              variant: 'custom',
              component: (
                <>
                  <Typography variant={'label'}>
                    <FormattedMessage {...modalMessages.preferences} />,
                  </Typography>
                </>
              ),
              GridProps: {
                xs: 12,
              },
            },
            // TODO: some preferences might be disabled for some households later on
            ...householdPreferencesNames.map((name) => ({
              variant: 'checkbox',
              name: _.camelCase(name),
              label: name,
              rules: {
                required: false,
              },
              GridProps: {
                xs: 12,
              },
            })),
          ],
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
          cancelCallback: modalState.cancelCallback,
        });

        break;
      case 'configurePreferences':
        props.modalProps = {
          ModalProps: {
            PaperProps: {
              sx: {
                height: 625,
              },
            },
          },
          title: (
            <Typography variant="h3">
              <FormattedMessage {...messages.configurePreferences} />
            </Typography>
          ),
          icon: <SettingsIcon sx={{ fontSize: '28px' }} />,
          actionsProps: [
            {
              children: 'Cancel',
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: 'Save',
              submitButton: true,
              isSubmitting:
                createAffordableWaitlistPropertyPrefMutationIsLoading ||
                updateAffordableWaitlistPropertyPrefMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const result = Object.keys(values).filter((key) => values[key]);
                const payload = {
                  preferences: result,
                };

                const isEdit = modalState?.configurePreferences?.id;
                if (isEdit) {
                  updateAffordableWaitlistPropertyPrefMutation({ payload });
                } else {
                  createAffordableWaitlistPropertyPrefMutation({ payload });
                }
              },
            },
          ],
        };

        props.formProps = {
          fields: [
            ...propertyPreferencesNames.map((name) => ({
              variant: 'checkbox',
              name: _.camelCase(name),
              label: <FormattedMessage {...modalMessages[_.camelCase(name)]} />,
              disabled: false,
              GridProps: {
                xs: 12,
                sx: {
                  '& .MuiFormControlLabel-label': {
                    marginLeft: '6px',
                  },
                },
              },
            })),
          ],
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
        });
        break;
      case 'deny':
        // logic here
        break;
      default:
        break;
    }

    return props;
  }, [
    // mutations
    addToWaitlistMutate,
    addToWaitlistMutationIsLoading,
    createAffordableWaitlistPropertyPrefMutation,
    createAffordableWaitlistPropertyPrefMutationIsLoading,
    updateAffordableWaitlistPropertyPrefMutation,
    updateAffordableWaitlistPropertyPrefMutationIsLoading,
    // form
    getValues,
    isDirty,
    isValid,
    formFields,
    // modal
    handleClose,
    closeConfirmModal,
    modalType,
    modalState,
    openConfirm,
    openConfirmModal,
    // Others
    addToWaitlistIncomeLevel,
    intl,
  ]);

  return (
    <>
      {modalType === 'addToWaitlist' ? (
        <AddToWaitlistModal
          organizationId={organizationId}
          propertyId={propertyId}
          open={open}
          handleClose={handleClose}
          modalProps={modalProps}
          ReactHookFormProps={ReactHookFormProps}
          formProps={formProps}
          addToWaitlistInfo={modalState.addToWaitlistParams.info}
          anticipatedAnnualIncome={anticipatedAnnualIncome}
          setAddToWaitlistIncomeLevel={setAddToWaitlistIncomeLevel}
          currentIncomeLevel={addToWaitlistIncomeLevel}
        />
      ) : (
        <Modal open={open} onClose={handleClose} {...modalProps}>
          {modalType === 'configurePreferences' && (
            <Typography variant="h4" marginBottom={2}>
              <FormattedMessage {...messages.preferences} />
            </Typography>
          )}
          <Form ReactHookFormProps={ReactHookFormProps} {...formProps} />
        </Modal>
      )}
      <ModalConfirm open={openConfirm} {...confirmModalProps} />
    </>
  );
};

export default ManageAffordableWaitlistModals;

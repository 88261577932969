import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  YesNoColumns,
  IncomeAssetCurrencyField,
  InterestRateField,
  SelectionColumn,
} from '../../SharedFormFields';
import {
  DeleteAccountBtn,
  AddAccountLink,
  ASSET_ACTUAL_IMPUTE_OPTIONS,
} from '..';

import componentMessages from '../messages';
import generalMessages from '../../../App/messages';
const messages = { ...generalMessages, ...componentMessages };

export const SuitableForOccupancyAssetFieldArrayRows = ({
  asset,
  fields,
  isPrior,
  handleAdd,
  assetName,
  noClicked,
  formFields,
  showAddAccountBtn,
  calculateAnnualIncome,
  calculateCashValue,
  fieldsAnnualIncome,
  intl,
}) => {
  const disabled = formFields[asset.name] !== 'yes';
  if (fields.length === 0) {
    fields.push();
  }
  const marketValueType = '.marketValue';
  const interestRateType = '.interestRate';
  const feesToConvertCash = '.feesToConvertCash';
  const cashValue = '.cashValue';
  const annualIncome = '.annualIncome';
  const actualImpute = '.actualImpute';
  const addAccountDisabled = fields.length >= asset.fieldLimit;
  const isYes = formFields[asset.name] === 'yes';

  return (
    <>
      {fields.map((assetField, index) => (
        <>
          <div className="row container-fluid" key={assetField}>
            <div className="col-xs-5">
              <div className="row">
                <div className="col-xs-6">
                  <div className="row">
                    <div className="col-xs-2" />
                    <div className="col-xs-10">
                      <FormattedMessage {...messages.suitableForOccupancy} />
                    </div>
                  </div>
                </div>
                <YesNoColumns
                  name={`${assetField}.suitableForOccupancy`}
                  noClicked={() => {
                    /* Do nothing, only changes form value*/
                  }}
                  isPrior={isPrior}
                  colSize="3"
                />
                <IncomeAssetCurrencyField
                  name={assetField}
                  type={marketValueType}
                  disabled={disabled || isPrior}
                  colSize="3"
                  onBlur={() => {
                    calculateAnnualIncome(asset.name, true, index);
                    calculateCashValue(asset.name, true, index);
                  }}
                />
              </div>
            </div>
            <div className="col-xs-7">
              <div className="row">
                <div className="col-xs-11">
                  <div className="row">
                    <InterestRateField
                      name={assetField}
                      disabled={disabled || isPrior}
                      type={interestRateType}
                      colSize="2"
                      onBlur={() =>
                        calculateAnnualIncome(asset.name, true, index)
                      }
                    />
                    <IncomeAssetCurrencyField
                      name={assetField}
                      type={feesToConvertCash}
                      disabled={disabled || isPrior}
                      colSize="2"
                      onBlur={() => calculateCashValue(asset.name, true, index)}
                    />
                    <IncomeAssetCurrencyField
                      name={assetField}
                      type={cashValue}
                      disabled={true}
                      colSize="2"
                    />
                    <IncomeAssetCurrencyField
                      name={assetField}
                      type={annualIncome}
                      disabled={true}
                      colSize="3"
                    />
                    <SelectionColumn
                      name={assetField}
                      type={actualImpute}
                      disabled={
                        disabled ||
                        isPrior ||
                        (isYes &&
                          +fieldsAnnualIncome?.[asset.name]?.values?.[index] >
                            0)
                      }
                      colSize="3"
                      options={ASSET_ACTUAL_IMPUTE_OPTIONS(intl)}
                      placeholder={intl.formatMessage(messages.choose)}
                    />
                  </div>
                </div>
                {index !== 0 && (
                  <div className="col-xs-1">
                    <DeleteAccountBtn
                      clickHandler={() => fields.remove(index)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ))}
      {showAddAccountBtn ? (
        <AddAccountLink
          clickHandler={handleAdd}
          fields={fields}
          fieldLimit={asset.fieldLimit}
          disabled={addAccountDisabled}
          additionalValues={{ suitableForOccupancy: null }}
        />
      ) : null}
    </>
  );
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from '../messages';

const GenerateReportButton = ({
  organizationId,
  propertyId,
  selectedIds,
  disabled,
  showToolTip,
  intl,
  onClick,
}) => {
  const button = (
    <Button
      variant={'shout'}
      disabled={disabled}
      intl={intl}
      onClick={() => {
        onClick({ organizationId, propertyId, ids: selectedIds });
      }}
    >
      <FormattedMessage {...messages.generateReport} />
    </Button>
  );
  return (
    <>
      {showToolTip ? (
        <Tooltip
          id={'generateMincTooltip'}
          title={<FormattedMessage {...messages.mustSelectOneRow} />}
        >
          {button}
        </Tooltip>
      ) : (
        button
      )}
    </>
  );
};

export default GenerateReportButton;

import moment from 'moment';
import messages from './messages';
import { ASSET_ROW_NAMES } from './constants';

import { hasDuplicates } from '../validate';
import generalMessages from '../../App/messages';

const validateValueOrIncome = (value: any) => {
  return +value >= 0;
};

const validateValueLessThan = (value, lessThanValue) => {
  return +value < +lessThanValue;
};
const validateNotEmpty = (value) => {
  return !value;
};

/**
 * Validates the Asset Information Form by checking if the required fields are filled out.
 * -
 * @param {*} values a json with the form values
 * @param {*} param1 an object with the props comming from the AssetInformation component
 * @returns an object with or without errors
 */
export const validate = (
  values: Object,
  { intl, isHotmaActive, flags }: Object,
) => {
  const errors = {};
  const { assetsTaxRefund } = flags;

  const isAltForm = values?.isAltForm ?? false;

  if (!isAltForm) {
    ASSET_ROW_NAMES.forEach((row) => {
      if (
        values[row] === 'yes' &&
        !(
          validateValueOrIncome(values[`${row}AssetValue`]) ||
          validateValueOrIncome(values[`${row}AssetIncome`])
        )
      ) {
        errors[`${row}AssetValue`] = intl.formatMessage(
          messages.valueOrIncomeRequired,
        );
      }
      if (values[`${row}AssetValue`] < 0) {
        errors[`${row}AssetValue`] = intl.formatMessage(
          messages.mustBePositive,
        );
      }
      if (values[`${row}AssetIncome`] < 0) {
        errors[`${row}AssetIncome`] = intl.formatMessage(
          messages.mustBePositive,
        );
      }
    });
  } else {
    const assets = ASSET_ROW_NAMES.filter((asset) =>
      assetsTaxRefund ? true : asset !== 'federalTaxRefundOrCredit',
    );
    assets.forEach((row) => {
      const yesNo = values[row];
      if (!yesNo) errors[row] = intl.formatMessage(messages.mustBePositive);
      if (
        values[row] === 'yes' &&
        !(
          validateValueOrIncome(values[`${row}AssetMarketValue`]) ||
          validateValueOrIncome(values[`${row}AssetInterestRate`]) ||
          validateValueOrIncome(values[`${row}AssetFeesToConvertCash`])
        )
      ) {
        errors[`${row}AssetMarketValue`] = intl.formatMessage(
          messages.valueOrIncomeRequired,
        );
      }
      if (values[`${row}AssetMarketValue`] < 0) {
        errors[`${row}AssetMarketValue`] = intl.formatMessage(
          messages.mustBePositive,
        );
      }
      if (values[`${row}AssetInterestRate`] < 0) {
        errors[`${row}AssetInterestRate`] = intl.formatMessage(
          messages.mustBePositive,
        );
      }
      if (values[`${row}AssetFeesToConvertCash`] < 0) {
        errors[`${row}AssetFeesToConvertCash`] = intl.formatMessage(
          messages.mustBePositive,
        );
      }

      const isFederalTaxRefundOrCredit = row === 'federalTaxRefundOrCredit';
      if (
        !isFederalTaxRefundOrCredit &&
        isHotmaActive &&
        validateNotEmpty(values[`${row}AssetActualImpute`]?.replace(/-/g, ''))
      ) {
        errors[`${row}AssetActualImpute`] = intl.formatMessage(
          messages.valueRequired,
        );
      }

      if (
        validateValueLessThan(
          values[`${row}AssetMarketValue`],
          values[`${row}AssetFeesToConvertCash`],
        )
      ) {
        errors[`${row}AssetFeesToConvertCash`] = intl.formatMessage(
          messages.mustBeLessThanMarketValue,
        );
      }

      // Check for list values as well
      if (values[row] === 'yes') {
        const assetRowList = values[`${row}List`];
        if (Array.isArray(assetRowList)) {
          const assetErrors = [];
          assetRowList.forEach((assetRow, idx) => {
            Object.entries(assetRow).forEach(([key, value]) => {
              if (key === 'suitableForOccupancy') {
                if (validateNotEmpty(value)) {
                  if (!assetErrors[idx]) {
                    assetErrors[idx] = {};
                  }
                  assetErrors[idx][key] = intl.formatMessage(
                    messages.suitableForOccupancyRequired,
                  );
                }
              } else if (key === 'feesToConvertCash') {
                if (!validateValueOrIncome(value)) {
                  if (!assetErrors[idx]) {
                    assetErrors[idx] = {};
                  }
                  assetErrors[idx][key] = intl.formatMessage(
                    messages.mustBePositive,
                  );
                }
                if (validateValueLessThan(assetRow.marketValue, value)) {
                  if (!assetErrors[idx]) {
                    assetErrors[idx] = {};
                  }
                  assetErrors[idx][key] = intl.formatMessage(
                    messages.mustBeLessThanMarketValue,
                  );
                }
              } else if (key === 'otherName') {
                const otherRowName = assetRow.otherName;
                if (validateNotEmpty(otherRowName)) {
                  if (!assetErrors[idx]) {
                    assetErrors[idx] = {};
                  }
                  assetErrors[idx][key] = intl.formatMessage(
                    messages.nameRequired,
                  );
                }

                if (hasDuplicates(assetRowList, otherRowName, 'otherName')) {
                  if (!assetErrors[idx]) {
                    assetErrors[idx] = {};
                  }
                  assetErrors[idx][key] = intl.formatMessage(
                    generalMessages.duplicateIdentifiers,
                    {
                      item: '"Other" rows',
                      identifiers: 'names',
                    },
                  );
                }
              } else if (isHotmaActive && key === 'actualImpute') {
                const actualImputeValue = assetRow.actualImpute?.replace(
                  /-/g,
                  '',
                );
                if (validateNotEmpty(actualImputeValue)) {
                  if (!assetErrors[idx]) {
                    assetErrors[idx] = {};
                  }
                  assetErrors[idx][key] = intl.formatMessage(
                    messages.valueRequired,
                  );
                }
              } else {
                if (!validateValueOrIncome(value)) {
                  if (!assetErrors[idx]) {
                    assetErrors[idx] = {};
                  }
                  assetErrors[idx][key] = intl.formatMessage(
                    messages.mustBePositive,
                  );
                }
              }
            });
          });
          errors[`${row}List`] = assetErrors;
        }
      }
    });
  }
  if (
    (values.realEstate === 'yes' || values.rentalProperty === 'yes') &&
    isHotmaActive
  ) {
    // Validate that all real estate and rental property rows have a suitableForOccupancy value
    const RElist = values.realEstateList;
    const RPlist = values.rentalPropertyList;
    const extractErrors = (list) => {
      const errors = [];
      list.forEach((row, idx) => {
        if (!row.suitableForOccupancy) {
          if (!errors[idx]) {
            errors[idx] = {};
          }
          errors[idx]['suitableForOccupancy'] = intl.formatMessage(
            messages.suitableForOccupancyRequired,
          );
        }

        const actualImputeValue = row.actualImpute?.replace(/-/g, '');
        if (validateNotEmpty(actualImputeValue)) {
          if (!errors[idx]) {
            errors[idx] = {};
          }
          errors[idx]['actualImpute'] = intl.formatMessage(
            messages.valueRequired,
          );
        }
      });
      return errors;
    };
    if (Array.isArray(RElist) && Array.isArray(RPlist)) {
      errors['realEstateList'] = extractErrors(RElist);
      errors['rentalPropertyList'] = extractErrors(RPlist);
    }
  }
  if (values.disposedDate) {
    const disposedDate = moment(values.disposedDate);
    if (!disposedDate.isValid()) {
      errors['disposedDate'] = intl.formatMessage(messages.invalidDate);
    } else {
      const today = moment();
      const threeYearsPrior = moment().subtract(3, 'years');
      if (!disposedDate.isBetween(threeYearsPrior, today, null, '[]')) {
        errors['disposedDate'] = intl.formatMessage(messages.invalidDate);
      }
    }
  }

  return errors;
};

export default validate;

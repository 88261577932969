import { SelectOption } from '../../containers/App/types';

const YES_VALUE = 'yes';
const YES_TEXT = 'Yes';
const YES_50_VALUE = 'yes50';
const YES_50_TEXT = 'Yes - 50% or more';
const YES_LESS_THAN_50_VALUE = 'yesLessThan50';
const YES_LESS_THAN_50_TEXT = 'Yes - < 50%';
const NO_VALUE = 'no';
const NO_TEXT = 'No';
const DO_NOT_WISH_TO_FURNISH_VALUE = 'notWishToFurnish';
const DO_NOT_WISH_TO_FURNISH_TEXT = 'Do not wish to provide';
const DO_NOT_WISH_TO_PROVIDE_VALUE = 'notWishToProvide';
const DO_NOT_WISH_OPTION = {
  value: DO_NOT_WISH_TO_FURNISH_VALUE,
  text: DO_NOT_WISH_TO_FURNISH_TEXT,
  disabled: false,
};
export const DO_NOT_WISH_PROVIDE_OPTION = {
  value: DO_NOT_WISH_TO_PROVIDE_VALUE,
  text: DO_NOT_WISH_TO_FURNISH_TEXT,
  disabled: false,
};

export const yesNoBooleanOptions = [
  { value: YES_VALUE, text: YES_TEXT, disabled: false },
  { value: NO_VALUE, text: NO_TEXT, disabled: false },
];

export const generalDisabilityOptions = [
  ...yesNoBooleanOptions,
  { ...DO_NOT_WISH_OPTION, value: DO_NOT_WISH_TO_PROVIDE_VALUE },
];

export const genderOptions = [
  { value: 'male', text: 'Male', disabled: false },
  { value: 'female', text: 'Female', disabled: false },
  DO_NOT_WISH_PROVIDE_OPTION,
];

export const veteranOptions = [...yesNoBooleanOptions, DO_NOT_WISH_OPTION];

export const disabledOptions = [
  ...yesNoBooleanOptions,
  { ...DO_NOT_WISH_OPTION, value: DO_NOT_WISH_TO_PROVIDE_VALUE },
];

export const ethnicityOptions = [
  { value: 'hispanic', text: 'Hispanic/Latino', disabled: false },
  { value: 'notHispanic', text: 'Not Hispanic/Latino', disabled: false },
  DO_NOT_WISH_PROVIDE_OPTION,
];

/**
 * Returns the races options based on the selected property state code
 * ---
 * @param {*} selectedPropertyStateCode the selected property state code
 * @returns the SelectOption array
 */
export const raceOptions = (
  selectedPropertyStateCode: string = '',
  { withDefault } = { withDefault: true },
): SelectOption[] => {
  const washingtonCode = 'WA';
  const michiganCode = 'MI';
  const isWAorMI =
    selectedPropertyStateCode === washingtonCode ||
    selectedPropertyStateCode === michiganCode;
  const isStateCodePresent = selectedPropertyStateCode !== '';

  return [
    ...(isStateCodePresent && withDefault
      ? [{ value: 'default', text: 'Choose', disabled: false }]
      : []),
    { value: 'white', text: 'White', disabled: false },
    { value: 'blackAmerican', text: 'Black/African American', disabled: false },
    { value: 'hawaiian', text: 'Hawaiian/Pacific Islander', disabled: false },
    { value: 'asian', text: 'Asian', disabled: false },
    { value: 'indian', text: 'Indian/Alaskan Native', disabled: false },
    { value: 'asianIndian', text: 'Asian/India', disabled: false },
    { value: 'chinese', text: 'Chinese', disabled: false },
    { value: 'filipino', text: 'Filipino', disabled: false },
    { value: 'Japanese', text: 'Japanese', disabled: false },
    { value: 'korean', text: 'Korean', disabled: false },
    { value: 'vietnamese', text: 'Vietnamese', disabled: false },
    { value: 'otherAsian', text: 'Other Asian', disabled: false },
    ...(isStateCodePresent && isWAorMI
      ? [{ value: 'multiRacial', text: 'Multi-Racial', disabled: false }]
      : []),
    { value: 'other', text: 'Other', disabled: false },
    DO_NOT_WISH_PROVIDE_OPTION,
  ];
};

export const studentsTypes = [
  { value: 'f_tK_12', text: 'Full-Time K-12', disabled: false },
  { value: 'f_tPost12', text: 'Full-Time Post 12', disabled: false },
  { value: 'p_t', text: 'Part-Time', disabled: false },
];

export const additionalStudentTypes = [
  { value: 'n_s', text: 'Not a Student', disabled: false },
];

export const maritalStatuses = [
  { text: 'Single', value: 'single', disabled: false },
  { text: 'Married', value: 'married', disabled: false },
  { text: 'Divorced', value: 'divorced', disabled: false },
  { text: 'Separated', value: 'separated', disabled: false },
  { text: 'Widowed', value: 'widowed', disabled: false },
];

export const custodyOptions = [
  { value: NO_VALUE, text: NO_TEXT, disabled: false },
  { value: YES_50_VALUE, text: YES_50_TEXT, disabled: false },
  {
    value: YES_LESS_THAN_50_VALUE,
    text: YES_LESS_THAN_50_TEXT,
    disabled: false,
  },
];

export const numbers0To10Options = [
  { text: '0', value: 0, disabled: false },
  { text: '1', value: 1, disabled: false },
  { text: '2', value: 2, disabled: false },
  { text: '3', value: 3, disabled: false },
  { text: '4', value: 4, disabled: false },
  { text: '5', value: 5, disabled: false },
  { text: '6', value: 6, disabled: false },
  { text: '7', value: 7, disabled: false },
  { text: '8', value: 8, disabled: false },
  { text: '9', value: 9, disabled: false },
  { text: '10', value: 10, disabled: false },
];

export const raceSourceOptions = [
  {
    value: 'C-Customer Provided',
    text: 'Customer Provided',
    disabled: false,
  },
  {
    value: 'E-Employee Observed',
    text: 'Employee Observed',
    disabled: false,
  },
];

import { useMutation, MutationOptions } from 'react-query';
import AffordableQualificationService from '../../services/affordableQualificationService';
import moment from 'moment/moment';

const useGenerateRD3560_8Mutation = ({
  organizationId,
  propertyId,
  options,
}: {
  organizationId: string,
  propertyId: string,
  options?: MutationOptions,
}) => {
  return useMutation(
    ['AffordableQualificationService.generateRD', organizationId, propertyId],
    async (affordableQualificationId: string) => {
      const fileName = `3560_8_${moment().unix()}.pdf`;
      await new AffordableQualificationService().generateRD(
        organizationId,
        propertyId,
        affordableQualificationId,
        fileName,
      );
    },
    {
      enabled: Boolean(organizationId && propertyId),
      ...options,
    },
  );
};

export default useGenerateRD3560_8Mutation;

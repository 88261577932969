import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { SubmitButton } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import useDownloadAll59AsMutation from './hooks.useDownloadAll59AsMutation';
import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

const GenerateAllRDGRCerts = ({ disabled, intl, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const { organizationId, id: propertyId } = useSelectedProperty();

  const downloadAllRdGrAsMutation = useDownloadAll59AsMutation({
    organizationId,
    propertyId,
    options: {
      enabled: Boolean(organizationId && propertyId && !disabled),
      onMutate: () => {
        toastr.info(
          intl.formatMessage(messages.generatingAllRDCerts),
          intl.formatMessage(messages.willDownloadWhenComplete),
        );
      },
      onSuccess: () => {
        toastr.success(
          intl.formatMessage(messages.success),
          intl.formatMessage(messages.generateAllRDCertsSuccess),
        );
      },
      onError: (error) => {
        toastr.error(
          intl.formatMessage(messages.error),
          intl.formatMessage(messages.errorGeneratingAllRDCerts, {
            errorMessage: error.toString(),
          }),
        );
      },
    },
    programName: 'RD',
  });

  return (
    <SubmitButton
      variant={'shout'}
      isSubmitting={downloadAllRdGrAsMutation.isLoading}
      onClick={downloadAllRdGrAsMutation.mutate}
      disabled={disabled}
      {...props}
    >
      <FormattedMessage {...messages.generateAllRdGrCerts} />
    </SubmitButton>
  );
};

export default GenerateAllRDGRCerts;

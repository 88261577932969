import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Components
import {
  Card,
  Divider,
  Typography,
  Switch,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import {
  SingleSelect,
  Grid,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { TextInput } from '../BasicDetails/TextInput';
import InfoIconToolTip from '../../../components/InfoIconToolTip';
import RDSetup from './Programs/RD';
import HUDSetup from './Programs/HUD';
import LIHTCSetup from './Programs/LIHTC';
import HOMESetup from './Programs/HOME';
import SetupGridItem from '../SetupGridItem';
import { SetupSwitch } from './Programs/shared';
import ElementWithPermissions from '../../../components/ElementWithPermissions';

// Hooks
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFetchPassbookRatesByState } from './hooks';
import {
  HOME_PROGRAM_NAME,
  LIHTC_HOME_PROGRAM_NAME,
  LIHTC_PROGRAM_NAME,
} from '../../../constants/affordableProgramNames';

// Utils
import {
  displayAsDashesIfEmpty,
  getProgramPassbookRatesDataByProgramName,
  stripOnlyWhiteSpaceToNull,
} from './utils';
import { capitalize } from 'lodash';

// Constants
import messages from './messages';
import integrationSetupMessages from '../IntegrationsSetup/messages';

type AffordableDetails = {
  organizationId: string,
  propertyId: string,
  state: string,
  programs: Object[],
  hudSubsidyTypes: string[],
  hudSecondarySubsidyTypes: string[],
};

type AffordableSetupValues = {
  stateId: string,
  HUDContractNumber: string,
  HUDProjectNumber: string,
  projectIdTic: string,
  requiresInitialCertificationForNonHUD?: boolean,
  RDProjectNumber: string,
  RDBorrowerId: string,
  RDProjectName: string,
  RDProgramType: Object,
  RDProjectType: Object,
  isRDRAEnabled: boolean,
  HUDPrimarySubsidyTypeId?: string,
  HUDSecondarySubsidyTypeId?: string,
  hudPrimarySubsidyType?: Object,
  hudSecondarySubsidyType?: Object,
  HUDCompleteGRCertsWithoutSigned59As: boolean,
  interimCert: boolean,
  HUDAnnualRecertMonth: boolean,
  LIHTCAnnualRecertMonth: boolean,
  RDAnnualRecertMonth: boolean,
  HOMEAnnualRecertMonth: boolean,
  affordableWaitlist: { enabled: boolean, status: string },
};

type Props = {
  editMode: boolean,
  onChange: Function,
  promptToaster: Function,
  disabled: boolean,
} & AffordableSetupValues &
  AffordableDetails;

function AffordableSetup({
  organizationId,
  propertyId,
  state,
  promptToaster,
  intl,
  editMode: editModeProp,
  onChange,
  stateId,
  projectIdTic,
  HUDContractNumber,
  HUDProjectNumber,
  requiresInitialCertificationForNonHUD,
  XMLPropertyName,
  XMLProjectName,
  isOverrideQualificationEnabled,
  RDProjectNumber,
  RDProjectType,
  RDBorrowerId,
  RDProjectName,
  RDProgramType,
  isRDRAEnabled,
  HUDPrimarySubsidyTypeId,
  HUDSecondarySubsidyTypeId,
  hudPrimarySubsidyType,
  hudSecondarySubsidyType,
  HUDCompleteGRCertsWithoutSigned59As,
  HOMEAffordabilityPeriodStartDate,
  HOMEUnitType,
  HOMEAnnualIncomeType,
  HOMEPropertyType,
  autoSendToTRACS,
  autoRolloverIncomeAndAssets,
  interimCert,
  annualRecertMonth,
  affordableWaitlist,
  disabled,
  ...affordableDetails
}: Props) {
  const editMode = editModeProp && !disabled;
  const {
    initialCertsForLihtc,
    passbookRate,
    homeEnhancements,
    alltracsDropboxAutomation,
    rolloverIncomeAndAssets,
    grossRentEnhancements,
    lihtcAffordableTransfers,
    affordableWaitlist: affordableWaitlistFlag,
  } = useFlags();
  const affordablePrograms = affordableDetails?.programs ?? [];
  const programNames = affordablePrograms.map(
    (pap) => pap?.masterAffordableProgram?.name,
  );

  const hasLIHTCHOMEProgram = homeEnhancements
    ? programNames.includes(LIHTC_HOME_PROGRAM_NAME)
    : false;
  const hasHUDProgram = programNames.includes('HUD');
  const hasRDProgram = programNames.includes('RD');
  const hasLIHTCProgram = programNames.includes('LIHTC');
  const hasHOMEProgram = programNames.includes('HOME');
  const hasHUDProgramOnly =
    hasHUDProgram && programNames.length === 1 && programNames.length !== 0;

  const affordableProgramNames = programNames.filter((programName) =>
    hasLIHTCHOMEProgram
      ? ![LIHTC_PROGRAM_NAME, HOME_PROGRAM_NAME].includes(programName)
      : true,
  );

  const { data: statePassbookRates, isLoading: isLoadingPassbookRates } =
    useFetchPassbookRatesByState({
      organizationId,
      propertyId,
      state,
    });

  const RDSetupValues = {
    RDProjectNumber,
    RDProjectType,
    RDBorrowerId,
    RDProjectName,
    RDProgramType,
    isRDRAEnabled,
    autoRolloverIncomeAndAssets: autoRolloverIncomeAndAssets.RD,
    RDAnnualRecertMonth: annualRecertMonth.RD,
    flags: {
      passbookRate,
      rolloverIncomeAndAssets,
    },
    passbookRates: getProgramPassbookRatesDataByProgramName(
      'RD',
      statePassbookRates,
    ),
    intl,
  };
  const HUDValues = {
    HUDPrimarySubsidyTypeId,
    hudPrimarySubsidyType,
    HUDProjectNumber,
    HUDSecondarySubsidyTypeId,
    hudSecondarySubsidyType,
    HUDContractNumber,
    HUDCompleteGRCertsWithoutSigned59As,
    autoSendToTRACS,
    autoRolloverIncomeAndAssets: autoRolloverIncomeAndAssets.HUD,
    HUDAnnualRecertMonth: annualRecertMonth.HUD,
    passbookRates: getProgramPassbookRatesDataByProgramName(
      'HUD',
      statePassbookRates,
    ),
    flags: {
      alltracsDropboxAutomation,
      rolloverIncomeAndAssets,
      grossRentEnhancements,
    },
    intl,
  };
  const LIHTCValues = {
    stateId,
    projectIdTic,
    XMLPropertyName,
    XMLProjectName,
    requiresInitialCertificationForNonHUD,
    isOverrideQualificationEnabled,
    autoRolloverIncomeAndAssets: autoRolloverIncomeAndAssets.LIHTC,
    LIHTCAnnualRecertMonth: annualRecertMonth.LIHTC,
    interimCert,
    flags: {
      initialCertsForLihtc,
      hasHUDProgramOnly,
      rolloverIncomeAndAssets,
      lihtcAffordableTransfers,
    },
    passbookRates: getProgramPassbookRatesDataByProgramName(
      'LIHTC',
      statePassbookRates,
    ),
    intl,
  };

  const HOMEValues = {
    HOMEAffordabilityPeriodStartDate,
    HOMEUnitType,
    HOMEAnnualIncomeType,
    HOMEPropertyType,
    autoRolloverIncomeAndAssets: autoRolloverIncomeAndAssets.HOME,
    HOMEAnnualRecertMonth: annualRecertMonth.HOME,
    flags: {
      passbookRate,
      rolloverIncomeAndAssets,
    },
    passbookRates: getProgramPassbookRatesDataByProgramName(
      'HOME',
      statePassbookRates,
    ),
    intl,
  };
  const anyHUDSubsidyType =
    (affordableDetails?.hudSubsidyTypes ?? []).length > 0;
  const anyHUDSecondarySubsidyType =
    affordableDetails.hudSecondarySubsidyTypes.length > 0;
  const infoTipMessage = (
    <>
      <p style={{ fontSize: '12px', textAlign: 'center' }}>
        <FormattedMessage {...messages.initialCertForNonHUDTip} />
      </p>
    </>
  );
  // This function helps to convert the new Switch data to match parent form utility function
  const handleSwitchChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.checked;
    onChange({ target: { name, value } });
  };
  const TypographyInputLabelProps = {
    variant: 'inputLabel',
    display: 'block',
    mb: 1,
  };

  const displayBothAffordableWaitlistOptions =
    affordableWaitlistFlag && affordableWaitlist.enabled;
  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant={'h3'}>
        <FormattedMessage {...messages.title} />
      </Typography>
      <Divider sx={{ marginY: 2 }} />
      {passbookRate ? (
        <>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={displayBothAffordableWaitlistOptions ? 4 : 6}
            >
              <Typography {...TypographyInputLabelProps}>
                <FormattedMessage {...messages.affordablePrograms} />
              </Typography>
              <Typography variant="body1">
                {affordableProgramNames.join(' / ')}
              </Typography>
            </Grid>
            {affordableWaitlistFlag && (
              <>
                <SetupGridItem
                  xs={12}
                  sm={affordableWaitlist.enabled ? 4 : 6}
                  label={<FormattedMessage {...messages.affordableWaitlist} />}
                  TooltipProps={{
                    title: (
                      <FormattedMessage
                        {...messages.affordableWaitlistTooltip}
                      />
                    ),
                  }}
                >
                  <SetupSwitch
                    switchId={'affordable-waitlist-switch'}
                    editMode={editMode}
                    name="setup.affordableWaitlist"
                    disabled={!editMode}
                    value={affordableWaitlist.enabled}
                    onChange={onChange}
                  />
                </SetupGridItem>
                {affordableWaitlist.enabled && (
                  <SetupGridItem
                    xs={12}
                    sm={4}
                    label={
                      <FormattedMessage
                        {...messages.affordableWaitlistStatus}
                      />
                    }
                  >
                    {editMode ? (
                      <ElementWithPermissions
                        scope={['affordable-waitlist-open-close']}
                      >
                        <SingleSelect
                          options={[
                            {
                              value: 'OPEN',
                              text: 'Open',
                            },
                            {
                              value: 'CLOSED',
                              text: 'Closed',
                            },
                          ]}
                          value={affordableWaitlist.status}
                          onChange={(value) => {
                            onChange({
                              target: {
                                name: 'setup.affordableWaitlistStatus',
                                value,
                              },
                            });
                          }}
                        />
                      </ElementWithPermissions>
                    ) : (
                      <Typography
                        color={
                          affordableWaitlist.status === 'OPEN'
                            ? 'green'
                            : 'firebrick'
                        }
                      >
                        {capitalize(affordableWaitlist.status)}
                      </Typography>
                    )}
                  </SetupGridItem>
                )}
              </>
            )}
          </Grid>
          <Divider sx={{ marginY: 2 }} />
          {hasHUDProgram && (
            <HUDSetup
              promptToaster={promptToaster}
              isLoadingPassbookRateData={isLoadingPassbookRates}
              editMode={editMode}
              onChange={onChange}
              values={HUDValues}
            />
          )}
          {hasLIHTCProgram && (
            <>
              {hasHUDProgram && <Divider sx={{ marginY: 2 }} />}
              <LIHTCSetup
                promptToaster={promptToaster}
                isLoadingPassbookRateData={isLoadingPassbookRates}
                editMode={editMode}
                onChange={onChange}
                values={LIHTCValues}
              />
            </>
          )}
          {hasHOMEProgram && homeEnhancements && (
            <>
              <Divider sx={{ marginY: 2 }} />
              <HOMESetup
                onChange={onChange}
                promptToaster={promptToaster}
                isLoadingPassbookRateData={isLoadingPassbookRates}
                editMode={editMode}
                values={HOMEValues}
              />
            </>
          )}
          {hasRDProgram && (
            <>
              {(hasHUDProgram || hasLIHTCProgram) && (
                <Divider sx={{ marginY: 2 }} />
              )}
              <RDSetup
                promptToaster={promptToaster}
                isLoadingPassbookRateData={isLoadingPassbookRates}
                editMode={editMode}
                onChange={onChange}
                values={RDSetupValues}
              />
            </>
          )}
        </>
      ) : (
        <>
          <div className="container-fluid panel__details">
            <Row>
              <Col xs={12} sm={6}>
                <Row>
                  <Col xs={12} md={5}>
                    <strong>
                      <FormattedMessage {...messages.stateProjectIdXml} />
                    </strong>
                  </Col>
                  <Col xs={12} md={7}>
                    {editMode ? (
                      <TextInput
                        name="setup.stateId"
                        value={stateId}
                        editMode={editMode}
                        required
                        placeholder="i.e. AR012-345"
                        onChange={onChange}
                      />
                    ) : (
                      stateId
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={5}>
                    <strong>
                      <FormattedMessage {...messages.stateProjectIdTicHec} />
                    </strong>
                  </Col>
                  <Col xs={12} md={7}>
                    {editMode ? (
                      <TextInput
                        name="setup.projectIdTic"
                        value={projectIdTic}
                        editMode={editMode}
                        required
                        placeholder="i.e. AR012-345"
                        onChange={onChange}
                      />
                    ) : (
                      projectIdTic
                    )}
                  </Col>
                </Row>
                {hasHUDProgram && (
                  <>
                    <Row>
                      <Col xs={12} md={5}>
                        <strong>
                          <FormattedMessage {...messages.hudSubsidyType} />
                        </strong>
                      </Col>
                      <Col xs={12} md={7}>
                        {anyHUDSubsidyType
                          ? affordableDetails.hudSubsidyTypes.join(', ')
                          : '---'}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={5}>
                        <strong>
                          <FormattedMessage {...messages.hudProjectNumber} />
                        </strong>
                      </Col>
                      <Col xs={12} md={7}>
                        {editMode ? (
                          <TextInput
                            name="setup.HUDProjectNumber"
                            value={HUDProjectNumber}
                            editMode={editMode}
                            required
                            placeholder="i.e. 06475404"
                            onChange={onChange}
                          />
                        ) : (
                          HUDProjectNumber
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
              <Col xs={12} sm={6}>
                <Row>
                  <Col xs={12} md={5}>
                    <strong>
                      <FormattedMessage {...messages.affordablePrograms} />
                    </strong>
                  </Col>
                  <Col xs={12} md={7}>
                    {programNames.join(' / ')}
                  </Col>
                </Row>
                {!hasHUDProgramOnly && initialCertsForLihtc && (
                  <Row>
                    <Col xs={12} md={5}>
                      <strong>
                        <FormattedMessage {...messages.initialCertForNonHUD} />
                        <span style={{ paddingLeft: 4 }}>
                          <InfoIconToolTip
                            placement={'top'}
                            message={infoTipMessage}
                          />
                        </span>
                      </strong>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      className={
                        requiresInitialCertificationForNonHUD
                          ? 'integration-on'
                          : 'integration-off'
                      }
                    >
                      {editMode ? (
                        <Switch
                          name="setup.requiresInitialCertificationForNonHUD"
                          id="initial-cert-for-non-HUD-switch"
                          checked={requiresInitialCertificationForNonHUD}
                          disabled={!editMode}
                          onChange={handleSwitchChange}
                        />
                      ) : requiresInitialCertificationForNonHUD ? (
                        <FormattedMessage {...integrationSetupMessages.on} />
                      ) : (
                        <FormattedMessage {...integrationSetupMessages.off} />
                      )}
                    </Col>
                  </Row>
                )}
                {hasHUDProgram && (
                  <>
                    <Row>
                      <Col xs={12} md={5}>
                        <strong>
                          <FormattedMessage
                            {...messages.hudSecondarySubsidyType}
                          />
                        </strong>
                      </Col>
                      <Col xs={12} md={7}>
                        {anyHUDSecondarySubsidyType
                          ? affordableDetails.hudSecondarySubsidyTypes.join(
                              ', ',
                            )
                          : '---'}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={5}>
                        <strong>
                          <FormattedMessage {...messages.hudContractNumber} />
                        </strong>
                      </Col>
                      <Col xs={12} md={7}>
                        {editMode ? (
                          <TextInput
                            name="setup.HUDContractNumber"
                            value={HUDContractNumber}
                            editMode={editMode}
                            required
                            placeholder="i.e. TN430016010"
                            onChange={onChange}
                          />
                        ) : (
                          HUDContractNumber
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Row>
                  <Col xs={12} md={5}>
                    <strong>
                      <FormattedMessage {...messages.altXMLPropertyName} />
                    </strong>
                  </Col>
                  <Col xs={12} md={7}>
                    {editMode ? (
                      <TextInput
                        name="setup.XMLPropertyName"
                        value={XMLPropertyName}
                        editMode={editMode}
                        onChange={({ target: { name, value } }) => {
                          onChange({
                            target: {
                              name,
                              value: stripOnlyWhiteSpaceToNull(value),
                            },
                          });
                        }}
                        maxLength={'100'}
                      />
                    ) : (
                      displayAsDashesIfEmpty(XMLPropertyName)
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={6}>
                <Row>
                  <Col xs={12} md={5}>
                    <strong>
                      <FormattedMessage {...messages.overrideQualification} />
                    </strong>
                  </Col>
                  <Col
                    xs={12}
                    md={7}
                    className={
                      isOverrideQualificationEnabled
                        ? 'integration-on'
                        : 'integration-off'
                    }
                  >
                    {editMode ? (
                      <Switch
                        name="setup.isOverrideQualificationEnabled"
                        id="override-qualification-switch"
                        checked={isOverrideQualificationEnabled}
                        disabled={!editMode}
                        onChange={handleSwitchChange}
                      />
                    ) : isOverrideQualificationEnabled ? (
                      <FormattedMessage {...integrationSetupMessages.on} />
                    ) : (
                      <FormattedMessage {...integrationSetupMessages.off} />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Row>
                  <Col xs={12} md={5}>
                    <strong>
                      <FormattedMessage {...messages.altXMLProjectName} />
                      <sup>*</sup>
                      <Tooltip
                        component="span"
                        placement="top"
                        variant="light"
                        title={
                          <FormattedMessage
                            {...messages.altXMLProjectNameTooltip}
                          />
                        }
                      >
                        <AlertInfoIcon
                          sx={{
                            marginRight: 0.5,
                            position: 'relative',
                            top: 2,
                            '& path': { stroke: 'white !important' },
                          }}
                          fontSize={'small'}
                          strokeWidth={0.5}
                        />
                      </Tooltip>
                    </strong>
                  </Col>
                  <Col xs={12} md={7}>
                    {editMode ? (
                      <TextInput
                        name="setup.XMLProjectName"
                        value={XMLProjectName}
                        editMode={editMode}
                        onChange={({ target: { name, value } }) => {
                          onChange({
                            target: {
                              name,
                              value: stripOnlyWhiteSpaceToNull(value),
                            },
                          });
                        }}
                        maxLength={'100'}
                      />
                    ) : (
                      displayAsDashesIfEmpty(XMLProjectName)
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {
            /* RD Setup */
            hasRDProgram && (
              <>
                <Divider sx={{ marginTop: '16px', marginBottom: '16px' }} />
                <RDSetup
                  editMode={editMode}
                  onChange={onChange}
                  values={RDSetupValues}
                />
              </>
            )
          }
          {hasHOMEProgram && homeEnhancements && (
            <>
              <Divider sx={{ marginTop: '16px', marginBottom: '16px' }} />
              <HOMESetup
                onChange={onChange}
                editMode={editMode}
                values={HOMEValues}
              />
            </>
          )}
        </>
      )}
    </Card>
  );
}

export default AffordableSetup;

import messages from '../messages';
import { Box } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Banner } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  RefreshIcon,
  AlertCriticalIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import React from 'react';
import useIntl from '../../../hooks/useIntl';

const IntacctModal = (props) => {
  const { open, closeModal, onSubmit } = props;
  const intl = useIntl();

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={
        <>
          <RefreshIcon color="primary" />
          <Box ml={1}>{intl.formatMessage(messages.intacctHeader)}</Box>
        </>
      }
      actionsProps={[
        {
          children: 'Cancel',
          onClick: closeModal,
        },
        {
          children: 'Confirm & Refresh',
          submitButton: true,
          onClick: onSubmit,
        },
      ]}
      ModalProps={{
        PaperProps: {
          sx: {
            width: {
              xs: 'calc(100% - 32px)',
              sm: 480,
            },
            maxHeight: 'calc(100vh - 100px)',
          },
        },
      }}
    >
      <Box>
        <Box mb={2}>{intl.formatMessage(messages.intacctBody)}</Box>
        <Banner
          color="warning"
          text={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <AlertCriticalIcon color="warning" />
              <Box ml={1}>
                {intl.formatMessage(messages.intacctWarningPart1)}{' '}
                <strong>
                  {intl.formatMessage(messages.intacctWarningPart2)}
                </strong>{' '}
                {intl.formatMessage(messages.intacctWarningPart3)}
              </Box>
            </Box>
          }
          hasBorder={false}
          TypographyProps={{
            sx: {
              whiteSpace: 'normal',
            },
          }}
        />
      </Box>
    </Modal>
  );
};

export default IntacctModal;

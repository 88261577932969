import { useMemo } from 'react';

const useRows = ({
  handleOpenModal,
  handleOpenConfirmModal,
  housingAssistanceVouchers,
}) => {
  return useMemo(() => {
    return housingAssistanceVouchers.map((row) => {
      const voucherSource = row.voucherSource;
      const voucherAmount = row.voucherAmount;
      const residentRent = row.residentRent;
      const voucherStartDate = row.voucherStartDate;
      const voucherEndDate = row.voucherEndDate;

      // deletion allowance
      const isStartDateInTheFuture =
        voucherStartDate && new Date(voucherStartDate) > new Date();

      return {
        voucherSource: {
          variant: 'description',
          value: voucherSource,
        },
        voucherAmount: {
          variant: 'currency',
          value: voucherAmount,
        },
        residentRent: {
          variant: 'currency',
          value: residentRent,
        },
        voucherStartDate: {
          variant: 'date',
          value: voucherStartDate,
        },
        voucherEndDate: {
          variant: 'date',
          value: voucherEndDate || null,
        },
        actions: {
          variant: 'iconButtons',
          array: [
            {
              name: 'edit',
              iconName: 'PencilIcon',
              color: 'primary',
              onClick: () => {
                handleOpenModal({
                  modalType: 'editVoucher',
                  voucher: {
                    id: row.id,
                    voucherSource,
                    voucherAmount,
                    voucherStartDate,
                    voucherEndDate,
                  },
                });
              },
            },
            ...(isStartDateInTheFuture
              ? [
                  {
                    name: 'delete',
                    iconName: 'TrashIcon',
                    color: 'error',
                    disabled: false,
                    onClick: () => {
                      handleOpenConfirmModal({
                        modalType: 'deleteVoucher',
                        voucher: {
                          id: row.id,
                        },
                      });
                    },
                  },
                ]
              : []),
          ],
        },
      };
    });
  }, [handleOpenModal, handleOpenConfirmModal, housingAssistanceVouchers]);
};

export default useRows;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  assetInformation: {
    id: 'App.Qualification.AssetInformationForm.AssetInformation',
    defaultMessage: 'Asset Information for',
  },
  assetNote: {
    id: 'App.Qualification.AssetInformationForm.assetNote',
    defaultMessage:
      'Please include all assets for all members of the household, including children under 18.',
  },
  typeOfAsset: {
    id: 'App.Qualification.AssetInformationForm.TypeOfAsset',
    defaultMessage: 'Type of Asset',
  },
  currentlyOwn: {
    id: 'App.Qualification.AssetInformationForm.CurrentlyOwn',
    defaultMessage: 'Currently Own',
  },
  value: {
    id: 'App.Qualification.AssetInformationForm.Value',
    defaultMessage: 'Value',
  },
  monthlyIncome: {
    id: 'App.Qualification.AssetInformationForm.MonthlyIncome',
    defaultMessage: 'Monthly Income from Assets',
  },
  over5k: {
    id: 'App.Qualification.AssetInformationForm.over5k',
    defaultMessage: 'If All Assets combined over $5,000.00',
  },
  cashHeld: {
    id: 'App.Qualification.AssetInformationForm.CashHeld',
    defaultMessage: 'Cash Held',
  },
  checkingAccount: {
    id: 'App.Qualification.AssetInformationForm.CheckingAccount',
    defaultMessage: 'Checking Account',
  },
  savingsAccount: {
    id: 'App.Qualification.AssetInformationForm.SavingsAccount',
    defaultMessage: 'Savings Account',
  },
  payCard: {
    id: 'App.Qualification.AssetInformationForm.PayCard',
    defaultMessage: 'Pay Card',
  },
  certificateOfDeposit: {
    id: 'App.Qualification.AssetInformationForm.CertificateOfDeposit',
    defaultMessage: 'Certificate of Deposit',
  },
  trustAccount: {
    id: 'App.Qualification.AssetInformationForm.TrustAccount',
    defaultMessage: 'Trust Account',
  },
  treasuryMoneyMarketFund: {
    id: 'App.Qualification.AssetInformationForm.TreasuryMoneyMarketFund',
    defaultMessage: 'Treasury Bills, Money Market Fund',
  },
  stocksOrBonds: {
    id: 'App.Qualification.AssetInformationForm.StocksOrBonds',
    defaultMessage: 'Stocks or Bonds',
  },
  retirementPlanAccount: {
    id: 'App.Qualification.AssetInformationForm.RetirementPlanAccount',
    defaultMessage: 'Retirement Plan Account',
  },
  ira: {
    id: 'App.Qualification.AssetInformationForm.IRA',
    defaultMessage: 'IRA',
  },
  pension: {
    id: 'App.Qualification.AssetInformationForm.Pension',
    defaultMessage: 'Pension',
  },
  keogh: {
    id: 'App.Qualification.AssetInformationForm.Keogh',
    defaultMessage: 'Keogh',
  },
  k401: {
    id: 'App.Qualification.AssetInformationForm.k401',
    defaultMessage: '401(k)',
  },
  lifeInsurance: {
    id: 'App.Qualification.AssetInformationForm.LifeInsurance',
    defaultMessage: 'Life insurance policy (not Term)',
  },
  realEstate: {
    id: 'App.Qualification.AssetInformationForm.RealEstate',
    defaultMessage: 'Real Estate currently owned',
  },
  rentalProperty: {
    id: 'App.Qualification.AssetInformationForm.RentalProperty',
    defaultMessage: 'Rental Property',
  },
  disposed: {
    id: 'App.Qualification.AssetInformationForm.disposed',
    defaultMessage:
      'Assets disposed of for less than Fair Market Value in past 2 years',
  },
  investmentProperties: {
    id: 'App.Qualification.AssetInformationForm.investmentProperties',
    defaultMessage: 'Personal Property held for investment',
  },
  aob: {
    id: 'App.Qualification.AssetInformationForm.AOB',
    defaultMessage: 'AOB',
  },
  vob: {
    id: 'App.Qualification.AssetInformationForm.vob',
    defaultMessage: 'VOB',
  },
  vosab: {
    id: 'App.Qualification.AssetInformationForm.VOSAB',
    defaultMessage: 'VOSAB',
  },
  vora: {
    id: 'App.Qualification.AssetInformationForm.VORA',
    defaultMessage: 'VORA',
  },
  voli: {
    id: 'App.Qualification.AssetInformationForm.VOLI',
    defaultMessage: 'VOLI',
  },
  coa: {
    id: 'App.Qualification.AssetInformationForm.COA',
    defaultMessage: 'COA & applicable documentation',
  },
  coda: {
    id: 'App.Qualification.AssetInformationForm.CODA',
    defaultMessage: 'CODA & applicable documentation',
  },
  copp: {
    id: 'App.Qualification.AssetInformationForm.COPP',
    defaultMessage: 'COPP & applicable documentation',
  },
  applicableDocumentation: {
    id: 'App.Qualification.AssetInformationForm.ApplicableDocumentation',
    defaultMessage: 'Applicable documentation',
  },
  no: {
    id: 'App.Qualification.AssetInformationForm.No',
    defaultMessage: 'No',
  },
  yes: {
    id: 'App.Qualification.AssetInformationForm.Yes',
    defaultMessage: 'Yes',
  },
  householdMemberName: {
    id: 'App.Qualification.AssetInformationForm.HouseholdMemberName',
    defaultMessage: 'If Yes, Household Member Name',
  },
  totalAssets: {
    id: 'App.Qualification.AssetInformationForm.TotalAssets',
    defaultMessage: 'Total Assets',
  },
  totalMonthlyIncomeFromAssets: {
    id: 'App.Qualification.AssetInformationForm.TotalMonthlyIncomeFromAssets',
    defaultMessage: 'Total Monthly Income from Assets',
  },
  imputedIncome: {
    id: 'App.Qualification.AssetInformationForm.ImputedIncome',
    defaultMessage: 'Imputed Income (.06%)',
  },
  saveAssetInformation: {
    id: 'App.Qualification.AssetInformationForm.SaveAssetInformation',
    defaultMessage: 'Save Asset Information',
  },
  valueOrIncomeRequired: {
    id: 'App.Qualification.AssetInformationForm.ValueOrIncomeRequired',
    defaultMessage: 'Value or Income Required',
  },
  mustBePositive: {
    id: 'App.Qualification.AssetInformationForm.MustBePositive',
    defaultMessage: 'Amount Must Be Positive',
  },
  mustBeLessThanMarketValue: {
    id: 'App.Qualification.AssetInformationForm.MustBeLessThanMarketValue',
    defaultMessage: 'Amount Must Less Than Market Value',
  },
  nameRequired: {
    id: 'App.Qualification.AssetInformationForm.NameRequired',
    defaultMessage: 'Name Required',
  },
  valueRequired: {
    id: 'App.Qualification.AssetInformationForm.ValueRequired',
    defaultMessage: 'Value Required',
  },
  invalidDate: {
    id: 'App.Qualification.AssetInformationForm.invalidDate',
    defaultMessage: 'Invalid Date',
  },
  interestRate: {
    id: 'App.Qualification.AssetInformationForm.InterestRate',
    defaultMessage: 'Interest Rate',
  },
  marketValue: {
    id: 'App.Qualification.AssetInformationForm.MarketValue',
    defaultMessage: 'Current Balance (Market Value)',
  },
  feesToCash: {
    id: 'App.Qualification.AssetInformationForm.FeesToCash',
    defaultMessage: 'Fees to Convert to Cash',
  },
  cashValue: {
    id: 'App.Qualification.AssetInformationForm.CashValue',
    defaultMessage: 'Cash Value',
  },
  annualIncome: {
    id: 'App.Qualification.AssetInformationForm.AnnualIncome',
    defaultMessage: 'Annual Income',
  },
  totalAnnualIncome: {
    id: 'App.Qualification.AssetInformationForm.TotalAnnualIncome',
    defaultMessage: 'Total Annual Income From Assets',
  },
  totalActualIncome: {
    id: 'App.Qualification.AssetInformationForm.TotalActualIncome',
    defaultMessage: 'Total Actual Income From Assets',
  },
  suitableForOccupancy: {
    id: 'App.Qualification.AssetInformationForm.SuitableForOccupancy',
    defaultMessage:
      'Is this property suitable for occupancy by the household as a residence?',
  },
  suitableForOccupancyRequired: {
    id: 'App.Qualification.AssetInformationForm.SuitableForOccupancyRequired',
    defaultMessage: 'Suitable for occupancy is required',
  },
  actualImpute: {
    id: 'App.Qualification.AssetInformationForm.ActualImpute',
    defaultMessage: 'Actual / Impute',
  },
  actualImputeTooltip: {
    id: 'App.Qualification.AssetInformationForm.ActualImputeTooltip',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Actual income from assets are known values. Impute is used when the values are unknown and total household assets are over {householdAssetThreshold}',
  },
  preHotmaFormNotification: {
    id: 'App.Qualification.AssetInformationForm.PreHotmaFormNotification',
    defaultMessage: 'This Asset form is pre-HOTMA rules.',
  },
  postHotmaFormNotification: {
    id: 'App.Qualification.AssetInformationForm.PostHotmaFormNotification',
    defaultMessage: "This Asset form is compliant with HOTMA's new rules.",
  },
  assetConfirmation: {
    id: 'App.Qualification.AssetInformationForm.AssetConfirmation',
    defaultMessage: "We have reviewed this household member's asset form.",
  },
  assetConfirmationError: {
    id: 'App.Qualification.AssetInformationForm.AssetConfirmationError',
    defaultMessage: 'Asset confirmation is required',
  },
  federalTaxRefundOrCredit: {
    id: 'App.Qualification.AssetInformationForm.FederalTaxRefundOrCredit',
    defaultMessage: 'Federal Tax Refund/Credit',
  },
  federalTaxRefundOrCreditTooltip: {
    id: 'App.Qualification.AssetInformationForm.FederalTaxRefundOrCreditTooltip',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Tax refunds and credits may be deducted from the net cash value of assets if the total household cash value of assets exceed {AssetThreshold}.',
  },
});

export default messages;

export const householdPreferencesNames = [
  'Disabled',
  'Elderly',
  'Veteran',
  'VAWA',
  'Working Family',
];

export const propertyPreferencesNames = [
  'Veteran',
  'Adult Transition',
  'Involuntarily',
  'Disability',
  'Elderly',
  'Homeless',
  'Paymore50',
  'Residency',
  'Victims',
  'Working Families',
];

import { isEmpty, isNil, pathOr } from 'ramda';
import moment from 'moment';
import { validateDate } from '../../../../utils/validations';
import {
  calculateLeaseEndDate,
  calculateLeaseEndDateV2,
  calculateRentWithAmenities,
} from '../../../../utils/lease-helpers.js';

import messages from '../messages';

export const validate = (values: Object, props: Object) => {
  const errors = {};

  const {
    editLeaseTerm,
    editLeaseStartDate,
    editLeaseEndDate,
    editLeaseRentAmount,
    editIsReceivingAssistance,
  } = values;

  const {
    leaseRentPercentage,
    leaseTerms,
    unit,
    intl,
    affordableValues,
    applicableRents,
    selectedProperty,
    lease,
    flags,
  } = props;
  const isAffordable = pathOr(false, ['floorPlan', 'isAffordable'], unit);
  const phaFloorplan = pathOr(false, ['floorPlan', 'phaFloorplan'], unit);
  const hudFloorplan = pathOr(false, ['floorPlan', 'hudFloorplan'], unit);
  const hudOrPha = phaFloorplan || hudFloorplan;

  const affordableMarketRent = +pathOr(
    0,
    ['affordableMarketRent'],
    affordableValues,
  );
  const affordableQuotingRent = +pathOr(
    0,
    ['affordableQuotingRent'],
    affordableValues,
  );
  const utilityAllowanceAmount = +pathOr(
    0,
    ['utilityAllowanceAmount'],
    affordableValues,
  );
  const noteRent = +pathOr(0, ['noteRent'], affordableValues);
  const nonOptionalCharge = +(affordableValues?.nonOptionalCharge ?? 0);
  const initialQuotingRentAmount =
    isAffordable && !(phaFloorplan || hudFloorplan)
      ? affordableQuotingRent
      : pathOr(
          pathOr(null, ['floorPlan', 'quotingRentAmount'], unit),
          ['quotingRentAmount'],
          unit,
        );

  const quotingRent =
    lease?.quotingRent ??
    calculateRentWithAmenities(unit, +initialQuotingRentAmount);
  const { leaseEndEnhance } = flags;

  /**
   * Required Fields
   */
  if (isNil(editLeaseStartDate) || isEmpty(editLeaseStartDate)) {
    errors['editStateDate'] = 'Lease Start Date is required.';
  }
  if (isNil(editLeaseEndDate) || isEmpty(editLeaseEndDate)) {
    errors['editLeaseEndDate'] = 'Lease End Date is required.';
  }
  if (isNil(editLeaseRentAmount) || isEmpty(editLeaseRentAmount)) {
    errors['editLeaseRentAmount'] = 'Lease Rent Amount is required.';
  }

  /**
   * Lease Rent Amount
   */
  const leasedRentOverride = hudOrPha || editIsReceivingAssistance;
  const outsideLowestQuote = leasedRentOverride
    ? +editLeaseRentAmount <= 0
    : (+editLeaseRentAmount / +quotingRent) * 100 + +leaseRentPercentage < 100;
  const outsideHighestQuote = leasedRentOverride
    ? false
    : (+editLeaseRentAmount / +quotingRent) * 100 - +leaseRentPercentage > 100;

  if (outsideLowestQuote) {
    errors.editLeaseRentAmount = leasedRentOverride
      ? intl.formatMessage(messages.rentGreaterThanZero)
      : intl.formatMessage(messages.rentWithinLeasedRentPercentage, {
          leaseRentPercentage,
        });
  }
  if (outsideHighestQuote && !hudOrPha) {
    errors.editLeaseRentAmount = intl.formatMessage(
      messages.rentWithinLeasedRentPercentage,
      {
        leaseRentPercentage,
      },
    );
  }

  if (
    !editIsReceivingAssistance &&
    isAffordable &&
    !hudOrPha &&
    !applicableRents?.noteRent && // isRD
    +editLeaseRentAmount >
      affordableMarketRent - utilityAllowanceAmount - nonOptionalCharge
  ) {
    errors.editLeaseRentAmount = intl.formatMessage(
      messages[
        nonOptionalCharge !== 0
          ? 'rentIsTooHighWithNonOptionalCharge'
          : 'rentIsTooHigh'
      ],
    );
  }

  /**RD rents */
  if (applicableRents?.noteRent) {
    if (+editLeaseRentAmount > noteRent + utilityAllowanceAmount) {
      errors.editLeaseRentAmount = intl.formatMessage(
        messages[
          utilityAllowanceAmount !== 0 ? 'noteRentErrorWithUA' : 'noteRentError'
        ],
      );
    }
  }
  if (applicableRents.noteRent && applicableRents.hudGrossRent) {
    if (
      +editLeaseRentAmount > noteRent + utilityAllowanceAmount ||
      +editLeaseRentAmount > affordableMarketRent + utilityAllowanceAmount
    ) {
      errors.editLeaseRentAmount = intl.formatMessage(
        messages[
          utilityAllowanceAmount !== 0
            ? 'hudGrossRentAndNoteErrorWithUA'
            : 'hudGrossRentandNoteError'
        ],
      );
    }
  }

  /**
   * Lease Start Date & End Date
   */

  // Valid Dates
  if (!errors['editLeaseStartDate'] && !validateDate(editLeaseStartDate)) {
    errors['editLeaseStartDate'] =
      'Lease Start Date must be a valid date format (MM/DD/YYYY).';
  }

  if (!errors['editLeaseEndDate'] && !validateDate(editLeaseEndDate)) {
    errors['editLeaseEndDate'] =
      'Lease End Date must be a valid date format (MM/DD/YYYY).';
  }

  // Date Constraints
  if (!errors['editLeaseStartDate']) {
    const momentStartDate = moment(editLeaseStartDate);
    const momentNow = moment();

    if (momentStartDate.isAfter(momentNow, 'day')) {
      errors[
        'editLeaseStartDate'
      ] = `Lease Start Date must be on or before ${momentNow.format(
        'MM/DD/YYYY',
      )}.`;
    }
  }

  if (!errors['editLeaseStartDate'] && !errors['editLeaseEndDate']) {
    const momentStartDate = moment(editLeaseStartDate);
    const momentEndDate = moment(editLeaseEndDate);

    if (momentEndDate.isBefore(momentStartDate)) {
      errors['editLeaseEndDate'] =
        'Lease End Date cannot be before Lease Start Date.';
    }

    if (momentStartDate.isAfter(momentEndDate)) {
      errors['editLeaseStartDate'] =
        'Lease Start Date cannot be after Lease End Date.';
    }

    if (editLeaseTerm && leaseTerms) {
      const calculatedEndDate = leaseEndEnhance
        ? calculateLeaseEndDateV2(
            editLeaseStartDate,
            editLeaseTerm,
            leaseTerms,
            selectedProperty,
          )
        : calculateLeaseEndDate(
            editLeaseStartDate,
            editLeaseTerm,
            leaseTerms,
            selectedProperty?.isSetLeaseEndDateToEndOfMonth,
          );

      if (calculatedEndDate) {
        const sixtyOneDaysBefore = calculatedEndDate
          .clone()
          .subtract(61, 'days');
        const sixtyOneDaysAfter = calculatedEndDate.clone().add(61, 'days');

        if (
          momentEndDate.isBefore(sixtyOneDaysBefore) ||
          momentEndDate.isAfter(sixtyOneDaysAfter)
        ) {
          errors.endDate = `The combination of Lease Term, Start Date, and End Date are invalid.
            Lease End Date cannot be more than 61 days before or after ${calculatedEndDate.format(
              'MM/DD/YYYY',
            )}.`;
        }
      }
    }
  }

  return errors;
};

export default validate;

import React from 'react';
import {
  YesNoColumns,
  IncomeAssetCurrencyField,
  InterestRateField,
} from '../../SharedFormFields';
import {
  ALT_FORM_FIRST_COL_SECTION,
  NumberAndAssetColumn,
  ALT_FORM_SECOND_COL_SECTION,
  DeleteAccountBtn,
  AddAccountLink,
} from '..';

export const RetirementAssetFieldArrayRows = ({
  asset,
  fields,
  isPrior,
  handleAdd,
  assetName,
  noClicked,
  formFields,
  showAddAccountBtn,
  calculateAnnualIncome,
  calculateCashValue,
}) => {
  const disabled = formFields[asset.name] !== 'yes';
  if (fields.length === 0) {
    fields.push();
  }
  const addAccountDisabled = fields.length >= asset.fieldLimit;
  // The dot allows us to place value inside object property inside the list
  const marketValueType = '.marketValue';
  const interestRateType = '.interestRate';
  const feesToConvertCash = '.feesToConvertCash';
  const cashValue = '.cashValue';
  const annualIncome = '.annualIncome';
  return (
    <>
      <div className="row container-fluid" style={{ paddingLeft: '20px' }}>
        {fields.map((assetField, index) =>
          index === 0 ? (
            <div className="row" key={index}>
              <div className={ALT_FORM_FIRST_COL_SECTION}>
                <div className="row">
                  <NumberAndAssetColumn
                    row={asset}
                    isPrior={isPrior}
                    colSize="6"
                    disabled={disabled}
                  />
                  <YesNoColumns
                    name={assetName}
                    noClicked={() => noClicked(asset.name, true)}
                    isPrior={isPrior}
                    colSize="3"
                  />
                  <IncomeAssetCurrencyField
                    name={assetField}
                    type={marketValueType}
                    disabled={disabled || isPrior}
                    colSize="3"
                    onBlur={() => {
                      calculateAnnualIncome(asset.name, true, index);
                      calculateCashValue(asset.name, true, index);
                    }}
                  />
                </div>
              </div>
              <div className={ALT_FORM_SECOND_COL_SECTION}>
                <div className="row">
                  <div className="col-xs-11">
                    <div className="row">
                      <InterestRateField
                        name={assetField}
                        disabled={disabled || isPrior}
                        type={interestRateType}
                        colSize="3"
                        onBlur={() =>
                          calculateAnnualIncome(asset.name, true, index)
                        }
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={feesToConvertCash}
                        disabled={disabled || isPrior}
                        colSize="3"
                        onBlur={() =>
                          calculateCashValue(asset.name, true, index)
                        }
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={cashValue}
                        disabled={true}
                        colSize="3"
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={annualIncome}
                        disabled={true}
                        colSize="3"
                      />
                    </div>
                  </div>
                  <div className="col-xs-1"></div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row" key={index}>
              <div className="col-xs-5">
                <div className="row">
                  <div className="col-xs-9" />
                  <IncomeAssetCurrencyField
                    name={assetField}
                    type={marketValueType}
                    disabled={disabled || isPrior}
                    colSize="3"
                    onBlur={() => {
                      calculateAnnualIncome(asset.name, true, index);
                      calculateCashValue(asset.name, true, index);
                    }}
                  />
                </div>
              </div>
              <div className="col-xs-7">
                <div className="row">
                  <div className="col-xs-11">
                    <div className="row">
                      <InterestRateField
                        name={assetField}
                        disabled={disabled || isPrior}
                        type={interestRateType}
                        colSize="3"
                        onBlur={() =>
                          calculateAnnualIncome(asset.name, true, index)
                        }
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={feesToConvertCash}
                        disabled={disabled || isPrior}
                        colSize="3"
                        onBlur={() =>
                          calculateCashValue(asset.name, true, index)
                        }
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={cashValue}
                        disabled={true}
                        colSize="3"
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={annualIncome}
                        disabled={true}
                        colSize="3"
                      />
                    </div>
                  </div>
                  <div className="col-xs-1" style={{ paddingLeft: '0px' }}>
                    <DeleteAccountBtn
                      clickHandler={() => fields.remove(index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ),
        )}
      </div>
      {showAddAccountBtn ? (
        <div className="row">
          <AddAccountLink
            clickHandler={handleAdd}
            fields={fields}
            fieldLimit={asset.fieldLimit}
            disabled={addAccountDisabled}
          />
        </div>
      ) : null}
    </>
  );
};

import moment from 'moment';
import { useMemo } from 'react';

import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Atoms';

// TODO: need to figure out how to make the checkboxes rerender when filters and sorting are applied
const useRows = ({
  mincTransactions,
  checkBoxListProps: { selectedIds, handleSelectRow },
}) => {
  return useMemo(() => {
    return mincTransactions.map((row) => {
      const headOfHousehold = row.headOfHousehold;
      const unitNumber = row.unitNumber;
      const certificationType = row.certificationType;
      const certificationCompletionDate = row.certificationCompletionDate;
      const effectiveDate = row.effectiveDate;
      const lastGeneratedAt = row.lastGeneratedAt;
      // done here for user timezone considerations
      const status = lastGeneratedAt
        ? `Generated at ${moment(lastGeneratedAt).format('YYYY.MM.DD')}`
        : null;
      const checked = selectedIds.has(row.id);
      return {
        checkbox: {
          value: (
            <Checkbox checked={checked} onChange={() => handleSelectRow(row)} />
          ),
        },
        headOfHousehold: {
          value: headOfHousehold,
        },
        unitNumber: {
          value: unitNumber,
        },
        certificationType: {
          value: certificationType,
        },
        effectiveDate: {
          variant: 'date',
          value: effectiveDate,
        },
        certificationCompletionDate: {
          variant: 'date',
          value: certificationCompletionDate,
        },
        status: {
          value: status,
        },
      };
    });
  }, [mincTransactions, selectedIds, handleSelectRow]);
};

export default useRows;
